<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Detalhes</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <form>
            <div class="pl-lg-0">
              <div class="row">
                <div class="col-lg-4">
                  <label class="form-control-label"> Título: </label>
                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Título"
                    v-model="noticia.titulo"
                  />
                </div>
                <div class="col-lg-4">
                  <label class="form-control-label"> Link Alternativo: </label>
                  <el-tooltip placement="top" :content="infoLinkAlternativo">
                    <i color="#1482c2" class="ml-1 fas fa-info-circle"></i>
                  </el-tooltip>

                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Link Alternativo"
                    v-model="noticia.url"
                  />
                </div>
                <div class="col-lg-4">
                  <base-input name="selectmultiple" label="Etiquetas:">
                    <el-select
                      v-model="noticia.etiquetas"
                      multiple
                      filterable
                      placeholder="Etiquetas"
                    >
                      <el-option
                        v-for="option in selectOptions"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label">Imagem da Notícia:</label>
                  <div class="row mb-5" v-show="noticia.imagem">
                    <div class="col-lg-12 text-center">
                      <img :src="noticia.imagem" alt="" class="imagemAtual" />
                    </div>
                  </div>

                  <dropzone-file-upload
                    style="border: 1px dashed #000; border-radius: 0.375rem"
                    @change="imgF()"
                    v-model="noticia.imagem"
                  ></dropzone-file-upload>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label"
                    >Notícia em destaque?
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-0">
                  <base-switch
                    class="mr-1"
                    on-text="Sim"
                    off-text="Não"
                    @change="changeSwitch('destaque')"
                    v-model="noticia.destaque"
                  ></base-switch>
                </div>
              </div>

              <div class="col-lg-4" v-if="noticia.destaque">
                <label class="form-control-label"> Resumo: </label>
                <el-tooltip placement="top" :content="infoDestaque">
                  <i color="#1482c2" class="ml-1 fas fa-info-circle"></i>
                </el-tooltip>

                <input
                  class="form-control"
                  name="grid1"
                  id="grid1"
                  type="text"
                  placeholder="Resumo"
                  v-model="noticia.resumo_destaque"
                />
              </div>

              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label">Publicar Notícia? </label>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-0">
                  <base-switch
                    class="ml-1"
                    on-text="Sim"
                    off-text="Não"
                    @change="changeSwitch('publicar')"
                    v-model="noticia.publicar"
                  ></base-switch>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <label class="form-control-label">Descrição da Notícia </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 text-left">
              <form>
                <html-editor></html-editor>
              </form>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="saveNews"
                >Guardar</v-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { ElSelect, ElOption } from "element-plus";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import Ajuda from "../Components/Ajuda";

export default {
  name: "Criar-Notícias",
  components: {
    HtmlEditor,
    DropzoneFileUpload,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Ajuda,
  },
  data() {
    return {
      infoLinkAlternativo:
        "Preencha este campo caso deseje que a noticia seja partilhada para um site externo.",
      infoDestaque:
        "Pequeno texto que servirá de resumo no destaque na página principal ",
      user: [],
      selectOptions: [],
      noticia: {
        titulo: "",
        descricao: "",
        url: "",
        imagem: "",
        data: "",
        etiquetas: [],
        autor: "",
        resumo_destaque: "",
        autor_entryUUID: "",
        destaque: false,
        publicar: true,
      },
      switches: {
        off: true,
        primary: true,
      },
      selects: {
        simple: "Badges",
        multiple: [],
      },
    };
  },
  methods: {
    onCopy(el) {
      var test = document.getElementById(el);
      test.select();
      document.execCommand("copy");
    },

    successNotification(pos, type, ownText, ownIcon) {
      const text = "Notícia criada com sucesso!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao criar a notícia! Verifique se os campos estão devidamente preenchidos!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    changeSwitch(noticia) {
      if (noticia == "destaque") {
        this.noticia.destaque = !this.noticia.destaque;
      } else {
        this.noticia.publicar = !this.noticia.publicar;
      }
    },

    async saveNews() {
      this.noticia.descricao = document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML;
      this.noticia.descricao.replaceAll(/&lt;/g, "<").replace(/&gt;/g, ">");
      this.noticia.autor = this.user.nome + " " + this.user.apelido;
      this.noticia.autor_entryUUID = this.user.entryUUID;
      this.noticia.descricao = document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML;
      if (
        this.noticia.titulo == "" ||
        this.noticia.descricao == "" ||
        this.noticia.imagem == "" ||
        this.noticia.etiquetas.length <= 0
      ) {
        this.errorNotification("top-right", "danger");
      } else {
        if (
          this.noticia.destaque == true &&
          this.noticia.resumo_destaque == ""
        ) {
          this.errorNotification("top-right", "danger");
        } else {
          if (this.noticia.destaque == true) {
            this.noticia.destaque = 1;
          } else {
            this.noticia.destaque = 0;
          }
          if (this.noticia.publicar == true) {
            this.noticia.publicar = 1;
          } else {
            this.noticia.publicar = 0;
          }
          var etiquetas = {
            items: this.noticia.etiquetas,
          };
          this.noticia.etiquetas = JSON.stringify(etiquetas);
          let res = await this.$store.dispatch("createNews", this.noticia);
          if (res) {
            if (res.resultado == "OK") {
              this.successNotification("top-right", "success");
              await this.$store.dispatch("getAllNews");
            } else {
              this.noticia.etiquetas = [];
              this.errorNotification("top-right", "danger");
            }
          } else {
            this.errorNotification("top-right", "danger");
            this.noticia.etiquetas = [];
          }
        }
      }
    },

    imgF() {
      setTimeout(() => {
        var img = document.getElementsByClassName("dz-preview-img");
        for (let i = 0; i < img.length; i++) {
          if (i === img.length - 1) {
            this.noticia.imagem = img[i].src;
          }
        }
      }, 200);
    },

    async getUserProfile() {
      var id = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", id);
      this.user = Object.values(user1)[0];

      var etiquetas = await this.$store.dispatch("etiquetas");
      for (let i = 0; i < etiquetas.length; i++) {
        this.selectOptions.push({
          label: etiquetas[i].etiqueta,
          value: etiquetas[i].etiqueta,
        });
      }
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
<style scoped>
.el-select__tags span {
  background-color: rgb(20, 130, 194) !important;
}
.imagemAtual {
  width: 50%;
  margin: auto;
}
</style>
