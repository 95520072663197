<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">{{ nomeEditar }}</h3>
        </div>
        <div class="card-body">
          <div class="col-lg-12" v-if="formularioEdicao">
            <label class="form-control-label">Etiqueta:</label>
            <input
              type="text"
              class="form-control"
              v-model="etiquetasEditada.etiqueta"
            />
          </div>
          <div class="col-lg-12" v-else>
            <label class="form-control-label"> Nome da Etiqueta:</label>
            <input
              type="text"
              class="form-control"
              v-model="novaEtiqueta.etiqueta"
            />
          </div>
          <div v-if="!formularioEdicao" class="col-lg-12 text-left mt-5">
            <v-button
              class="btn base-button btn-success"
              type="success"
              @click="criarEtiqueta"
              >Guardar
            </v-button>
          </div>
          <div v-if="formularioEdicao" class="col-lg-12 text-left mt-5">
            <v-button
              class="btn base-button btn-success"
              type="success"
              @click="editarEtiqueta"
              >Editar
            </v-button>
            <v-button class="btn base-button" type="success" @click="cancelar"
              >Cancelar
            </v-button>
          </div>
        </div>
      </div>
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Etiquetas</h3>
        </div>
        <!-- Card body -->
        <div class="card-body p-0">
          <el-table
            class="table-responsive table-flush"
            header-row-class-name="thead-light"
            :data="etiquetas"
          >
            <el-table-column
              label="Nome da Etiqueta"
              min-width="310px"
              prop="name"
              sortable
            >
              <template v-slot="{ row }">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="font-weight-600 name mb-0 text-sm">{{
                      row.etiqueta
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column v-slot="{ row }" max-width="180px">
              <el-dropdown trigger="click" class="dropdown">
                <span class="btn btn-sm btn-icon-only text-light">
                  <i class="fas fa-ellipsis-v mt-2"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu
                    class="dropdown-menu dropdown-menu-arrow show"
                    label="Estado"
                  >
                    <a class="dropdown-item" @click="showSwal('editar', row.id)"
                      >Editar</a
                    >
                    <a class="dropdown-item" @click="showSwal('apagar', row.id)"
                      >Apagar</a
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      etiquetas: [],
      etiquetasEditada: {
        id: "",
        etiqueta: "",
      },
      novaEtiqueta: {
        etiqueta: "",
      },

      currentPage: 1,
      rendered: false,
      formularioEdicao: false,
    };
  },

  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao atualizar o seu perfil! Tente novamente mais tarde!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    async showSwal(type, id) {
      var that = this;
      if (type === "apagar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja apagar esta etiqueta?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id: id,
              };
              let res = await that.$store.dispatch("apagarEtiqueta", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Etiqueta apagada com sucesso!"
                  );
                  var etiquetas = await that.$store.dispatch("etiquetas");
                  that.etiquetas = etiquetas;
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "editar") {
        this.formularioEdicao = true;
        this.etiquetasEditada = this.etiquetas.find((x) => x.id == id);
        console.log(this.formularioEdicao);
      }
    },

    async editarEtiqueta() {
      if (this.etiquetasEditada.etiqueta != "") {
        let res = await this.$store.dispatch(
          "editarEtiquetas",
          this.etiquetasEditada
        );
        if (res.resultado == "OK") {
          var etiquetas = await this.$store.dispatch("etiquetas");
          this.etiquetas = etiquetas;
          this.successNotification(
            "top-right",
            "success",
            "Etiqueta editada com sucesso!"
          );
          this.formularioEdicao = false;
        } else {
          this.errorNotification("top-right", "danger");
        }
      } else {
        this.errorNotification("top-right", "danger");
      }
    },

    async criarEtiqueta() {
      if (this.novaEtiqueta.etiqueta != "") {
        let res = await this.$store.dispatch(
          "criarEtiqueta",
          this.novaEtiqueta
        );

        if (res.resultado == "OK") {
          var etiquetas1 = await this.$store.dispatch("etiquetas");
          this.etiquetas = etiquetas1;
          this.successNotification(
            "top-right",
            "success",
            "Etiqueta criada com sucesso!"
          );
          var etiquetas = await this.$store.dispatch("etiquetas");
          this.etiquetas = etiquetas;
        } else {
          this.errorNotification("top-right", "danger");
        }
      } else {
        this.errorNotification("top-right", "danger");
      }
    },

    async cancelar() {
      var etiquetas = await this.$store.dispatch("etiquetas");
      this.etiquetas = etiquetas;
      this.formularioEdicao = false;
    },
  },
  computed: {
    nomeEditar() {
      if (this.formularioEdicao == false) {
        return "Criar Etiqueta";
      } else {
        return "Editar Etiqueta";
      }
    },
  },
  async mounted() {
    var etiquetas = await this.$store.dispatch("etiquetas");
    this.etiquetas = etiquetas;
    this.rendered = true;
  },
};
</script>
