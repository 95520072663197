<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">Feedbacks</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">{{ $route.name }}</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(recebidos360)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    tudoRespondido(row)
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <!-- v-if="tudoRespondido(row) == 'Pedido respondido'" -->
            <v-button
              v-if="tudoRespondido(row) == 'Pedido respondido'"
              class="btn base-button btn-primary btn-sm"
              @click="mostarComentario(row.id, row.nome, row.apelido)"
              >Ver Feedback
            </v-button>
          </el-table-column>
        </el-table>
        <modal class="modalPerfil" v-model:show="modals.recebido">
          <div class="card card-profile">
            <div class="card-header">
              <h3 class="mb-0">
                360 - Enviado por
                {{ nome360 + " " + apelido360 }}
              </h3>
            </div>
            <div class="card-body">
              <div class="card" v-for="(item, i) in perguntaFiltradas" :key="i">
                <div class="card-body">
                  <h3>
                    {{ item.pergunta }}
                    <br />
                    <small> <b class="text-success">RESPOSTA:</b></small>
                    {{ item.resposta }}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div type="link" class="ml-auto text-right" @click="fecharModal()">
              Fechar
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Ajuda from "../../../Components/Ajuda";
import Modal from "../../../../components/Modal";
export default {
  name: "Recebidos",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      classPorResponder: false,
      currentPage: 1,
      recebidos360: [],
      perguntas: [],
      perguntaFiltradas: [],
      comentarioIndividual: "",
      nome360: "",
      apelido360: "",
      modals: {
        recebido: false,
      },
    };
  },
  methods: {
    tudoRespondido(json) {
      var listagem = JSON.parse(json.perguntas);
      var parse = new Array();
      for (let i = 0; i < listagem.length; i++) {
        parse.push({
          perguntas: listagem[i],
        });
      }
      var respostas = [];
      for (let k = 0; k < parse.length; k++) {
        if (!parse[k].perguntas.resposta || parse[k].perguntas.resposta == "") {
          respostas.push(0);
        } else {
          respostas.push(1);
        }
      }
      if (respostas.some((x) => x == 0)) {
        return "Pedido pendente de resposta";
      } else {
        return "Pedido respondido";
      }
    },
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async mostarComentario(id, nome, apelido) {
      this.nome360 = nome;
      this.apelido360 = apelido;

      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listar360ComentarioIndividual",
        jsonComentario
      );
      this.perguntaFiltradas = this.perguntas.filter((x) => x.id == id);
      this.modals.recebido = true;
    },
    fecharModal() {
      this.modals.recebido = false;
    },
    async listarRecebidos() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonRecebidos = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listar360Recebidos",
        jsonRecebidos
      );
      this.recebidos360 = todas;

      var listagem = Object.values(this.recebidos360);
      var parse = new Array();
      for (let i = 0; i < listagem.length; i++) {
        parse.push({
          perguntas: JSON.parse(listagem[i].perguntas),
          id: listagem[i].id,
        });
      }

      for (let i = 0; i < parse.length; i++) {
        for (let k = 0; k < parse[i].perguntas.length; k++) {
          if (parse[i].perguntas[k].resposta) {
            this.perguntas.push({
              id: parse[i].id,
              pergunta: parse[i].perguntas[k].pergunta,
              resposta: parse[i].perguntas[k].resposta,
            });
          } else {
            this.perguntas.push({
              id: parse[i].id,
              pergunta: parse[i].perguntas[k].pergunta,
              resposta: "",
            });
          }
        }
      }
    },
    async guardarRespostas(id) {
      var temp = [];
      for (let i = 0; i < this.perguntaFiltradas.length; i++) {
        temp.push({
          pergunta: this.perguntaFiltradas[i].pergunta,
          resposta: this.perguntaFiltradas[i].resposta,
        });
      }
      console.log(JSON.stringify(temp));
      var data = {
        id: id.id,
        perguntas: JSON.stringify(temp),
      };
      await this.$store.dispatch("actualizarRespostas360", data);
    },
  },
  async mounted() {
    this.listarRecebidos();
  },
};
</script>
<style scoped>
.modal-content {
  width: 65vw !important;
}

.modal-dialog {
  max-width: 65vw !important;
}

.modal-body {
  padding: 0 !important;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
}

.modal-body .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px 1.5rem;
}

.card-body .card {
  margin-bottom: 20px;
}
</style>
