<template>
  <div class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Calendário',
            icon: 'ni ni-calendar-grid-58 text-orange',
            path: '/calendario',
          }"
        >
        </sidebar-item>
        <div v-if="administrador">
          <sidebar-item
            :link="{
              name: 'Utilizadores',
              icon: 'ni ni-single-02 text-orange',
              path: '/gerir-utilizadores',
            }"
          >
          </sidebar-item>
        </div>
        <a
          aria-current="page"
          href="#"
          @click="logout()"
          class="active router-link-exact-active nav-link"
          ><i class="ni ni-button-power text-orange"></i
          ><span class="nav-link-text">Logout</span></a
        >
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      administrador: false,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("idYourCodeCalendar");
      this.$router.push("/login");
    },
    async admin() {
      let id_plataforma = localStorage.getItem("idYourCodeCalendar");
      var res = await this.$store.dispatch("listarUtilizadores");
      var utilizadores = Object.values(res);
      var admin = utilizadores.find((x) => x.id_plataforma == id_plataforma);
      console.log("admin", admin.admin == "1");
      if (admin.admin == "1") {
        this.administrador = true;
      } else {
        this.administrador = false;
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.admin();
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
