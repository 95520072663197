<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Detalhes</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <form>
            <div class="pl-lg-0">
              <div class="row">
                <div class="col-lg-4">
                  <label class="form-control-label"> Título: </label>
                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Título"
                    v-model="noticia.titulo"
                  />
                </div>
                <div class="col-lg-4">
                  <label class="form-control-label"> Link Alternativo: </label>
                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Link Alternativo"
                    v-model="noticia.url"
                  />
                </div>
                <div class="col-lg-4">
                  <base-input name="selectmultiple" label="Etiquetas:">
                    <el-select
                      v-model="noticia.etiquetas"
                      multiple
                      filterable
                      placeholder="Etiquetas"
                    >
                      <el-option
                        v-for="option in selectOptions"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label">Imagem da Notícia</label>
                  <div class="row mb-5" v-show="noticia.imagem">
                    <div class="col-lg-12 text-center">
                      <img :src="noticia.imagem" alt="" class="imagemAtual" />
                    </div>
                  </div>

                  <dropzone-file-upload
                    style="border: 1px dashed #000; border-radius: 0.375rem"
                    @change="imgF()"
                    v-model="noticia.imagem"
                  ></dropzone-file-upload>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label"
                    >Notícia em destaque?:
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mt-0">
                  <base-switch
                    class="mr-1"
                    on-text="Sim"
                    off-text="Não"
                    @change="changeSwitch('destaque')"
                    v-model="noticia.destaque"
                  ></base-switch>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label">Publicar Notícia?</label>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-0">
                  <base-switch
                    class="ml-1"
                    on-text="Sim"
                    off-text="Não"
                    @change="changeSwitch('publicar')"
                    v-model="noticia.publicar"
                  ></base-switch>
                </div>
              </div>
            </div>
          </form>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <label class="form-control-label">Descrição da Notícia </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 text-left">
              <form>
                <html-editor></html-editor>
              </form>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="saveNews"
                >Guardar</v-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { ElSelect, ElOption } from "element-plus";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
import Ajuda from "../Components/Ajuda";

export default {
  name: "Editar-Notícias",
  components: {
    HtmlEditor,
    DropzoneFileUpload,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Ajuda,
  },
  data() {
    return {
      user: [],
      selectOptions: [
        {
          label: "Etiqueta 1",
          value: "Etiqueta 1",
        },
        {
          label: "Etiqueta 2",
          value: "Etiqueta 2",
        },
        {
          label: "Etiqueta 3",
          value: "Etiqueta 3",
        },
        {
          label: "Etiqueta 4",
          value: "Etiqueta 4",
        },
        {
          label: "Etiqueta 5",
          value: "Etiqueta 5",
        },
        {
          label: "Etiqueta 6",
          value: "Etiqueta 6",
        },
      ],
      noticia: {
        id: this.$store.state.noticiaEditada[0].id,
        titulo: this.$store.state.noticiaEditada[0].titulo,
        descricao: this.$store.state.noticiaEditada[0].descricao,
        url: this.$store.state.noticiaEditada[0].url,
        imagem: this.$store.state.noticiaEditada[0].imagem,
        data: this.$store.state.noticiaEditada[0].data,
        etiquetas: [],
        autor: this.$store.state.noticiaEditada[0].autor,
        autor_entryUUID: this.$store.state.noticiaEditada[0].autor_entryUUID,
        destaque: false,
        publicar: true,
      },
      switches: {
        off: true,
        primary: true,
      },
      selects: {
        simple: "Badges",
        multiple: [],
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "Notícia editada com sucesso!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao editar a notícia! Verifique se os campos estão devidamente preenchidos!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    changeSwitch(noticia) {
      if (noticia == "destaque") {
        this.noticia.destaque = !this.noticia.destaque;
      } else {
        this.noticia.publicar = !this.noticia.publicar;
      }
    },

    async saveNews() {
      this.noticia.autor = this.user.nome + " " + this.user.apelido;
      this.noticia.autor_entryUUID = this.user.entryUUID;
      this.noticia.descricao = document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML;
      this.noticia.descricao.replaceAll(/&lt;/g, "<").replace(/&gt;/g, ">");
      if (
        this.noticia.titulo == "" ||
        this.noticia.descricao == "" ||
        this.noticia.url == "" ||
        this.noticia.imagem == "" ||
        this.noticia.etiquetas.length <= 0
      ) {
        this.errorNotification("top-right", "danger");
      } else {
        if (this.noticia.destaque == true) {
          this.noticia.destaque = 1;
        } else {
          this.noticia.destaque = 0;
        }
        if (this.noticia.publicar == true) {
          this.noticia.publicar = 1;
        } else {
          this.noticia.publicar = 0;
        }
        var etiquetas = {
          items: this.noticia.etiquetas,
        };
        this.noticia.etiquetas = JSON.stringify(etiquetas);
        let res = await this.$store.dispatch(
          "gravarEditarNoticia",
          this.noticia
        );
        if (res) {
          if (res.resultado == "OK") {
            this.successNotification("top-right", "success");
            await this.$store.dispatch("getAllNews");
          } else {
            this.noticia.etiquetas = [];
            console.log(this.noticia);
            this.errorNotification("top-right", "danger");
          }
        } else {
          this.errorNotification("top-right", "danger");
          this.noticia.etiquetas = [];
        }
      }
    },

    imgF() {
      setTimeout(() => {
        var img = document.getElementsByClassName("dz-preview-img");
        for (let i = 0; i < img.length; i++) {
          if (i === img.length - 1) {
            this.noticia.imagem = img[i].src;
          }
        }
      }, 200);
    },
  },
  mounted() {
    var etiquetas = JSON.parse(this.$store.state.noticiaEditada[0].etiquetas);
    var teste = Object.values(etiquetas);
    for (let i = 0; i < teste.length; i++) {
      this.noticia.etiquetas = teste[i];
    }
    console.log(etiquetas);
    setTimeout(() => {
      document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML = this.noticia.descricao;
    }, 1500);
  },
};
</script>
<style scoped>
.el-select__tags span {
  background-color: rgb(20, 130, 194) !important;
}

.imagemAtual {
  width: 50%;
  margin: auto;
}
</style>
