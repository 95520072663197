import axios from "axios";

const instance = axios.create({
  baseURL: "https://calendario.yourcode.pt/api/public",
  headers: {
    Authorization: "Basic eW91cmNvZGVfY2FsZW5kYXJpbzp3aVhHaWZSeVZkODNraTJpcmluRjgwYUs1dTJMa1lq",
    "content-type": "application/json",
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});

export default {

  async login(data) {
    try {
      const res = await instance.post("/api/login", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async registar(data) {
    try {
      const res = await instance.post("/api/registar", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async aprovarUtilizadores(data) {
    try {
      const res = await instance.post("/api/aprovarUtilizadores", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async desaprovarUtilizadores(data) {
    try {
      const res = await instance.post("/api/desaprovarUtilizadores", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarUtilizadores() {
    try {
      const res = await instance.post("/api/listarUtilizadores");
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async apagarUtilizador(data) {
    try {
      const res = await instance.post("/api/apagarUtilizador",data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  
  async promoverAdmin(data) {
    try {
      const res = await instance.post("/api/promoverAdmin", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async despromoverAdmin(data) {
    try {
      const res = await instance.post("/api/despromoverAdmin", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async calendario(data) {
    try {
      const res = await instance.post("/api/calendario", data);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async criarEventoCalendario(data) {
    try {
      const res = await instance.post("/api/calendario/criarEvento", data);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async editarEventoCalendario(data) {
    try {
      const res = await instance.post("/api/calendario/editarEvento", data);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async apagarEventoCalendario(data) {
    try {
      const res = await instance.post("/api/calendario/apagarEvento", data);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async todosEventosCalendario() {
    try {
      const res = await instance.get("/api/calendario/todos");
      return res;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

};
