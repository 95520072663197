<template>
  <loader
    v-show="carregar"
    object="rgb(20, 130, 194)"
    color1="#ffffff"
    color2="rgb(20, 130, 194)"
    size="5"
    speed="10"
    bg="#343a40"
    objectbg="#999793"
    opacity="80"
    name="circular"
  ></loader>
  <div v-if="rendered">
    <div id="slider" class="mb-5">
      <transition-group tag="div" :name="transitionName" class="slides-group">
        <div :style="fundoSlide()" class="slide">
          <div class="opacidadeSlide"></div>

          <h2 class="display-3 text-white tituloSlide">
            <router-link :to="'/noticia?id=' + destaques[current].id">
              {{ destaques[current].titulo }}
            </router-link>
          </h2>

          <p class="text-white textoSlide">
            <!-- {{ slides[current].descricao }}! -->
            {{ destaques[current].resumo_destaque }}
          </p>

          <router-link
            class="verNoticiaSlide"
            :to="'/noticia?id=' + destaques[current].id"
          >
            Ler notícia
          </router-link>
        </div>
      </transition-group>
      <div
        class="btn btn-prev text-white"
        aria-label="Previous slide"
        @click="slide(-1)"
      >
        &#10094;
      </div>
      <div
        class="btn btn-next text-white"
        aria-label="Next slide"
        @click="slide(1)"
      >
        &#10095;
      </div>
    </div>
  </div>
  <div class="container-fluid mt-4">
    <div class="card">
      <!-- Card header -->
      <div class="card-body mb-0 pb-0">
        <div class="col-lg-12 pl-0">
          <div class="row mb-5">
            <div
              v-for="item in slides"
              :key="item"
              class="col-lg-4 mb-5 pl-4 pr-4"
            >
              <router-link :to="'/noticia?id=' + item.id">
                <div
                  class="imagemGrelha mb-4"
                  :style="fundo(item.imagem)"
                ></div>
                <div class="row">
                  <div class="col-lg-12">
                    <h3 class="display-6">{{ item.titulo }}</h3>
                  </div>
                </div>
              </router-link>
              <div class="row mt-3">
                <div class="col-lg-12">
                  <p :class="listarInteracoes(item.id) + ' infoNoticia'">
                    <i class="ni ni-watch-time"></i> {{ item.data }}
                    <span
                      class="gostoCor"
                      @click="gostar(listarInteracoes(item.id), item.id)"
                    >
                      <i class="ml-3 ni ni-like-2"></i
                      ><span> {{ contadorGostos(item.id) }}</span>
                      Gosto(s)
                    </span>
                  </p>

                  <div class="partilha">
                    <base-dropdown
                      menu-on-right
                      tag="li"
                      title-tag="a"
                      title-classes="nav-link pr-0"
                    >
                      <template v-slot:title-container>
                        <i class="ml-3 ni ni-send" @click.prevent></i> Opções de
                        partilha
                      </template>

                      <ul class="dropdown-toggle" v-if="!item.url">
                        <div
                          class="nav-link"
                          @click="
                            copyPaste(
                              'https://calendario.yourcode.pt/noticia?id=' +
                                item.id
                            )
                          "
                        >
                          <span><i class="far fa-clone"></i></span>

                          <span style="color: #000"> Copiar Link Interno </span>
                        </div>
                      </ul>
                      <ul class="dropdown-toggle" v-if="item.url">
                        <div
                          class="nav-link"
                          @click="
                            partilhar(
                              'whatsapp',
                              item.id,
                              item.url,
                              item.titulo
                            )
                          "
                        >
                          <span class="whatsapp"
                            ><i class="fab fa-whatsapp"></i
                          ></span>

                          <span style="color: #000"> WhatsApp </span>
                        </div>
                        <div
                          class="nav-link"
                          @click="
                            partilhar('twitter', item.id, item.url, item.titulo)
                          "
                        >
                          <span class="twitter"
                            ><i class="fab fa-twitter"></i
                          ></span>
                          <span style="color: #000"> Twitter</span>
                        </div>
                        <div
                          class="nav-link"
                          @click="
                            partilhar(
                              'facebook',
                              item.id,
                              item.url,
                              item.titulo
                            )
                          "
                        >
                          <span class="facebook"
                            ><i class="fab fa-facebook"></i
                          ></span>
                          <span style="color: #000"> Facebook</span>
                        </div>
                      </ul>
                    </base-dropdown>
                  </div>
                </div>
              </div>
              <div id="descricao">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  scelerisque diam ac sollicitudin volutpat.Lorem ipsum dolor
                  sit amet, consectetur adipiscing elit. Nulla.
                </p>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <router-link
                    class="verNoticiaGrelha"
                    :to="'/noticia?id=' + item.id"
                  >
                    Ler notícia
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-lg-12 text-center">
              <router-link to="/noticias" class="verNoticiaTodas"
                >Ver todas as Notícias</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      carregar: true,
      rendered: false,
      selectOptions: [
        {
          label: "Etiqueta",
          value: "Selecionar",
        },
      ],
      selects: {
        simple: "Selecionar",
      },
      filtrado: Object.values(this.$store.state.news),
      current: 0,
      direction: 1,
      transitionName: "fade",
      slides: Object.values(this.$store.state.news),
      imagemFundo: "",
      todasInteracoes: [],
      interacoesGostos: [],
      gostou: "gosto",
      destaques: [],
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "Link da notícia copiado!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: text,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    copyPaste(url) {
      navigator.clipboard.writeText(url);
      this.successNotification("top-right", "success");
    },

    partilhar(onde, id, link, titulo) {
      if (onde == "whatsapp") {
        window.open("whatsapp://send?text=" + link);
      } else if (onde == "twitter") {
        window.open("http://twitter.com/share?text=" + titulo + "&url=" + link);
      } else if (onde == "facebook") {
        window.open(
          "https://www.facebook.com/share.php?u=" + link + "&quote=" + titulo
        );
      }
    },

    contadorGostos(idNoticia) {
      var contador = 0;
      var quantidade = this.todasInteracoes.filter(
        (x) => x.id_noticia == idNoticia
      );
      for (let i = 0; i < quantidade.length; i++) {
        if (quantidade[i].gosto == 1) {
          contador++;
        }
      }
      return " " + contador;
    },

    async gostar(data, idNoticia) {
      var todas = [];
      todas.push(
        this.todasInteracoes.find(
          (x) =>
            x.id_noticia == idNoticia &&
            x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
        )
      );

      var indexInteracoesGostos = this.interacoesGostos
        .map((x) => x.id_noticia)
        .indexOf(idNoticia);
      var entryUUid = localStorage.getItem("idYourCodeCalendar");
      if (data == false) {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos[indexInteracoesGostos].gosto = 1;
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 1;
            }
          });
        } else {
          this.interacoesGostos.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
          this.todasInteracoes.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
        }

        // this.listarInteracoes();
        var json = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 1,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json);
      } else {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos.splice(indexInteracoesGostos, 1);
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 0;
            }
          });
        }
        var json1 = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 0,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json1);
        this.listarInteracoes();
      }
    },

    filtradoTeste(data) {
      this.slides = Object.values(data);
      this.filtrado = Object.values(data);
      var destaques = Object.values(data);
      var temp = [];
      for (let i = 0; i < destaques.length; i++) {
        if (destaques[i].destaque == "1" && destaques[i].resumo_destaque) {
          temp.push(destaques[i]);
        }
      }
      console.log(destaques);
      this.destaques = temp;
      this.rendered = true;
      this.carregar = false;
    },

    fundo(imagem) {
      return "background-image: url(" + imagem + ");";
    },

    fundoSlide() {
      return (
        "background-image: url(" + this.destaques[this.current].imagem + ");"
      );
    },

    filtrar(etiqueta) {
      this.filtrado = [];
      this.current = 0;

      if (etiqueta == "Selecionar") {
        this.filtrado = Object.values(this.$store.state.news);
      } else {
        if (
          Object.values(this.$store.state.news).filter((x) =>
            x.etiquetas.includes(etiqueta)
          ).length > 0
        ) {
          var noticiaFiltrada = Object.values(
            this.$store.state.news
          ).filter((x) => x.etiquetas.includes(etiqueta));
          for (let i = 0; i < noticiaFiltrada.length; i++) {
            this.filtrado.push(noticiaFiltrada[i]);
          }
        }
      }
    },

    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      var len = this.destaques.length;
      this.current = (this.current + (dir % len) + len) % len;
    },

    listarInteracoes(id) {
      var interacoesNoticia = this.interacoesGostos;
      var converterInteracoesNoticia = Object.values(interacoesNoticia);

      var procuraInteracao = converterInteracoesNoticia.find(
        (x) => x.id_noticia == id
      );
      if (procuraInteracao) {
        if (procuraInteracao.gosto == 1) {
          return this.gostou;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  async mounted() {
    var news = [];
    if (this.$store.state.news.length == undefined) {
      news = this.$store.state.news;
      this.filtradoTeste(news);
    } else {
      news = await this.$store.dispatch("getNews");
      this.filtradoTeste(news);
    }
    var id = {
      entryUUID: localStorage.getItem("idYourCodeCalendar"),
    };
    let interacoes = await this.$store.dispatch("listarInteracoes", id);
    let todasInteracoes = await this.$store.dispatch("listarTodasInteracoes");
    this.todasInteracoes = Object.values(todasInteracoes);
    this.interacoesGostos = Object.values(interacoes);
    // console.log(this.todasInteracoes);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");
.dropdown-toggle::after {
  display: none;
}
.instagram {
  color: #d6249f;
}
.facebook {
  color: #4267b2;
}
.twitter {
  color: #1da1f2;
}
.whatsapp {
  /* color: #075e54; */
  color: #7fff00;
}
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
/* .slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
/* .slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

/* SLIDES CLASSES */
.height {
  max-height: 450px;
  max-width: 800px;
}

body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
}

#slider {
  width: 100%;
  height: 55vh;
  position: relative;
  box-shadow: 0px 0px 10px #666;
}

.slide {
  width: 100%;
  height: 55vh;
  position: absolute;
  top: 5;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.opacidadeSlide {
  background: rgb(20, 130, 194);
  height: 55vh;
  width: 100%;
  overflow: auto;
  opacity: 0.5;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.btn {
  z-index: 10;
  cursor: pointer;
  border: 2px solid rgb(20, 130, 194);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 50px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}

.infoNoticia {
  font-size: 12px;
  max-width: 250px;
  float: left;
}

.partilha {
  font-size: 12px;
}

.imagemGrelha {
  width: 100%;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.375rem;
}

.tituloSlide {
  position: absolute;
  top: 30%;
  text-shadow: 2px 2px 8px #000;
}

.tituloSlide:hover a {
  color: #ccc !important;
}

.textoSlide {
  position: absolute;
  top: 45%;
  text-shadow: 2px 2px 8px #000;
  text-align: center;
}

.verNoticiaSlide {
  position: absolute;
  left: auto;
  top: 65%;
  width: auto;
  height: 30px;
  text-align: center;
  background-color: transparent;
  border-bottom: 2px solid #fff !important;
  color: #fff;
  text-shadow: 1px 1px 8px #000;
  padding: 0 5px;
  font-weight: bold;
}

.verNoticiaSlide:hover {
  color: #ccc;
}

.verNoticiaGrelha {
  width: auto;
  height: 30px;
  text-align: center;
  background-color: transparent;
  color: rgb(20, 130, 194);
  padding: 0 5px;
  font-weight: bold;
  font-size: 14px;
}

.verNoticiaGrelha:hover {
  color: #ccc;
}

.verNoticiaTodas {
  width: auto;
  height: 30px;
  text-align: center;
  background-color: transparent;
  color: rgb(20, 130, 194);
  padding: 0 5px;
  font-weight: bold;
  font-size: 16px;
}

.verNoticiaTodas:hover {
  color: #ccc;
}

.descricaoGrelha p {
  font-size: 14px;
}

.gostoCor {
  cursor: pointer;
}

.gosto .gostoCor {
  color: red;
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
</style>
