<template>
  <div v-if="rendered">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">
            {{ $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>

    <div v-if="rendered" class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">Utilizadores</h3>
            </div>
            <div class="col">
              <input
                type="search"
                class="form-control"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px; float: right"
                placeholder="Pesquisar"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
          </div>
        </div>
        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="queriedData"
        >
          <el-table-column label="Nome" min-width="100px" prop="nome" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Email" prop="Email">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.email }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data de Inicio" prop="date">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.data_inicio_funcao }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Função" prop="funcao">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.funcao }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="name">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <v-button
                    class="btn base-button btn-primary btn-sm btn-primary"
                    type="success"
                    @click="verPerfil(row.id)"
                    >Ver Perfil
                  </v-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- MODALS -->
        <modal
          class="modalPerfil"
          v-if="utilizadorSelecionado.length != 0"
          v-model:show="modals.classic"
        >
          <div class="card card-profile">
            <div
              class="card-img-top"
              style="background-image: url(/img/theme/img-1-1000x600.jpg)"
            />
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <img
                    :src="utilizadorSelecionado.avatar"
                    class="rounded-circle"
                  />
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
            ></div>
            <div class="card-body pt-0">
              <div class="text-center">
                <h5 class="h3">
                  {{
                    utilizadorSelecionado.nome +
                    " " +
                    utilizadorSelecionado.apelido
                  }}
                </h5>
                <div class="h5 font-weight-300">
                  <i class="ni ni-email-83"></i>
                  {{ utilizadorSelecionado.email }}
                </div>
                <div class="h5 font-weight-300">
                  <i class="ni ni-mobile-button"></i>
                  {{ utilizadorSelecionado.telemovel }}
                </div>
                <div class="h5 font-weight-300">
                  <i class="ni ni-briefcase-24"></i>
                  {{ utilizadorSelecionado.funcao }}
                </div>
                <div
                  v-if="utilizadorSelecionado.desporto"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-running"></i>
                  {{ utilizadorSelecionado.desporto.replaceAll(",", ", ") }}
                </div>
                <div
                  v-if="utilizadorSelecionado.territorio_responsabilidade"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-globe-europe"></i>
                  {{ utilizadorSelecionado.territorio_responsabilidade }}
                </div>
                <div
                  v-if="utilizadorSelecionado.responsabilidades_individuais"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-check-square"></i>
                  {{ utilizadorSelecionado.responsabilidades_individuais }}
                </div>
                <div
                  v-if="utilizadorSelecionado.descricao"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-address-card"></i> Sobre mim:<br />
                  {{ utilizadorSelecionado.descricao }}
                </div>
                <div
                  v-if="utilizadorSelecionado.missao_preenchida == 1"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-bullseye"></i>
                  <span @click="modalMissao()">A minha Missão</span>
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div
              type="link"
              class="text-white ml-auto text-right"
              @click="fecharModal()"
            >
              Fechar
            </div>
          </template>
        </modal>

        <modal
          class="modalPerfil"
          v-if="utilizadorSelecionado.length != 0"
          v-model:show="modals.perfil"
        >
          <div class="card card-profile">
            <div
              class="card-img-top"
              style="background-image: url(/img/theme/img-1-1000x600.jpg)"
            />
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <img
                    :src="utilizadorSelecionado.avatar"
                    class="rounded-circle"
                  />
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
            ></div>
            <div class="card-body pt-0">
              <div class="text-center">
                <h5 class="h3">
                  {{
                    utilizadorSelecionado.nome +
                    " " +
                    utilizadorSelecionado.apelido
                  }}
                </h5>
                <div class="h5 font-weight-300">
                  <i class="ni ni-email-83"></i>
                  {{ utilizadorSelecionado.email }}
                </div>
                <div class="h5 font-weight-300">
                  <i class="ni ni-mobile-button"></i>
                  {{ utilizadorSelecionado.telemovel }}
                </div>
                <div class="h5 font-weight-300">
                  <i class="ni ni-briefcase-24"></i>
                  {{ utilizadorSelecionado.funcao }}
                </div>
                <div
                  v-if="utilizadorSelecionado.desporto"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-running"></i>
                  {{ utilizadorSelecionado.desporto.replaceAll(",", ", ") }}
                </div>
                <div
                  v-if="utilizadorSelecionado.territorio_responsabilidade"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-globe-europe"></i>
                  {{ utilizadorSelecionado.territorio_responsabilidade }}
                </div>
                <div
                  v-if="utilizadorSelecionado.responsabilidades_individuais"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-check-square"></i>
                  {{ utilizadorSelecionado.responsabilidades_individuais }}
                </div>
                <div
                  v-if="utilizadorSelecionado.descricao"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-address-card"></i> Sobre mim:<br />
                  {{ utilizadorSelecionado.descricao }}
                </div>
                <div
                  v-if="utilizadorSelecionado.missao_preenchida == 1"
                  class="h5 font-weight-300"
                >
                  <i class="fas fa-bullseye"></i>
                  <span
                    class="pointer"
                    @click="modalMissao(utilizadorSelecionado)"
                    >A minha Missão</span
                  >
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div
              type="link"
              class="text-white ml-auto text-right"
              @click="fecharModal()"
            >
              Fechar
            </div>
          </template>
        </modal>

        <modal
          class="modalPerfil"
          v-if="utilizadorSelecionado.length != 0"
          v-model:show="modals.missao"
        >
          <div class="card">
            <div class="row justify-content-center">
              <div class="col-lg-10 order-lg-10">
                <div class="card-profile-image"></div>
                <div
                  class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
                ></div>
                <div class="card-body pt-0">
                  <div class="text-center">
                    <h5 class="h3">
                      <img
                        style="max-width: 30px"
                        :src="utilizadorSelecionado.avatar"
                        class="rounded-circle"
                      />{{
                        " " +
                        utilizadorSelecionado.nome +
                        " " +
                        utilizadorSelecionado.apelido
                      }}
                    </h5>
                    <div class="h5 font-weight-300">
                      Título da Missão: {{ missao.titulo }}
                    </div>
                    <div class="h5 font-weight-300">
                      Resumo da missão:
                      <span v-html="missao.descricao"></span>
                    </div>
                    <div class="h5 font-weight-300">
                      {{ missao.data }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div
              type="link"
              class="text-white ml-auto text-right"
              @click="fecharModalMissao()"
            >
              Fechar
            </div>
          </template>
        </modal>
        <!-- FIM MODALS -->
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import Ajuda from "../Components/Ajuda";
import Modal from "@/components/Modal";
export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      utilizadores: [],
      searchQuery: "",
      utilizadorSelecionado: [],
      propsToSearch: ["nome", "apelido", "email", "funcao"],
      rendered: false,
      missao: {
        titulo: "",
        descricao: "",
        data: "",
      },
      modals: {
        perfil: false,
        missao: false,
        form: false,
      },
    };
  },
  methods: {
    fecharModal() {
      this.modals.perfil = false;
    },
    verPerfil(id) {
      var utilizadores = Object.values(this.utilizadores);
      this.utilizadorSelecionado = utilizadores.find((x) => x.id == id);
      this.modals.perfil = true;
    },
    modalMissao(user) {
      // console.log(user);
      this.modals.perfil = false;
      this.missao.titulo = user.titulo;
      this.missao.descricao = user.descricao_missao;
      this.missao.data = user.data_criacao;
      this.modals.missao = true;
    },
    fecharModalMissao() {
      this.modals.missao = false;
    },
  },
  computed: {
    queriedData() {
      if (!this.searchQuery) {
        return Object.values(this.utilizadores);
      }
      let result = Object.values(this.utilizadores).filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
  },
  async mounted() {
    var utilizadores = [];
    if (this.$store.state.todosUtilizadores.length == undefined) {
      utilizadores = this.$store.state.todosUtilizadores;
      this.utilizadores = utilizadores;

      this.rendered = true;
    } else {
      utilizadores = await this.$store.dispatch("todosUtilizadores");
      this.utilizadores = utilizadores;
      this.utilizadores = this.utilizadores.filter(
        (x) => x.entryUUID != localStorage.getItem("idYourCodeCalendar")
      );
      this.rendered = true;
    }
  },
};
</script>
<style>
.modalPerfil .modal-body {
  padding: 0 !important;
}
.pointer {
  cursor: pointer;
}
</style>
