import { createStore } from "vuex";
import api from "@/api/index";

const initialState = () => {
  return {
    resultApi:""
  };
};

const store = createStore({
  state: initialState(),
  // plugins: [createPersistedState()],
  mutations: {
    UPDATE_STATE(state, payload) {
      state[payload.item] = payload.data;
    },
    RESET_STATE(state) {
      Object.assign(state, initialState());
    },
    SET_GLOBAL_VARIABLE(state, payload) {
      state[payload.var] = payload.value;
    },
    setactiveStoreName(state, person) {
      state.activeStoreName = person;
    },
  },
  actions: {


    login: async function ({ commit }, data) {
      let res = await api.login(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },

    registar: async function ({ commit }, data) {
      let res = await api.registar(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },
    apagarUtilizador: async function ({ commit }, data) {
      let res = await api.apagarUtilizador(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },

    aprovarUtilizadores: async function ({ commit }, data) {
      let res = await api.aprovarUtilizadores(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },
    desaprovarUtilizadores: async function ({ commit }, data) {
      let res = await api.desaprovarUtilizadores(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },
    listarUtilizadores: async function ({ commit }) {
      let res = await api.listarUtilizadores();
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },
    promoverAdmin: async function ({ commit }, data) {
      let res = await api.promoverAdmin(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },
    despromoverAdmin: async function ({ commit }, data) {
      let res = await api.despromoverAdmin(data);
      commit("SET_GLOBAL_VARIABLE", {
        var: "resultApi",
        value: res,
      });
      return res;
    },

    calendario: async function ({ commit }, data) {
      let res = await api.calendario(data);
      commit("SET_GLOBAL_VARIABLE", { var: "resultApi", value: res.data });
      return res.data;
    },
    criarEventoCalendario: async function ({ commit }, data) {
      let res = await api.criarEventoCalendario(data);
      commit("SET_GLOBAL_VARIABLE", { var: "calendario", value: res.data });
      return res.data;
    },
    editarEventoCalendario: async function ({ commit }, data) {
      let res = await api.editarEventoCalendario(data);
      commit("SET_GLOBAL_VARIABLE", { var: "calendario", value: res.data });
      return res.data;
    },
    apagarEventoCalendario: async function ({ commit }, data) {
      let res = await api.apagarEventoCalendario(data);
      commit("SET_GLOBAL_VARIABLE", { var: "calendario", value: res.data });
      return res.data;
    },
    todosEventosCalendario: async function ({ commit }) {
      let res = await api.todosEventosCalendario();
      commit("SET_GLOBAL_VARIABLE", { var: "calendario", value: res.data });
      return res.data;
    },

  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
});

export default store;
