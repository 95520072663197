<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Detalhes</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <form>
            <div class="pl-lg-0">
              <div class="row">
                <div class="col-lg-4">
                  <label class="form-control-label"> Título: </label>
                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Título"
                    v-model="noticia.titulo"
                  />
                </div>
                <div class="col-lg-4">
                  <label class="form-control-label"> Link Alternativo: </label>
                  <input
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Link Alternativo"
                    v-model="noticia.url"
                  />
                </div>
                <div class="col-lg-4">
                  <base-input name="selectmultiple" label="Etiquetas:">
                    <el-select
                      v-model="noticia.etiquetas"
                      multiple
                      filterable
                      placeholder="Etiquetas"
                    >
                      <el-option
                        v-for="option in selectOptions"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label">Imagem da Notícia:</label>
                  <dropzone-file-upload
                    style="border: 1px dashed #000; border-radius: 0.375rem"
                    @change="imgF()"
                    v-model="noticia.imagem"
                  ></dropzone-file-upload>
                </div>
              </div>
            </div>
          </form>

          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <label class="form-control-label">Descrição da Notícia </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 text-left">
              <form>
                <html-editor></html-editor>
              </form>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="saveNews"
                >Guardar</v-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { ElSelect, ElOption } from "element-plus";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
export default {
  name: "Criar-Notícias",
  components: {
    HtmlEditor,
    DropzoneFileUpload,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      user: [],
      selectOptions: [],
      noticia: {
        titulo: "",
        descricao: "",
        url: "",
        imagem: "",
        data: "",
        etiquetas: [],
        autor: "",
        autor_entryUUID: "",
        destaque: 0,
        publicar: 0,
      },
      switches: {
        off: true,
        primary: true,
      },
      selects: {
        simple: "Badges",
        multiple: [],
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "Sugestão enviada com sucesso!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao criar a sugestão! Verifique se os campos estão devidamente preenchidos!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    async saveNews() {
      this.noticia.autor = this.user.nome + " " + this.user.apelido;
      this.noticia.autor_entryUUID = this.user.entryUUID;
      this.noticia.descricao = document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML;
      if (
        this.noticia.titulo == "" ||
        this.noticia.descricao == "" ||
        this.noticia.url == "" ||
        this.noticia.imagem == "" ||
        this.noticia.etiquetas.length <= 0
      ) {
        this.errorNotification("top-right", "danger");
      } else {
        var etiquetas = {
          items: this.noticia.etiquetas,
        };
        this.noticia.etiquetas = JSON.stringify(etiquetas);
        let res = await this.$store.dispatch("createSugestion", this.noticia);
        if (res) {
          if (res.resultado == "OK") {
            this.successNotification("top-right", "success");
          } else {
            this.noticia.etiquetas = [];
            console.log(this.noticia);
            this.errorNotification("top-right", "danger");
          }
        } else {
          this.errorNotification("top-right", "danger");
          this.noticia.etiquetas = [];
        }
      }
    },

    imgF() {
      setTimeout(() => {
        var img = document.getElementsByClassName("dz-preview-img");
        for (let i = 0; i < img.length; i++) {
          if (i === img.length - 1) {
            this.noticia.imagem = img[i].src;
          }
        }
      }, 200);
    },
  },
  async mounted() {
    this.user = this.$store.state.profileDetails;
    var etiquetas = await this.$store.dispatch("etiquetas");
    for (let i = 0; i < etiquetas.length; i++) {
      this.selectOptions.push({
        label: etiquetas[i].etiqueta,
        value: etiquetas[i].etiqueta,
      });
    }
  },
};
</script>
<style scoped>
.el-select__tags span {
  background-color: rgb(20, 130, 194) !important;
}
</style>
