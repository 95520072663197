<template>
  <loader
    v-show="carregar"
    object="rgb(20, 130, 194)"
    color1="#ffffff"
    color2="rgb(20, 130, 194)"
    size="5"
    speed="10"
    bg="#343a40"
    objectbg="#999793"
    opacity="80"
    name="circular"
  ></loader>
  <div v-if="rendered">
    <div>
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>

            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
              <route-bread-crumb> </route-bread-crumb>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <Ajuda />
          </div>
        </div>
      </base-header>
    </div>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header mb-0 pb-0">
          <div class="col-lg-12 pl-0">
            <div class="col-lg-3 pl-0 text-left">
              <label class="form-control-label">Filtrar por Etiqueta</label>
              <base-input name="select">
                <el-select
                  v-model="selects.simple"
                  filterable
                  placeholder="Filtrar Notícias"
                  @change="filtrar(selects.simple)"
                >
                  <el-option
                    v-for="option in selectOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-lg-8 mt-0">
              <h1 class="display-3 ml-1 text-capitalize">
                {{ filtrado[current].titulo }}
              </h1>
            </div>
            <div class="col-lg-4 text-right">
              <div class="row">
                <div class="col-lg-12">
                  <p class="ml-2 infoNoticia">
                    <i class="ni ni-watch-time"></i>
                    {{ filtrado[current].data }}
                    <i class="ni ni-single-02 ml-2"></i>
                    {{ filtrado[current].autor }}
                  </p>
                  <p
                    :class="
                      listarInteracoes(filtrado[current].id) + ' infoNoticia'
                    "
                  >
                    <span
                      class="gostoCor"
                      @click="
                        gostar(
                          listarInteracoes(filtrado[current].id),
                          filtrado[current].id
                        )
                      "
                    >
                      <i class="ml-3 ni ni-like-2"></i
                      ><span> {{ contadorGostos(filtrado[current].id) }}</span>
                      Gosto(s)
                    </span>

                    <base-dropdown
                      menu-on-right
                      tag="li"
                      title-tag="a"
                      title-classes="nav-link pr-0"
                    >
                      <template v-slot:title-container>
                        <i class="ml-3 ni ni-send" @click.prevent></i> Opções de
                        partilha
                      </template>

                      <ul class="dropdown-toggle" v-if="filtrado[current].url">
                        <div
                          class="nav-link"
                          @click="
                            partilhar(
                              'whatsapp',
                              filtrado[current].id,
                              filtrado[current].url,
                              filtrado[current].titulo
                            )
                          "
                        >
                          <span class="whatsapp"
                            ><i class="fab fa-whatsapp"></i
                          ></span>

                          <span style="color: #000"> WhatsApp </span>
                        </div>
                        <div
                          class="nav-link"
                          @click="
                            partilhar(
                              'twitter',
                              filtrado[current].id,
                              filtrado[current].url,
                              filtrado[current].titulo
                            )
                          "
                        >
                          <span class="twitter"
                            ><i class="fab fa-twitter"></i
                          ></span>
                          <span style="color: #000"> Twitter</span>
                        </div>
                        <div
                          class="nav-link"
                          @click="
                            partilhar(
                              'facebook',
                              filtrado[current].id,
                              filtrado[current].url,
                              filtrado[current].titulo
                            )
                          "
                        >
                          <span class="facebook"
                            ><i class="fab fa-facebook"></i
                          ></span>
                          <span style="color: #000"> Facebook</span>
                        </div>
                      </ul>
                      <ul class="dropdown-toggle" v-if="!filtrado[current].url">
                        <div
                          class="nav-link"
                          @click="
                            copyPaste(
                              'https://calendario.yourcode.pt/noticia?id=' +
                                filtrado[current].id
                            )
                          "
                        >
                          <span><i class="far fa-clone"></i></span>

                          <span style="color: #000"> Copiar Link Interno </span>
                        </div>
                      </ul>
                    </base-dropdown>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="slider">
            <transition-group
              tag="div"
              :name="transitionName"
              class="slides-group"
            >
              <a :href="filtrado[current].url" target="_blank">
                <div
                  v-if="show"
                  :key="current"
                  class="slide"
                  :style="fundoSlide()"
                ></div>
              </a>
            </transition-group>
            <div
              class="btn1 btn-prev"
              aria-label="Previous slide"
              @click="slide(-1)"
            >
              &#10094;
            </div>
            <div
              class="btn1 btn-next"
              aria-label="Next slide"
              @click="slide(1)"
            >
              &#10095;
            </div>
          </div>
          <div class="container-fluid mt-5">
            <div id="descricao" v-html="filtrado[current].descricao"></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 mt-0">
              <h1 class="display-3 ml-0 text-capitalize">Comentários:</h1>
            </div>
          </div>
          <div v-if="comentarios.length > 0">
            <div v-for="(item, i) in comentarios" :key="i">
              <p class="comentarioLabel">
                Utilizador:
                <span> {{ item.nome + " " + item.apelido }}</span> Data:
                <span>{{ item.data }}</span>
              </p>
              <p>
                <span>{{ item.comentario }}</span>
              </p>
              <hr />
            </div>
          </div>
          <div v-else>
            <p>Ainda não existem comentários...</p>
          </div>
          <div>
            <!-- Card body -->
            <div class="card-body">
              <div class="col-lg-12 pl-0 pr-0">
                <div class="form-group">
                  <textarea
                    rows="4"
                    class="form-control"
                    placeholder="Novo Comentário"
                    v-model="novoComentario"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-left pr-0 pl-0">
            <v-button
              class="btn base-button btn-success"
              type="success"
              @click="enviarComentario(filtrado[current].id)"
              >Enviar Comentário</v-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import Ajuda from "../Components/Ajuda";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Ajuda,
  },
  data() {
    return {
      todasInteracoes: [],
      interacoesGostos: [],
      gostou: "gosto",
      comentarios: [],
      comentariosAjuda: [],
      comentariosTodos: [],
      novoComentario: "",
      selectOptions: [
        {
          label: "Selecionar",
          value: "Selecionar",
        },
      ],
      selects: {
        simple: "Selecionar",
      },
      carregar: true,
      rendered: false,
      filtrado: [],
      current: 0,
      direction: 1,
      transitionName: "fade",
      show: true,
      slides: [],
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "Link da notícia copiado!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: text,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    copyPaste(url) {
      navigator.clipboard.writeText(url);
      this.successNotification("top-right", "success");
    },
    partilhar(onde, id, link, titulo) {
      if (onde == "whatsapp") {
        window.open("whatsapp://send?text=" + link);
      } else if (onde == "twitter") {
        window.open("http://twitter.com/share?text=" + titulo + "&url=" + link);
      } else if (onde == "facebook") {
        window.open(
          "https://www.facebook.com/share.php?u=" + link + "&quote=" + titulo
        );
      }
    },

    contadorGostos(idNoticia) {
      var contador = 0;
      var quantidade = this.todasInteracoes.filter(
        (x) => x.id_noticia == idNoticia
      );
      for (let i = 0; i < quantidade.length; i++) {
        if (quantidade[i].gosto == 1) {
          contador++;
        }
      }
      return " " + contador;
    },
    listarInteracoes(id) {
      var interacoesNoticia = this.interacoesGostos;
      var converterInteracoesNoticia = Object.values(interacoesNoticia);

      var procuraInteracao = converterInteracoesNoticia.find(
        (x) => x.id_noticia == id
      );
      if (procuraInteracao) {
        if (procuraInteracao.gosto == 1) {
          return this.gostou;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async gostar(data, idNoticia) {
      var todas = [];
      todas.push(
        this.todasInteracoes.find(
          (x) =>
            x.id_noticia == idNoticia &&
            x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
        )
      );

      var indexInteracoesGostos = this.interacoesGostos
        .map((x) => x.id_noticia)
        .indexOf(idNoticia);
      var entryUUid = localStorage.getItem("idYourCodeCalendar");
      if (data == false) {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos[indexInteracoesGostos].gosto = 1;
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 1;
            }
          });
        } else {
          this.interacoesGostos.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
          this.todasInteracoes.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
        }

        // this.listarInteracoes();
        var json = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 1,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json);
      } else {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos.splice(indexInteracoesGostos, 1);
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 0;
            }
          });
        }
        var json1 = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 0,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json1);
        this.listarInteracoes();
      }
    },
    async enviarComentario(id_noticia) {
      var comentario = this.novoComentario;
      if (comentario != "") {
        let jsonComentario = {
          id_noticia: id_noticia,
          id_utilizador: localStorage.getItem("idYourCodeCalendar"),
          comentario: comentario,
        };
        let res = await this.$store.dispatch("novoComentario", jsonComentario);
        if (res) {
          if (res.data.resultado == "OK") {
            this.buscarComentarios();
          }
        }
      }
    },

    filtrar(etiqueta) {
      this.filtrado = [];
      this.current = 0;
      if (etiqueta == "Selecionar") {
        this.filtrado = this.slides;
      } else {
        if (
          Object.values(this.slides).filter((x) =>
            x.etiquetas.includes(etiqueta)
          ).length > 0
        ) {
          var noticiaFiltrada = Object.values(this.slides).filter((x) =>
            x.etiquetas.includes(etiqueta)
          );
          for (let i = 0; i < noticiaFiltrada.length; i++) {
            this.filtrado.push(noticiaFiltrada[i]);
          }
        }
      }
    },

    iframe() {
      var alterarDescricao = this.slides;
      for (let i = 0; i < alterarDescricao.length; i++) {
        alterarDescricao[i].descricao = alterarDescricao[i].descricao
          .replaceAll(/&lt;/g, "<")
          .replace(/&gt;/g, ">");
      }
      var alterarDescricaofiltrado = this.filtrado;
      for (let i = 0; i < alterarDescricaofiltrado.length; i++) {
        alterarDescricao[i].descricao = alterarDescricao[i].descricao
          .replaceAll(/&lt;/g, "<")
          .replace(/&gt;/g, ">");
      }
      this.slides = alterarDescricao;
      this.filtrado = alterarDescricaofiltrado;
    },

    slide(dir) {
      this.comentarios = this.comentariosTodos;
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      var len = this.filtrado.length;
      this.current = (this.current + (dir % len) + len) % len;
      var comentariosEncontrar = this.comentarios.filter(
        (x) => x.id == this.filtrado[this.current].id
      );
      if (comentariosEncontrar.length > 0) {
        this.comentarios = comentariosEncontrar;
      } else {
        this.comentarios = [];
      }
    },

    filtradoInicio(data) {
      this.slides = Object.values(data);
      this.filtrado = Object.values(data);
      this.iframe();
      this.rendered = true;
      this.carregar = false;
      let slides = this.slides;
      let temp = [];
      let etiquetas1 = [];

      for (let i = 0; i < slides.length; i++) {
        temp.push(JSON.parse(slides[i].etiquetas));
        for (let k = 0; k < temp.length; k++) {
          etiquetas1.push(temp[k].items);
        }
        var unico = [...new Set(etiquetas1)];
        var indexacao = unico.reduce(function (prev, curr) {
          return prev.concat(curr, unico[prev.length / 2]);
        }, []);
        var etiquetasFinal = [...new Set(indexacao)];
        etiquetasFinal = etiquetasFinal.filter(function (element) {
          return element != undefined;
        });
      }
      for (let i = 0; i < etiquetasFinal.length; i++) {
        this.selectOptions.push({
          label: etiquetasFinal[i],
          value: etiquetasFinal[i],
        });
      }
    },
    async buscarComentarios() {
      let resultado = await this.$store.dispatch("todosComentarios");
      this.comentariosTodos = Object.values(resultado);
      this.comentarios = this.comentariosTodos.filter(
        (x) => x.id == this.filtrado[this.current].id
      );
      this.comentariosAjuda = this.comentariosTodos.filter(
        (x) => x.id == this.filtrado[this.current].id
      );
    },
    fundoSlide() {
      return (
        "background-image: url(" + this.filtrado[this.current].imagem + ");"
      );
    },
  },

  async mounted() {
    var news = [];
    if (this.$store.state.news.length == undefined) {
      news = this.$store.state.news;
      this.filtradoInicio(news);
    } else {
      news = await this.$store.dispatch("getNews");
      this.filtradoInicio(news);
    }
    this.buscarComentarios();
    var id = {
      entryUUID: localStorage.getItem("idYourCodeCalendar"),
    };
    let interacoes = await this.$store.dispatch("listarInteracoes", id);
    let todasInteracoes = await this.$store.dispatch("listarTodasInteracoes");
    this.todasInteracoes = Object.values(todasInteracoes);
    this.interacoesGostos = Object.values(interacoes);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");
.dropdown-toggle::after {
  display: none;
}
.instagram {
  color: #d6249f;
}
.facebook {
  color: #4267b2;
}
.twitter {
  color: #1da1f2;
}
.whatsapp {
  /* color: #075e54; */
  color: #7fff00;
}
.gostoCor {
  cursor: pointer;
}

.gosto .gostoCor {
  color: red;
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.partilha {
  font-size: 12px;
}
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
/* .slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
/* .slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

/* SLIDES CLASSES */
.height {
  max-height: 450px;
  max-width: 800px;
}

body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
}

#slider {
  width: 100%;
  height: 55vh;
  position: relative;
}

.slide {
  width: 100%;
  height: 55vh;
  position: absolute;
  top: 5;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.btn1 {
  z-index: 10;
  cursor: pointer;
  border: 2px solid rgb(20, 130, 194);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: absolute;
  top: calc(50% - 50px);
  left: 1%;
  transition: transform 0.3s ease-in-out;
  user-select: none;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn1:hover {
  transform: scale(1.1);
}

.infoNoticia {
  font-size: 12px;
  max-width: 250px;
  float: left;
}

.comentarioLabel {
  font-size: 12px;
}
</style>
