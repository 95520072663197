<template>
  <div>
    <base-header class="pb-6 mb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">
            Olá {{ user.nome + " " + user.apelido }}, como corre o teu dia? 😎
          </h3>
        </div>
        <div class="card-body">
          <div class="row pb-5 pt-5 container-fluid">
            <UserHapiness />
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="mb-4">
        <card>
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">Feedback Geral</h3>
              </div>
            </div>
          </template>
          <div class="card-body container-fluid">
            <div class="row pb-5 pt-5">
              <div class="chart-area text-center mauto">
                <canvas :width="750" id="graficoFeedbacks"></canvas>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import UserHapiness from "./UserProfile/UserHapiness";
import Ajuda from "../Components/Ajuda";
// import { barChartStacked } from "../../components/Charts/Feedbacks";
import Chart from "chart.js";
import "../../components/Charts/roundedCornersExtension";

export default {
  components: {
    UserHapiness,
    Ajuda,
  },
  data() {
    return {
      user: this.$store.state.profileDetails,
      feedbacks: this.$store.state.feedbacks,
      feedbackNegativo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      feedbackNeutro: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      feedbackFeliz: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      feedbacksComentarios: [],
      barChartStackedID: "1",
      dataGraficos: [],
    };
  },
  methods: {
    async listarFeedbacks() {
      this.feedbacks = await this.$store.dispatch("listarFeedbacks");
      this.valores();
    },

    criarGrafico() {
      const ctx = document.getElementById("graficoFeedbacks").getContext("2d");

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          datasets: [
            {
              label: "Feedback Negativo %",
              maxBarThickness: 10,
              tension: 0.4,
              data: this.feedbackNegativo,
              backgroundColor: "#f5365c",
            },
            {
              label: "Feedback Neutro %",
              maxBarThickness: 10,
              tension: 0.4,
              data: this.feedbackNeutro,
              backgroundColor: "#ff9800",
            },
            {
              label: "Feedback Feliz %",
              maxBarThickness: 10,
              tension: 0.4,
              data: this.feedbackFeliz,
              backgroundColor: "#2dce89",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 0,
          barPercentage: 1.6,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: false,
          },
          scales: {
            display: false,
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  borderDash: [2],
                  borderDashOffset: [2],
                  zeroLineColor: "#dee2e6",
                  drawBorder: false,
                  drawTicks: false,
                  lineWidth: 1,
                  zeroLineWidth: 1,
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
                ticks: {
                  beginAtZero: true,
                  padding: 10,
                  fontSize: 13,
                  fontColor: "#8898aa",
                  fontFamily: "Open Sans",
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                gridLines: {
                  drawBorder: false,
                  color: "transparent",
                  zeroLineColor: "transparent",
                },
                ticks: {
                  fontSize: 13,
                  fontColor: "#8898aa",
                  fontFamily: "Open Sans",
                },
              },
            ],
          },
          layout: {
            padding: 0,
          },
        },
      });
    },

    obterValores(mes, tipo) {
      var todasDatas = Object.values(this.feedbacks);
      var mesFeedback = [];
      for (let i = 0; i < todasDatas.length; i++) {
        mesFeedback.push(todasDatas[i].data.slice(5, 7).replace(/-/g, "/"));
      }
      var numeroMes = [];
      for (let k = 0; k < mesFeedback.length; k++) {
        if (mesFeedback[k][0] == "0") {
          numeroMes.push(mesFeedback[k].replace("0", ""));
        } else {
          numeroMes.push(mesFeedback[k]);
        }
      }
      var indexData = [];
      for (let i = 0; i < numeroMes.length; i++) {
        indexData.push(numeroMes[i] - 1);
      }
      var json = {
        "01": [],
        "02": [],
        "03": [],
        "04": [],
        "05": [],
        "06": [],
        "07": [],
        "08": [],
        "09": [],
        10: [],
        11: [],
        12: [],
      };
      for (let k = 0; k < todasDatas.length; k++) {
        json[todasDatas[k].data.slice(5, 7).replace(/-/g, "/")].push(
          todasDatas[k].feedback
        );
      }
      var meses = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      var countNegativo = 0;
      var countNeutro = 0;
      var countFeliz = 0;
      for (let k = 0; k < meses.length; k++) {
        for (let y = 0; y < json[meses[k]].length; y++) {
          if (meses[k] == mes) {
            if (json[meses[k]][y] == "1") {
              countNegativo++;
            }
            if (json[meses[k]][y] == "2") {
              countNeutro++;
            }
            if (json[meses[k]][y] == "3") {
              countFeliz++;
            }
          }
        }
      }
      if (tipo == "Negativo") {
        var todosFeedbacksNeg =
          Number(countNegativo) + Number(countNeutro) + Number(countFeliz);
        var percentagemNeg = (Number(countNegativo) * 100) / todosFeedbacksNeg;
        return percentagemNeg.toFixed(2);
      }
      if (tipo == "Neutro") {
        var todosFeedbacksNeutro =
          Number(countNegativo) + Number(countNeutro) + Number(countFeliz);
        var percentagemNeutro =
          (Number(countNeutro) * 100) / todosFeedbacksNeutro;
        return percentagemNeutro.toFixed(2);
      }
      if (tipo == "Feliz") {
        var todosFeedbacksFeliz =
          Number(countNegativo) + Number(countNeutro) + Number(countFeliz);
        var percentagemFeliz = (Number(countFeliz) * 100) / todosFeedbacksFeliz;
        return percentagemFeliz.toFixed(2);
      }
    },

    valores() {
      var meses = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      for (let i = 0; i <= 12; i++) {
        this.feedbackNegativo[i] = this.obterValores(meses[i], "Negativo");
        this.feedbackNeutro[i] = this.obterValores(meses[i], "Neutro");
        this.feedbackFeliz[i] = this.obterValores(meses[i], "Feliz");
      }
      this.criarGrafico();
    },

    async getUser() {
      var entryUUID = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", entryUUID);

      this.user = Object.values(user1)[0];
    },
  },
  mounted() {
    this.getUser();
    this.listarFeedbacks();
  },
};
</script>

<style>
.mauto {
  margin: auto !important;
}
</style>
