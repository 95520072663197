<template>
  <!-- Page content -->
  <div class="container pt-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <small>Registo</small>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <input
                alternative
                class="mb-3 form-control"
                addon-left-icon="ni ni-hat-3"
                placeholder="Nome"
                name="fullName"
                v-model="nome"
              />

              <input
                alternative
                class="mb-3 form-control"
                addon-left-icon="ni ni-hat-3"
                placeholder="Apelido"
                name="fullName"
                v-model="apelido"
              />
              <input
                alternative
                class="mb-3 form-control"
                placeholder="Email"
                name="fullName"
                v-model="email"
              />

              <input
                alternative
                class="mb-3 form-control"
                addon-left-icon="ni ni-hat-3"
                placeholder="Username"
                name="fullName"
                v-model="username"
              />

              <input
                alternative
                class="mb-3 form-control"
                addon-left-icon="ni ni-lock-circle-open"
                placeholder="Palavra Passe"
                type="password"
                name="password"
                v-model="password"
              />
              <input
                alternative
                class="mb-3 form-control"
                addon-left-icon="ni ni-lock-circle-open"
                placeholder="confirmar Palavra Passe"
                type="password"
                name="confirmar"
                v-model="confirmar"
              />

              <div class="text-center">
                <button
                  type="primary"
                  class="my-4 btn base-button bg-orange text-white"
                  @click="registar()"
                >
                  Criar Conta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  data() {
    return {
      username: "",
      nome: "",
      email: "",
      apelido: "",
      password: "",
      confirmar: "",
      status: "0",
      id_plataforma: "",
    };
  },
  // watch: {
  //   password() {
  //     if (this.password.length > 6 && this.password == this.confirmar) {
  //       this.mensagem = '<span style="color:green">Palavras Passe OK</span>';
  //     } else {
  //       this.mensagem = '<span style="color:red">Palavras Passe NOK</span>';
  //     }
  //   },
  //   confirmar() {
  //     if (this.confirmar == this.password) {
  //       this.mensagemConfirmar =
  //         '<span style="color:green">Palavras Passe Coincidem</span>';
  //     } else {
  //       this.mensagemConfirmar =
  //         '<span style="color:red">Palavras Passe não Coincidem</span>';
  //     }
  //   },
  // },
  methods: {
    async registar() {
      this.id_plataforma =
        this.gerarIdPlataforma(5) +
        "-" +
        this.gerarIdPlataforma(5) +
        "-" +
        this.gerarIdPlataforma(5) +
        "-" +
        this.gerarIdPlataforma(5);
      if (
        this.username != "" ||
        this.nome != "" ||
        this.email != "" ||
        this.apelido != ""
      ) {
        if (this.email.includes("@")) {
          if (this.password != "" && this.confirmar != "") {
            if (this.password == this.confirmar) {
              if (this.password.length > 6) {
                let registo = {
                  nome: this.nome,
                  apelido: this.apelido,
                  email: this.email,
                  username: this.username,
                  password: this.password,
                  status: this.status,
                  id_plataforma: this.id_plataforma,
                };
                let res = await this.$store.dispatch("registar", registo);
                console.log(res);
                if (res.resultado == "OK") {
                  this.successNotification(
                    "top-right",
                    "success",
                    "O seu registo foi concluido com sucesso! Aguarde que um Admin aprove!"
                  );
                  this.username = "";
                  this.nome = "";
                  this.email = "";
                  this.apelido = "";
                  this.password = "";
                  this.confirmar = "";
                } else {
                  console.log(res);
                  if (res.details.includes("Username Unico")) {
                    this.errorNotification(
                      "top-right",
                      "danger",
                      "O Username introduzido já se encontra em uso!"
                    );
                  } else if (res.details.includes("Email Unico")) {
                    this.errorNotification(
                      "top-right",
                      "danger",
                      "O Email introduzido já se encontra em uso!"
                    );
                  } else {
                    this.errorNotification(
                      "top-right",
                      "danger",
                      "Ocorreu um erro no Registo, por favor tente mais tarde!"
                    );
                  }
                }
              } else {
                this.errorNotification(
                  "top-right",
                  "danger",
                  "A sua password tem de conter mais de 6 caracteres!"
                );
              }
            } else {
              this.errorNotification(
                "top-right",
                "danger",
                "Passwords não coincidem"
              );
            }
          } else {
            this.errorNotification(
              "top-right",
              "danger",
              "Passwords não coincidem1"
            );
          }
        } else {
          this.errorNotification(
            "top-right",
            "danger",
            "O Email introduzido não é válido!"
          );
        }
      } else {
        this.errorNotification(
          "top-right",
          "danger",
          "Todos os campos são obrigatorios"
        );
      }
    },
    gerarIdPlataforma(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  mounted() {},
};
</script>
