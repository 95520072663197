<template>
  <div>
    <base-header class="pb-6 mb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-capitalize">
            {{ $route.name }} - {{ user.nome + " " + user.apelido }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="mb-4">
        <edit-profile-form></edit-profile-form>
      </div>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import Ajuda from "../Components/Ajuda";

export default {
  data() {
    return {
      user: [],
    };
  },
  methods: {
    async getuser() {
      var id = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", id);

      this.user = Object.values(user1)[0];
    },
  },
  mounted() {
    this.getuser();
  },
  components: {
    EditProfileForm,
    Ajuda,
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
