import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/views/Layout/DashboardLayout";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Alternative from "../views/Dashboard/AlternativeDashboard.vue";
import Widgets from "../views/Widgets.vue";
import Charts from "../views/Charts.vue";
import Calendar from "../views/Calendar/Calendar.vue";
import News from "../views/News/News.vue";
import EditarNoticia from "../views/News/EditarNoticia.vue";
import CreateNews from "../views/News/CreateNews.vue";
import NoticiaIndividual from "../views/News/NoticiaIndividual.vue";
import listNews from "../views/News/ListNews.vue";
import GerirSugestao from "../views/News/gerirSugestoes.vue";
import GerirEtiquetas from "../views/News/gerirEtiquetas.vue";
import SugerirNoticia from "../views/News/Sugerir-Noticia.vue";
import EditarPid from "../views/Pages/PID/EditarPid.vue";
import CriarPid from "../views/Pages/PID/CriarPid.vue";
import GerirPid from "../views/Pages/PID/GerirPid.vue";
import pid from "../views/Pages/PID/pid.vue";
import Coaching from "../views/Pages/PID/coaching.vue";
import VerPid from "../views/Pages/PID/VerPid.vue";

// Pages
import Inicio from "../views/Pages/Inicio.vue";
import Pricing from "../views/Pages/Pricing.vue";
import Login from "../views/Pages/Login.vue";
import Registar from "../views/Pages/Registar.vue";
import GerirUtilizadores from "../views/Pages/GerirUtilizadores.vue";
import Lock from "../views/Pages/Lock.vue";
import Profile from "../views/Pages/UserProfile.vue";
import Decathlonianos from "../views/Pages/Decathlonianos.vue";
import Missao from "../views/Pages/Missao.vue";
import Feliz from "../views/Pages/Feliz.vue";
import Timeline from "../views/Pages/TimeLinePage.vue";

// Feedback Referente
import ListarLigacoes from "../views/Pages/Feedbacks/Referente/ListarLigacoes.vue";
import RecebidosReferentes from "../views/Pages/Feedbacks/Referente/Recebidos.vue";
import EnviadosReferentes from "../views/Pages/Feedbacks/Referente/Enviados.vue";

// 360
import ListarDecathlonianos from "../views/Pages/Feedbacks/360/ListarDecathlonianos.vue";
import Recebidos360 from "../views/Pages/Feedbacks/360/Recebidos.vue";
import Enviados360 from "../views/Pages/Feedbacks/360/Enviados.vue";

// Components pages
import Buttons from "../views/Components/Buttons.vue";
import Cards from "../views/Components/Cards.vue";
import Grid from "../views/Components/GridSystem.vue";
import Notifications from "../views/Components/Notifications.vue";
import Icons from "../views/Components/Icons.vue";
import Typography from "../views/Components/Typography.vue";

// Tables pages
import RegularTables from "../views/Tables/RegularTables.vue";
import SortableTables from "../views/Tables/SortableTables.vue";
import PaginatedTables from "../views/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "../views/Maps/GoogleMaps.vue";
import VectorMaps from "../views/Maps/VectorMaps.vue";

// Forms pages
import FormElements from "../views/Forms/FormElements.vue";
import FormComponents from "../views/Forms/FormComponents.vue";
import FormValidation from "../views/Forms/FormValidation.vue";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
    },
    {
      path: "grid-system",
      name: "Grid",
      components: { default: Grid },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      components: { default: RegularTables },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "vector",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Forms elements",
      components: { default: FormElements },
    },
    {
      path: "components",
      name: "Forms Components",
      components: { default: FormComponents },
    },
    {
      path: "validation",
      name: "Forms Validation",
      components: { default: FormValidation },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/calendario",
    component: DashboardLayout,
    name: "Dashboards",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/inicio",
        name: "Página Inicial",
        components: { default: Inicio },
      },
      {
        path: "/alternative",
        name: "Alternative",
        components: { default: Alternative },
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "/widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
      {
        path: "/charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "/calendario",
        name: "Calendário",
        components: { default: Calendar },
      },
      {
        path: "/gerir-utilizadores",
        name: "Gerir-Utilizadores",
        components: { default: GerirUtilizadores },
      },
      {
        path: "/perfil",
        name: "Perfil de Utilizador",
        components: { default: Profile },
      },
      {
        path: "/decathlonianos",
        name: "Decathlonianos",
        components: { default: Decathlonianos },
      },
      {
        path: "/missao",
        name: "Missão",
        components: { default: Missao },
      },
      {
        path: "/noticias",
        name: "Notícias",
        components: { default: News },
      },
      {
        path: "/noticia",
        name: "Notícia",
        components: { default: NoticiaIndividual },
      },
      {
        path: "/noticias/criar",
        name: "Criar Notícias",
        components: { default: CreateNews },
      },
      {
        path: "/noticias/editar",
        name: "Editar Notícias",
        components: { default: EditarNoticia },
      },
      {
        path: "/noticias/gerir",
        name: "Gerir Notícias",
        components: { default: listNews },
      },
      {
        path: "/noticias/sugestoes",
        name: "Gerir Sugestões de Notícia",
        components: { default: GerirSugestao },
      },
      {
        path: "/noticias/etiquetas",
        name: "Gerir Etiquetas",
        components: { default: GerirEtiquetas },
      },
      {
        path: "/noticias/sugerir-noticia",
        name: "Sugerir Notícia",
        components: { default: SugerirNoticia },
      },
      {
        path: "/pages/timeline",
        name: "Timeline",
        components: { default: Timeline },
      },
      {
        path: "/feliz",
        name: "Sou Feliz",
        components: { default: Feliz },
      },
      {
        path: "/pid/o-meu-pid",
        name: "O meu PID",
        components: { default: pid },
      },
      {
        path: "/pid/gerir",
        name: "Gerir PID",
        components: { default: GerirPid },
      },
      {
        path: "/pid/coaching",
        name: "Coaching",
        components: { default: Coaching },
      },
      {
        path: "/pid/verPid",
        name: "Ver Pid",
        components: { default: VerPid },
      },
      {
        path: "/pid/criar",
        name: "Criar PID",
        components: { default: CriarPid },
      },
      {
        path: "/pid/editar",
        name: "Editar PID",
        components: { default: EditarPid },
      },
      {
        path: "/feedback/referente/ligacoes",
        name: "Referente",
        components: { default: ListarLigacoes },
      },
      {
        path: "/feedback/referente/recebidos",
        name: "Recebidos",
        components: { default: RecebidosReferentes },
      },
      {
        path: "/feedback/referente/enviados",
        name: "Enviados",
        components: { default: EnviadosReferentes },
      },
      {
        path: "/feedback/360/listardecathlonianos",
        name: "ListarDecathlonianos",
        components: { default: ListarDecathlonianos },
      },
      {
        path: "/feedback/360/recebidos",
        name: "Recebidos ",
        components: { default: Recebidos360 },
      },
      {
        path: "/feedback/360/enviados",
        name: "Enviados ",
        components: { default: Enviados360 },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/pricing",
        name: "Pricing",
        components: { default: Pricing },
      },
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
      {
        path: "/registar",
        name: "Registar",
        components: { default: Registar },
      },
      
      {
        path: "/lock",
        name: "Lock",
        components: { default: Lock },
      },
    ],
  },
  componentsMenu,
  tablesMenu,
  mapsMenu,
  formsMenu,
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  // let name = store.state.userLogin.name;
  let id = localStorage.getItem("idYourCodeCalendar");
  if (to.name != "Login" && to.name != "Registar" && id == undefined)
    next({ name: "Login" });
  // // COMENTADO else if((to.name === 'Login' || to.name === 'ChangePassword') && (name != null)) next({ name: "PerformanceStore" });
  else next();
});

export default router;
