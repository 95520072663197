<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">Feedbacks</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">Feedbacks por responder</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(perguntasNaoRespondidas)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm"
                    >{{ row.comentario }} Sem comentário</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <v-button
              class="btn base-button btn-primary btn-sm"
              @click="mostarComentario(row.id, row.nome, row.apelido)"
              >Preencher
            </v-button>
          </el-table-column>
        </el-table>
        <modal class="modalPerfil" v-model:show="modals.preencher">
          <div class="card card-profile">
            <div class="card-header">
              <h3 class="mb-0">
                360 para
                {{ nome360 + " " + apelido360 }}
              </h3>
            </div>
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="card"
                      v-for="(item, i) in perguntaFiltradas"
                      :key="i"
                    >
                      <div class="card-body">
                        <h3>{{ item.pergunta }}</h3>

                        <div>
                          <label class="pl-3">1</label>
                          <input
                            type="radio"
                            value="1"
                            v-model="item.resposta"
                            @change="guardarRespostas(item)"
                          />
                          <label class="pl-3">2</label>
                          <input
                            type="radio"
                            value="2"
                            v-model="item.resposta"
                            @change="guardarRespostas(item)"
                          />
                          <label class="pl-3">3</label>
                          <input
                            type="radio"
                            value="3"
                            v-model="item.resposta"
                            @change="guardarRespostas(item)"
                          />
                          <label class="pl-3">4</label>
                          <input
                            type="radio"
                            value="4"
                            v-model="item.resposta"
                            @change="guardarRespostas(item)"
                          />
                          <label class="pl-3">5</label>
                          <input
                            type="radio"
                            value="5"
                            v-model="item.resposta"
                            @change="guardarRespostas(item)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <v-button
                      class="btn base-button btn-success"
                      type="success"
                      @click="guardar360()"
                      >Submeter 360
                    </v-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div
              type="link"
              class="ml-auto text-right"
              @click="modals.preencher = false"
            >
              Fechar
            </div>
          </template>
        </modal>
      </div>
    </div>
    <div class="container-fluid">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">{{ $route.name }}</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(perguntasRespondidas)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.comentario
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <v-button
              class="btn base-button btn-primary btn-sm"
              @click="mostarFeedback(row.id, row.nome, row.apelido)"
              >Ver Feedback
            </v-button>
          </el-table-column>
        </el-table>
        <modal class="modalPerfil" v-model:show="modals.enviado">
          <div class="card card-profile">
            <div class="card-header">
              <h3 class="mb-0">
                360 Enviado por si para
                {{ nome360 + " " + apelido360 }}
              </h3>
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      <div
                        class="card"
                        v-for="(item, i) in perguntaFiltradas"
                        :key="i"
                      >
                        <div class="card-body">
                          <h3>{{ item.pergunta }}</h3>
                          <div>
                            <label class="pl-3">1</label>
                            <input
                              type="radio"
                              value="1"
                              disabled
                              v-model="item.resposta"
                              @change="guardarRespostas(item)"
                            />
                            <label class="pl-3">2</label>
                            <input
                              type="radio"
                              value="2"
                              disabled
                              v-model="item.resposta"
                              @change="guardarRespostas(item)"
                            />
                            <label class="pl-3">3</label>
                            <input
                              type="radio"
                              value="3"
                              disabled
                              v-model="item.resposta"
                              @change="guardarRespostas(item)"
                            />
                            <label class="pl-3">4</label>
                            <input
                              type="radio"
                              value="4"
                              disabled
                              v-model="item.resposta"
                              @change="guardarRespostas(item)"
                            />
                            <label class="pl-3">5</label>
                            <input
                              type="radio"
                              value="5"
                              disabled
                              v-model="item.resposta"
                              @change="guardarRespostas(item)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-slot:footer>
            <div
              type="link"
              class="ml-auto text-right"
              @click="modals.enviado = false"
            >
              Fechar
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Ajuda from "../../../Components/Ajuda";
import Modal from "../../../../components/Modal";
export default {
  name: "Enviados",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      currentPage: 1,
      porResponder360: [],
      respondido360: [],
      referentesEnviadosPreencher: [],
      comentarioIndividual: "",
      comentarioPreencher: "",
      nome360: "",
      apelido360: "",
      perguntas: [],
      perguntasNaoRespondidas: [],
      perguntasRespondidas: [],
      perguntaFiltradas: [],
      modals: {
        enviado: false,
        preencher: false,
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async mostarComentario(id, nome, apelido) {
      this.nome360 = nome;
      this.apelido360 = apelido;

      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listar360ComentarioIndividual",
        jsonComentario
      );
      this.perguntaFiltradas = this.perguntas.filter((x) => x.id == id);
      this.modals.preencher = true;
    },
    async mostarFeedback(id, nome, apelido) {
      this.nome360 = nome;
      this.apelido360 = apelido;

      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listar360ComentarioIndividual",
        jsonComentario
      );
      this.perguntaFiltradas = this.perguntas.filter((x) => x.id == id);
      this.modals.enviado = true;
    },

    async mostarComentarioPreencher(id) {
      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listarReferentesComentarioIndividual",
        jsonComentario
      );

      this.modals.preencher = true;
    },
    async guardarRespostas(id) {
      var temp = [];
      for (let i = 0; i < this.perguntaFiltradas.length; i++) {
        temp.push({
          pergunta: this.perguntaFiltradas[i].pergunta,
          resposta: this.perguntaFiltradas[i].resposta,
        });
      }
      console.log(JSON.stringify(temp));
      var data = {
        id: id.id,
        perguntas: JSON.stringify(temp),
      };
      await this.$store.dispatch("actualizarRespostas360", data);
    },
    guardar360() {
      this.modals.preencher = false;
      this.successNotification(
        "top-right",
        "success",
        "As tuas respostas foram guardadas com sucesso"
      );
      this.listarEnviados();
    },
    fecharModal() {
      this.modals.enviado = false;
    },
    async listarEnviados() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonMissao = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listar360EnviadosPreencher",
        jsonMissao
      );
      this.porResponder360 = todas;

      var listagem = Object.values(this.porResponder360);
      var parse = new Array();
      this.perguntasNaoRespondidas = [];
      this.perguntasRespondidas = [];
      for (let i = 0; i < listagem.length; i++) {
        parse.push({
          perguntas: JSON.parse(listagem[i].perguntas),
          id: listagem[i].id,
        });
        if (
          JSON.parse(listagem[i].perguntas).some(
            (x) => x.resposta == "" || !x.resposta
          )
        ) {
          this.perguntasNaoRespondidas.push(listagem[i]);
        } else {
          this.perguntasRespondidas.push(listagem[i]);
        }
      }
      for (let i = 0; i < parse.length; i++) {
        for (let k = 0; k < parse[i].perguntas.length; k++) {
          if (parse[i].perguntas[k].resposta) {
            this.perguntas.push({
              id: parse[i].id,
              pergunta: parse[i].perguntas[k].pergunta,
              resposta: parse[i].perguntas[k].resposta,
            });
          } else {
            this.perguntas.push({
              id: parse[i].id,
              pergunta: parse[i].perguntas[k].pergunta,
              resposta: "",
            });
          }
        }
      }
    },
  },
  async mounted() {
    this.listarEnviados();
  },
};
</script>
<style scoped>
.modal-content {
  width: 65vw !important;
}

.modal-dialog {
  max-width: 65vw !important;
}

.modal-body {
  padding: 0 !important;
  max-height: 90vh;
  overflow: auto;
  border-radius: 10px;
}

.modal-body .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px 1.5rem;
}

.card-body .card {
  margin-bottom: 20px;
}
</style>
