<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">Feedbacks</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">Feedbacks por responder</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(referentesEnviadosPreencher)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
              <modal class="modalPerfil" v-model:show="modals.preencher">
                <div class="card card-profile">
                  <div class="border-0 card-header">
                    <h3 class="mb-0">
                      Feedback para
                      {{ row.nome + " " + row.apelido }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <textarea
                              rows="8"
                              class="form-control"
                              placeholder="O meu feedback sobre a tua prestação é ..."
                              v-model="comentarioPreencher"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <v-button
                            class="btn base-button btn-success"
                            type="success"
                            @click="showSwal('submeter', row.id)"
                            >Submeter Feedback
                          </v-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-slot:footer>
                  <div
                    type="link"
                    class="ml-auto text-right"
                    @click="fecharModal()"
                  >
                    Fechar
                  </div>
                </template>
              </modal>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm"
                    >{{ row.comentario }} Sem comentário</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <v-button
              class="btn base-button btn-primary btn-sm"
              @click="mostarComentarioPreencher(row.id)"
              >Preencher
            </v-button>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="container-fluid">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">{{ $route.name }}</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(referentesEnviados)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
              <modal class="modalPerfil" v-model:show="modals.enviado">
                <div class="card card-profile">
                  <div class="border-0 card-header">
                    <h3 class="mb-0">
                      Feedback Enviado por si - Comentário para
                      {{ row.nome + " " + row.apelido }}
                    </h3>
                    <p class="mt-5">
                      {{ comentarioIndividual.comentario }}
                    </p>
                  </div>
                </div>

                <template v-slot:footer>
                  <div
                    type="link"
                    class="ml-auto text-right"
                    @click="fecharModal()"
                  >
                    Fechar
                  </div>
                </template>
              </modal>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.comentario
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <v-button
              class="btn base-button btn-primary btn-sm"
              @click="mostarComentario(row.id)"
              >Ver Feedback
            </v-button>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Ajuda from "../../../Components/Ajuda";
import Modal from "../../../../components/Modal";
export default {
  name: "Enviados",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      currentPage: 1,
      referentesEnviados: [],
      referentesEnviadosPreencher: [],
      comentarioIndividual: "",
      comentarioPreencher: "",
      modals: {
        enviado: false,
        preencher: false,
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async mostarComentario(id) {
      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listarReferentesComentarioIndividual",
        jsonComentario
      );

      this.modals.enviado = true;
    },
    async mostarComentarioPreencher(id) {
      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listarReferentesComentarioIndividual",
        jsonComentario
      );

      this.modals.preencher = true;
    },
    async showSwal(type, id) {
      var that = this;
      if (type === "submeter") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja submeter o Feedback?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              var jsonSubmeter = {
                id: id,
                comentario: that.comentarioPreencher,
              };
              let res = await that.$store.dispatch(
                "enviarPreenchimentoFeedback",
                jsonSubmeter
              );

              if (res) {
                if (res.data.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Feedback submetido com sucesso!"
                  );

                  that.listarEnviados();
                  that.listarEnviadosPreencher();
                  that.fecharModal();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      }
    },
    fecharModal() {
      this.modals.enviado = false;
    },
    async listarEnviados() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonMissao = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listarReferentesEnviados",
        jsonMissao
      );
      this.referentesEnviados = todas;
    },
    async listarEnviadosPreencher() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonMissao = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listarReferentesEnviadosPreencher",
        jsonMissao
      );
      this.referentesEnviadosPreencher = todas;
    },
  },
  async mounted() {
    this.listarEnviados();
    this.listarEnviadosPreencher();
  },
};
</script>
<style scoped>
.modal-content {
  width: 65vw !important;
}

.modal-dialog {
  max-width: 65vw !important;
}
</style>
