<template>
  <div>
    <loader
      v-show="criarpid"
      object="rgb(20, 130, 194)"
      color1="#ffffff"
      color2="rgb(20, 130, 194)"
      size="5"
      speed="10"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Detalhes</h3>
        </div>
        <div class="card-body">
          <form>
            <div class="pl-lg-0">
              <div class="row">
                <div class="col-lg-4">
                  <label class="form-control-label"> Título: </label>
                  <input
                    v-model="titulo"
                    class="form-control"
                    name="grid1"
                    id="grid1"
                    type="text"
                    placeholder="Título"
                  />
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-4">
                  <base-input name="selectmultiple" label="Funções:">
                    <el-select
                      v-model="funcoesSelecionadas"
                      multiple
                      filterable
                      placeholder="Etiquetas"
                      @change="chamadaPerguntas"
                    >
                      <el-option
                        v-for="option in selectOptions"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-lg-12 mt-5">
              <ul class="nav nav-pills nav-pills-primary" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#Etapa0"
                    role="tablist"
                    aria-expanded="true"
                    @click="etapa = 0"
                  >
                    Etapa 0
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa1"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 1"
                  >
                    Etapa 1
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa2"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 2"
                  >
                    Etapa 2
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa3"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 3"
                  >
                    Etapa 3
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa4"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 4"
                  >
                    Etapa 4
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa5"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 5"
                  >
                    Etapa 5
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa6"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 6"
                  >
                    Etapa 6
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa7"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 7"
                  >
                    Etapa 7
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa8"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 8"
                  >
                    Etapa 8
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa9"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 9"
                  >
                    Etapa 9
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa10"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 10"
                  >
                    Etapa 10
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa11"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 11"
                  >
                    Etapa 11
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-toggle="tab"
                    href="#Etapa12"
                    role="tablist"
                    aria-expanded="false"
                    @click="etapa = 12"
                  >
                    Etapa 12
                  </a>
                </li>
              </ul>
              <div class="tab-content tab-space">
                <div class="tab-pane active" id="Etapa0" aria-expanded="true">
                  <div class="row">
                    <div class="col-md-4">
                      <ul class="nav nav-pills nav-pills-primary flex-column">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            href="#saberEstar"
                            data-toggle="tab"
                            @click="bloco = 'Saber Estar'"
                            >Saber Estar</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            href="#saberFazer"
                            data-toggle="tab"
                            @click="bloco = 'Saber Fazer'"
                            >Saber Fazer</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            href="#academy"
                            data-toggle="tab"
                            @click="bloco = 'Academy'"
                            >Academy</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            href="#planoAccao"
                            data-toggle="tab"
                            @click="bloco = 'Plano de Acção'"
                            >Plano de Acção</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-8">
                      <div class="tab-content">
                        <div class="tab-pane" id="saberEstar">
                          <button
                            @click="add('novo', 'SaberEstar')"
                            icon
                            type="primary"
                            class="btn base-button btn-success mt-5 text-right"
                          >
                            <span class="btn-inner--icon"
                              ><i
                                class="ni ni-fat-add"
                                style="font-size: 18px !important"
                              ></i>
                              Adicionar Pergunta</span
                            >
                          </button>
                          <div
                            class="form-group"
                            v-for="(input, k) in perguntasSaberEstar"
                            :key="k"
                          >
                            <!-- <div v-if="input.pergunta == ''"> -->

                            <div v-if="input.etapa == etapa">
                              <label class="form-control-label"
                                >Pergunta
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.pergunta"
                              />
                              <label class="form-control-label"
                                >Percentagem
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.percentagem"
                              />
                              <button
                                @click="remove(k, 'SaberEstar')"
                                icon
                                type="primary"
                                class="btn base-button btn-danger"
                                v-show="k || (!k && input.etapa == etapa)"
                              >
                                <span
                                  @click="remove(k, 'SaberEstar')"
                                  class="btn-inner--icon"
                                  ><i class="ni ni-fat-delete"></i
                                ></span>
                              </button>
                            </div>
                          </div>
                          <!-- </div> -->
                        </div>

                        <div class="tab-pane" id="saberFazer">
                          <button
                            @click="add('novo', 'SaberFazer')"
                            icon
                            type="primary"
                            class="btn base-button btn-success mt-5"
                          >
                            <span class="btn-inner--icon"
                              ><i
                                class="ni ni-fat-add"
                                style="font-size: 18px !important"
                              ></i>
                              Adicionar Pergunta</span
                            >
                          </button>
                          <div
                            class="form-group"
                            v-for="(input, k) in perguntasSaberFazer"
                            :key="k"
                          >
                            <!-- <div v-if="input.pergunta == ''"> -->
                            <div v-if="input.etapa == etapa">
                              <label class="form-control-label"
                                >Pergunta
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.pergunta"
                              />
                              <label class="form-control-label"
                                >Percentagem
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.percentagem"
                              />
                              <button
                                @click="remove(k, 'SaberFazer')"
                                icon
                                type="primary"
                                class="btn base-button btn-danger"
                                v-show="k || (!k && input.etapa == etapa)"
                              >
                                <span class="btn-inner--icon"
                                  ><i class="ni ni-fat-delete"></i
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane" id="academy">
                          <button
                            @click="add('novo', 'Academy')"
                            icon
                            type="primary"
                            class="btn base-button btn-success mt-5 text-right"
                          >
                            <span class="btn-inner--icon"
                              ><i
                                class="ni ni-fat-add"
                                style="font-size: 18px !important"
                              ></i>
                              Adicionar Pergunta</span
                            >
                          </button>
                          <div
                            class="form-group"
                            v-for="(input, k) in perguntasAcademy"
                            :key="k"
                          >
                            <!-- <div v-if="input.pergunta == ''"> -->
                            <div v-if="input.etapa == etapa">
                              <label class="form-control-label"
                                >Pergunta
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.pergunta"
                              />
                              <label class="form-control-label"
                                >Percentagem
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.percentagem"
                              />
                              <button
                                @click="remove(k, 'Academy')"
                                icon
                                type="primary"
                                class="btn base-button btn-danger"
                                v-show="k || (!k && input.etapa == etapa)"
                              >
                                <span class="btn-inner--icon"
                                  ><i class="ni ni-fat-delete"></i
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane" id="planoAccao">
                          <button
                            @click="add('novo', 'PlanoAccao')"
                            icon
                            type="primary"
                            class="btn base-button btn-success mt-5 text-right"
                          >
                            <span class="btn-inner--icon"
                              ><i
                                class="ni ni-fat-add"
                                style="font-size: 18px !important"
                              ></i>
                              Adicionar Pergunta</span
                            >
                          </button>
                          <div
                            class="form-group"
                            v-for="(input, k) in perguntasPlanoAccao"
                            :key="k"
                          >
                            <!-- <div v-if="input.pergunta == ''"> -->
                            <div v-if="input.etapa == etapa">
                              <label class="form-control-label"
                                >Pergunta
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.pergunta"
                              />
                              <label class="form-control-label"
                                >Percentagem
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="input.percentagem"
                              />
                              <button
                                @click="remove(k, 'PlanoAccao')"
                                icon
                                type="primary"
                                class="btn base-button btn-danger"
                                v-show="k || (!k && input.etapa == etapa)"
                              >
                                <span class="btn-inner--icon"
                                  ><i class="ni ni-fat-delete"></i
                                ></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-12 text-left">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="criarPID"
                >Guardar</v-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  data() {
    return {
      criarpid: true,
      etapa: 0,
      titulo: "",
      bloco: "",
      pid: [],
      funcoesSelecionadas: [],
      user: [],
      perguntasSaberEstar: [],
      perguntasSaberFazer: [],
      perguntasAcademy: [],
      perguntasSugestoesCoach: [],
      perguntasPlanoAccao: [],
      selectOptions: [],
      selects: {
        simple: "Badges",
        multiple: [],
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    add(k, bloco) {
      if (bloco == "SaberEstar") {
        this.perguntasSaberEstar.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
      } else if (bloco == "SaberFazer") {
        this.perguntasSaberFazer.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
      } else if (bloco == "Academy") {
        this.perguntasAcademy.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
        console.log(this.perguntasAcademy);
      } else if (bloco == "SugestoesCoach") {
        this.perguntasSugestoesCoach.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
      } else if (bloco == "PlanoAccao") {
        this.perguntasPlanoAccao.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
      }
    },

    remove(k, onde) {
      if (onde == "SaberEstar") {
        this.perguntasSaberEstar.splice(k, 1);
      } else if (onde == "SaberFazer") {
        this.perguntasSaberFazer.splice(k, 1);
      } else if (onde == "Academy") {
        this.perguntasAcademy.splice(k, 1);
      } else if (onde == "SugestoesCoach") {
        this.perguntasSugestoesCoach.splice(k, 1);
      } else if (onde == "PlanoAccao") {
        this.perguntasPlanoAccao.splice(k, 1);
      }
    },

    async criarPID() {
      var $ = this.$store;
      // VERIFICAR SE TEM TITULO E CARGOS
      var titulo = this.titulo;
      var funcoes = this.funcoesSelecionadas.join();
      let resultadoPIDConsulta = [];
      if (titulo == "" || this.funcoesSelecionadas.length <= 0) {
        this.criarpid = false;
        this.errorNotification(
          "top-right",
          "danger",
          "Ocorreu um erro, verifique se os campos estão devidamente preenchidos!"
        );
      } else {
        // VERIFICAR SE AS FUNÇÕES/CARGOS JÁ TEM PID

        for (let i = 0; i < this.funcoesSelecionadas.length; i++) {
          var pid = {
            nome: this.funcoesSelecionadas[i],
          };
          var resultado = await $.dispatch("PIDConsultar", pid);
          resultadoPIDConsulta.push(resultado);
        }
        if (
          resultadoPIDConsulta.filter(
            (x) => x.resultado == 1,
            "resultado consulta"
          ).length > 0
        ) {
          // UMA DAS FUNCOES QUE SELECIONOU JÁ TEM UM PID ASSOCIADO
          this.criarpid = false;
          this.errorNotification(
            "top-right",
            "danger",
            resultadoPIDConsulta.filter(
              (x) => x.resultado == 1,
              "resultado consulta"
            ).length + " função(ões) que seleccionou, já tem um PID associado!"
          );
        } else {
          // VERIFICAR SE TODAS AS ETAPAS TEM PERGUNTAS
          var etapasSaberEstar = [];
          var etapasSaberFazer = [];
          var etapasAcademy = [];
          var etapasPlanoAccao = [];
          for (let i = 0; i < this.perguntasSaberEstar.length; i++) {
            etapasSaberEstar.push(this.perguntasSaberEstar[i].etapa);
          }
          for (let i = 0; i < this.perguntasSaberFazer.length; i++) {
            etapasSaberFazer.push(this.perguntasSaberFazer[i].etapa);
          }
          for (let i = 0; i < this.perguntasAcademy.length; i++) {
            etapasAcademy.push(this.perguntasAcademy[i].etapa);
          }
          for (let i = 0; i < this.perguntasPlanoAccao.length; i++) {
            etapasPlanoAccao.push(this.perguntasPlanoAccao[i].etapa);
          }
          var lengthetapasSaberEstar = [...new Set(etapasSaberEstar)];
          var lengthetapasSaberFazer = [...new Set(etapasSaberFazer)];
          var lengthetapasAcademy = [...new Set(etapasAcademy)];
          var lengthetapasPlanoAccao = [...new Set(etapasPlanoAccao)];
          if (
            lengthetapasSaberEstar.length !== 143 &&
            lengthetapasSaberFazer.length == 13 &&
            lengthetapasAcademy.length == 13 &&
            lengthetapasPlanoAccao.length == 13
          ) {
            // 1 - INSERT NA TABELA PIDS TITULO + FUNCOES -> OK
            // 1.1 - IR BUSCAR O ID DO NOVO PID -> OK

            let jsonCriarPid = {
              titulo: titulo,
              funcoes: funcoes,
            };
            let res = await $.dispatch("criarPid", jsonCriarPid);
            if (res) {
              if (res.resultado == "OK") {
                let PIDID = res.ID[0].id;

                // 2 - INSERT NA TABELA PIDS_PERGUNTAS TODAS AS PERGUNTAS -> OK
                // 2.1 - A CADA INSERT FAZER PUSH DO ID DA PERGUNTA ( RETURN DA BD ) -> OK
                this.criarpid = true;
                // CRIAR PERGUNTAS SABER ESTAR E RETORNAR ID

                for (let k = 0; k < this.perguntasSaberEstar.length; k++) {
                  let jsonCriarPerguntasSaberEstar = {
                    pergunta: this.perguntasSaberEstar[k].pergunta,
                  };
                  let resSaberEstar = await $.dispatch(
                    "criarPerguntaPid",
                    jsonCriarPerguntasSaberEstar
                  );
                  this.perguntasSaberEstar[k].id = resSaberEstar.ID[0].id;
                  this.perguntasSaberEstar[k].pid_id = PIDID;
                }

                // CRIAR PERGUNTAS FAZER FAZER E RETORNAR ID

                for (let k = 0; k < this.perguntasSaberFazer.length; k++) {
                  let jsonCriarPerguntasSaberFazer = {
                    pergunta: this.perguntasSaberFazer[k].pergunta,
                  };
                  let resSaberFazer = await $.dispatch(
                    "criarPerguntaPid",
                    jsonCriarPerguntasSaberFazer
                  );
                  this.perguntasSaberFazer[k].id = resSaberFazer.ID[0].id;
                  this.perguntasSaberFazer[k].pid_id = PIDID;
                }

                // CRIAR PERGUNTAS ACADEMY E RETORNAR ID

                for (let k = 0; k < this.perguntasAcademy.length; k++) {
                  let jsonCriarPerguntasAcademy = {
                    pergunta: this.perguntasAcademy[k].pergunta,
                  };
                  let resAcademy = await $.dispatch(
                    "criarPerguntaPid",
                    jsonCriarPerguntasAcademy
                  );
                  this.perguntasAcademy[k].id = resAcademy.ID[0].id;
                  this.perguntasAcademy[k].pid_id = PIDID;
                }

                // CRIAR PERGUNTAS PLANO DE ACÇÃO E RETORNAR ID

                for (let k = 0; k < this.perguntasPlanoAccao.length; k++) {
                  let jsonCriarPerguntasPlanoAccao = {
                    pergunta: this.perguntasPlanoAccao[k].pergunta,
                  };
                  let resPlanoAcao = await $.dispatch(
                    "criarPerguntaPid",
                    jsonCriarPerguntasPlanoAccao
                  );
                  this.perguntasPlanoAccao[k].id = resPlanoAcao.ID[0].id;
                  this.perguntasPlanoAccao[k].pid_id = PIDID;
                }

                // 3 - INSERT NA TABELA PIDS_LIGACOES -> OK
                // 3.1 - INSERT NA TABELA PIDS_LIGACOES  PID_ID + NOVA ARRAY COM PERGUNTA ID + ETAPA + BLOCO + PERCENTAGEM -> OK

                var resLigacoesSaberEstar = [];
                var resLigacoesSaberFazer = [];
                var resLigacoesAcademy = [];
                var resLigacoesPlanoAcao = [];

                // CRIAR LIGACOES SABER ESTAR

                for (let i = 0; i < this.perguntasSaberEstar.length; i++) {
                  let jsonLigacoesSaberEstar = {
                    pid_id: this.perguntasSaberEstar[i].pid_id,
                    pergunta_id: this.perguntasSaberEstar[i].id,
                    etapa: this.perguntasSaberEstar[i].etapa,
                    bloco: this.perguntasSaberEstar[i].bloco,
                    percentagem: this.perguntasSaberEstar[i].percentagem,
                  };
                  resLigacoesSaberEstar = await $.dispatch(
                    "criarLigacoesPid",
                    jsonLigacoesSaberEstar
                  );
                }

                // CRIAR LIGACOES SABER FAZER

                for (let i = 0; i < this.perguntasSaberFazer.length; i++) {
                  let jsonLigacoesSaberFazer = {
                    pid_id: this.perguntasSaberFazer[i].pid_id,
                    pergunta_id: this.perguntasSaberFazer[i].id,
                    etapa: this.perguntasSaberFazer[i].etapa,
                    bloco: this.perguntasSaberFazer[i].bloco,
                    percentagem: this.perguntasSaberFazer[i].percentagem,
                  };
                  resLigacoesSaberFazer = await $.dispatch(
                    "criarLigacoesPid",
                    jsonLigacoesSaberFazer
                  );
                }

                // CRIAR LIGACOES ACADEMY

                for (let i = 0; i < this.perguntasAcademy.length; i++) {
                  let jsonLigacoesAcademy = {
                    pid_id: this.perguntasAcademy[i].pid_id,
                    pergunta_id: this.perguntasAcademy[i].id,
                    etapa: this.perguntasAcademy[i].etapa,
                    bloco: this.perguntasAcademy[i].bloco,
                    percentagem: this.perguntasAcademy[i].percentagem,
                  };
                  resLigacoesAcademy = await $.dispatch(
                    "criarLigacoesPid",
                    jsonLigacoesAcademy
                  );
                }

                // CRIAR LIGACOES PLANO ACCAO

                for (let i = 0; i < this.perguntasPlanoAccao.length; i++) {
                  let jsonLigacoesPlanoAccao = {
                    pid_id: this.perguntasPlanoAccao[i].pid_id,
                    pergunta_id: this.perguntasPlanoAccao[i].id,
                    etapa: this.perguntasPlanoAccao[i].etapa,
                    bloco: this.perguntasPlanoAccao[i].bloco,
                    percentagem: this.perguntasPlanoAccao[i].percentagem,
                  };
                  resLigacoesPlanoAcao = await $.dispatch(
                    "criarLigacoesPid",
                    jsonLigacoesPlanoAccao
                  );
                }

                // RESULTADOS API
                // VERIFICAR SE FORAM TODOS OK

                if (
                  resLigacoesSaberEstar.resultado == "OK" &&
                  resLigacoesSaberFazer.resultado == "OK" &&
                  resLigacoesAcademy.resutlado == "OK" &&
                  resLigacoesPlanoAcao.resultado == "OK"
                ) {
                  //ALERTA PID CRIADO COM SUCESSO + LIMPAR PERGUNTAS, TITULO, FUNCOES
                  this.criarpid = false;
                  this.successNotification(
                    "top-right",
                    "success",
                    "PID criado com sucesso!"
                  );
                  this.perguntasSaberEstar = [];
                  this.perguntasSaberFazer = [];
                  this.perguntasAcademy = [];
                  this.perguntasPlanoAccao = [];
                  this.titulo = "";
                  this.funcoesSelecionadas = [];
                } else {
                  this.criarpid = false;
                  this.errorNotification(
                    "top-right",
                    "danger",
                    "Ocorreu um erro, tente novamente mais tarde!"
                  );
                }
              }
            }
          } else {
            console.log(resLigacoesSaberEstar);
            console.log(resLigacoesSaberFazer);
            console.log(resLigacoesAcademy);
            console.log(resLigacoesPlanoAcao);
            this.criarpid = false;
            this.errorNotification(
              "top-right",
              "danger",
              "Ocorreu um erro, existem blocos e/ou etapas vazios!"
            );
          }
        }
      }
    },

    async inicio() {
      var $ = this.$store;
      // var id = localStorage.getItem("idYourCodeCalendar");
      // let data = {
      //   entryUUID: id,
      //   funcao: this.user.funcao,
      // };
      // let res = await this.$store.dispatch("pid", data);
      // console.log(res);
      let res = await $.dispatch("funcoesExistentes");
      let temp = Object.values(res);
      var temporario = [];
      for (let i = 0; i < temp.length; i++) {
        temporario.push(temp[i].funcao);
      }
      let unico = [...new Set(temporario)];
      for (let k = 0; k < unico.length; k++) {
        this.selectOptions.push({
          label: unico[k],
          value: unico[k],
        });
      }

      this.criarpid = false;
    },
  },
  async mounted() {
    var id = localStorage.getItem("idYourCodeCalendar");
    let user = await this.$store.dispatch("profileDetails", id);
    this.user = Object.values(user)[0];
    this.inicio();
  },
  watch: {},
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
