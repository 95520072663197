<template>
  <base-header class="pb-6">
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>

        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          <route-bread-crumb> </route-bread-crumb>
        </nav>
      </div>
    </div>
  </base-header>
  <div v-if="rendered" class="container-fluid mt--6">
    <div class="card">
      <div class="border-0 card-header">
        <h3 class="mb-0">Notícias</h3>
      </div>

      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="noticias"
        :items-per-page="5"
      >
        <el-table-column label="Notícia" min-width="310px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.nome
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Autor" prop="autor" min-width="140px" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.email
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Estado"
          min-width="170px"
          prop="status"
          sortable
        >
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <span v-if="row.status == 1" class="status text-green"
                >Publicada</span
              >
              <span v-if="row.status == 0" class="status text-red"
                >Inactiva</span
              >
            </badge>
          </template>
        </el-table-column>

        <el-table-column v-slot="{ row }" min-width="180px">
          <el-dropdown trigger="click" class="dropdown">
            <span class="btn btn-sm btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                label="Estado"
              >
                <a class="dropdown-item" @click="showSwal('activar', row.id)"
                  >Activar</a
                >
                <a class="dropdown-item" @click="showSwal('editar', row.id)"
                  >Editar</a
                >
                <a class="dropdown-item" @click="showSwal('desactivar', row.id)"
                  >Desactivar</a
                >
                <a class="dropdown-item" @click="showSwal('apagar', row.id)"
                  >Apagar</a
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-table-column>
      </el-table>

      <div class="card-footer py-4 d-flex justify-content-end">
        <base-pagination v-model="currentPage" :total="50"></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      noticias: [],
      noticiaEditada: [],
      currentPage: 1,
      rendered: false,
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async showSwal(type, id) {
      var that = this;
      if (type === "apagar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja apagar esta notícia? Ela não poderá ser restaurada!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id: id,
              };
              let res = await that.$store.dispatch("deleteNews", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Notícia apagada com sucesso!"
                  );
                  await that.$store.dispatch("getAllNews");
                  await that.$store.dispatch("getNews");
                  that.noticias = Object.values(that.$store.state.newsAll);
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "desactivar") {
        swal
          .fire({
            title: "Atenção",
            text:
              "Deseja desactivar esta Notícia? Ela não aparecerá nas notícias mas pode vir a ser activada a qualquer momento!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id: id,
              };
              let res = await that.$store.dispatch("deactivateNews", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Notícia desactivada com sucesso!"
                  );
                  await that.$store.dispatch("getAllNews");
                  await that.$store.dispatch("getNews");
                  that.noticias = Object.values(that.$store.state.newsAll);
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "activar") {
        swal
          .fire({
            title: "Atenção",
            text:
              "Deseja activar esta Notícia? Ela aparecerá para todos os utilizadores da plataforma.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id: id,
              };
              let res = await that.$store.dispatch("activateNews", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Notícia activada com sucesso!"
                  );
                  await that.$store.dispatch("getAllNews");
                  await that.$store.dispatch("getNews");
                  that.noticias = Object.values(that.$store.state.newsAll);
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "editar") {
        let jsonNoticiaEditada = {
          id: id,
        };
        await that.$store.dispatch("carregarEditarNoticia", jsonNoticiaEditada);
        this.$router.push("/noticias/editar");
      }
    },
  },
  async mounted() {
    var noticias = await this.$store.dispatch("listarUtilizadores");
    this.noticias = Object.values(noticias);
    console.log(this.noticias);
    this.rendered = true;
  },
};
</script>
