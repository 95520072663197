<template>
  <div>
    <button
      @click="mostrarAjuda()"
      type="button"
      class="btn base-button btn-neutral btn-sm precisoAjuda"
    >
      Precisas de ajuda no menu {{ $route.name }}?
    </button>
  </div>
  <modal
    v-model:show="modals.notice"
    modal-classes="modal-danger"
    modal-content-classes="bg-gradient-danger"
  >
    <template v-slot:header>
      <h6 class="modal-title text-default">{{ textoAjudaNome }}</h6>
    </template>

    <div class="py-3 text-justify text-default">
      {{ textoAjudaDescricao }}
    </div>

    <template v-slot:footer>
      <div
        type="link"
        class="text-default ml-auto text-right"
        @click="modals.notice = false"
      >
        Fechar
      </div>
    </template>
  </modal>
</template>
<script>
import AjudaTextos from "./AjudaTextos";
import Modal from "../../../src/components/Modal";

export default {
  name: "icons",
  components: {
    Modal,
  },
  data() {
    return {
      AjudaTextos,
      nomeDaRota: this.$route.name,
      textoAjudaNome: "",
      textoAjudaDescricao: "",
      mostrarTexto: false,
      modals: {
        notice: false,
      },
    };
  },
  methods: {
    mostrarAjuda() {
      this.modals.notice = true;
      this.mostrarTexto = true;

      for (let i = 0; i < this.AjudaTextos.length; i++) {
        if (this.nomeDaRota == this.AjudaTextos[i].nomeRota) {
          this.textoAjudaNome = this.AjudaTextos[i].nomeRota;
          this.textoAjudaDescricao = this.AjudaTextos[i].descricao;
        }
      }
    },
  },
};
</script>
