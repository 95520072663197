<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">
            {{ $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Detalhes</h3>
        </div>
        <!-- Card body -->
        <div class="card-body" v-show="!alternarFormMensagem">
          <h2 class="text-center">
            Preenche a tua missão! A missão ajuda-te a ter compromissos
            claramente definidos <br />
            para com a empresa, a tua equipa, os teus clientes, utilizadores e
            contigo mesm@!
          </h2>
          <form>
            <div class="pl-lg-0">
              <div class="row">
                <div class="col-lg-6">
                  <label class="form-control-label"> Titulo: </label>
                  <input
                    v-model="titulo"
                    required
                    class="form-control"
                    name="grid1"
                    type="text"
                  />
                </div>
                <div class="col-lg-6">
                  <label class="form-control-label"> Data: </label>
                  <input
                    disabled
                    class="form-control"
                    v-model="dataAtual"
                    name="grid1"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label"> Descrição: </label>
                  <form>
                    <html-editor></html-editor>
                  </form>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-lg-12">
                  <label class="form-control-label"
                    >Desejas que a tua missão seja vista por outros
                    declathorianos?</label
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 mt-0">
                  <base-switch
                    class="ml-1"
                    on-text="Sim"
                    off-text="Não"
                    @change="alterarEstadoMissao()"
                    v-model="publico"
                  ></base-switch>
                </div>
              </div>
            </div>
            <div class="col-lg-12 text-left mt-4 pl-0">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="criarMissao(identificador)"
                >Guardar</v-button
              >
            </div>
          </form>
        </div>

        <div class="card-body p-5" v-show="alternarFormMensagem">
          <h2 class="text-center text-success">
            Obrigado por preencheres a tua missão!
          </h2>
          <h3 class="text-center">
            A missão ajuda-te a ter compromissos claramente definidos <br />
            para com a empresa, a tua equipa, os teus clientes, utilizadores e
            contigo mesm@!
          </h3>
        </div>
      </div>

      <div class="card">
        <div class="border-0 card-header">
          <h3 class="mb-0">Missões Passadas</h3>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(missoes)"
        >
          <el-table-column label="Titulo" prop="name" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.titulo }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <!-- <el-table-column
            label="Descrição"
            min-width="210px"
            prop="name"
            sortable
          > 
             <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span
                    class="font-weight-600 name mb-0 text-sm"
                    v-html="row.descricao"
                  ></span>
                </div>
              </div>
            </template> 
          </el-table-column> -->

          <el-table-column label="Data" prop="name">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.data_criacao }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Acções" prop="name" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <v-button
                    class="btn base-button btn-primary btn-sm btn-primary"
                    type="success"
                    @click="
                      mostrarCamposeditar(row.id, row.descricao, row.titulo)
                    "
                    v-show="podeEditar(row.data_criacao, row.id)"
                    >Editar
                  </v-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import Ajuda from "../Components/Ajuda";
export default {
  name: "Missao",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    HtmlEditor,
  },
  data() {
    return {
      currentPage: 1,
      titulo: "",
      dataAtual: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      descricao: "",
      estado: "",
      missoes: [],
      missaoAtual: [],
      alternarFormMensagem: false,
      alternarBtEditar: false,
      identificador: "",
      publico: true,
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    diferencaDias(date1, date2) {
      var dt1 = new Date(date1);
      var dt2 = new Date(date2);
      return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    },

    mostrarCamposeditar(id, descricao, titulo) {
      this.identificador = id;
      document.getElementsByClassName("ql-editor")[0].innerHTML = descricao;
      this.titulo = titulo;
      this.alternarFormMensagem = !this.alternarFormMensagem;
    },

    podeEditar(data) {
      if (this.diferencaDias(data, this.dataAtual) < 30) {
        return true;
      } else {
        return false;
      }
    },

    async listarMissoes() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonMissao = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch("listarMissoes", jsonMissao);
      this.missoes = todas;

      var todasValores = Object.values(todas);
      var maisRecente = todasValores[0];

      this.missaoAtual = maisRecente;

      var anoAtual = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/")
        .split("/")[0];

      if (this.missaoAtual.data_criacao.includes(anoAtual)) {
        this.alternarFormMensagem = true;
      }
    },

    async criarMissao(id) {
      var entryUUID = localStorage.getItem("idYourCodeCalendar");
      this.descricao = document.getElementsByClassName(
        "ql-editor"
      )[0].innerHTML;
      if (id == "") {
        let jsonCriar = {
          entryUUID: entryUUID,
          titulo: this.titulo,
          descricao: this.descricao,
          data_criacao: this.dataAtual,
        };

        let res = await this.$store.dispatch("criarMissao", jsonCriar);
        if (res) {
          if (res.data.resultado == "OK") {
            this.estadoMissao(jsonCriar, this.publico);
            this.listarMissoes();
            this.successNotification(
              "top-right",
              "success",
              "Missão criada com sucesso!"
            );
          } else {
            this.errorNotification(
              "top-right",
              "danger",
              "Ocurreu um erro! Por favor, tente mais tarde!"
            );
          }
        } else {
          this.errorNotification(
            "top-right",
            "danger",
            "Ocurreu um erro! Por favor, tente mais tarde!"
          );
        }
      } else {
        let jsonAtualizar = {
          id: id,
          entryUUID: entryUUID,
          titulo: this.titulo,
          descricao: this.descricao,
          data_criacao: this.dataAtual,
        };

        let res = await this.$store.dispatch("atualizarMissao", jsonAtualizar);
        if (res) {
          if (res.data.resultado == "OK") {
            this.estadoMissao(jsonAtualizar, this.publico);
            this.listarMissoes();
            this.successNotification(
              "top-right",
              "success",
              "Missão atualizada com sucesso!"
            );
          } else {
            this.errorNotification(
              "top-right",
              "danger",
              "Ocurreu um erro! Por favor, tente mais tarde!"
            );
          }
        } else {
          this.errorNotification(
            "top-right",
            "danger",
            "Ocurreu um erro! Por favor, tente mais tarde!"
          );
        }
      }
    },
    alterarEstadoMissao() {
      this.publico = !this.publico;
      console.log(this.publico);
    },

    async estadoMissao(data, estado) {
      if (estado == true) {
        var bodyAceite = {
          entryUUID: data.entryUUID,
          estado: 1,
        };
        await this.$store.dispatch("atualizarEstadoMissao", bodyAceite);
      } else {
        var bodyNaoAceite = {
          entryUUID: data.entryUUID,
          estado: 0,
        };
        await this.$store.dispatch("atualizarEstadoMissao", bodyNaoAceite);
      }
    },
  },
  mounted() {
    this.listarMissoes();
  },
};
</script>
