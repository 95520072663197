<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">Feedbacks</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col">
              <h3 class="mb-0">{{ $route.name }}</h3>
            </div>
          </div>
        </div>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(referentesRecebidos)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row.avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.nome + " " + row.apelido
                  }}</span>
                </div>
              </div>
              <modal class="modalPerfil" v-model:show="modals.recebido">
                <div class="card card-profile">
                  <div class="border-0 card-header">
                    <h3 class="mb-0">
                      Feedback Recebido - Comentário de
                      {{ row.nome + " " + row.apelido }}
                    </h3>
                    <p
                      class="mt-5"
                      v-html="comentarioIndividual.comentario"
                    ></p>
                  </div>
                </div>

                <template v-slot:footer>
                  <div
                    type="link"
                    class="ml-auto text-right"
                    @click="fecharModal()"
                  >
                    Fechar
                  </div>
                </template>
              </modal>
            </template>
          </el-table-column>

          <el-table-column label="Comentário" prop="comentario" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span
                    v-if="row.comentario"
                    class="font-weight-600 name mb-0 text-sm"
                    >{{ row.comentario }}</span
                  >
                  <span
                    v-else
                    class="font-weight-600 name mb-0 text-sm text-red"
                    >Pedido pendente de resposta</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Data" prop="data" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.data
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <v-button
              v-if="row.comentario"
              class="btn base-button btn-primary btn-sm"
              @click="mostarComentario(row.id)"
              >Ver Feedback
            </v-button>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Ajuda from "../../../Components/Ajuda";
import Modal from "../../../../components/Modal";
export default {
  name: "Recebidos",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      currentPage: 1,
      referentesRecebidos: [],
      comentarioIndividual: "",
      modals: {
        recebido: false,
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async mostarComentario(id) {
      var jsonComentario = {
        id: id,
      };

      this.comentarioIndividual = await this.$store.dispatch(
        "listarReferentesComentarioIndividual",
        jsonComentario
      );

      this.modals.recebido = true;
    },
    fecharModal() {
      this.modals.recebido = false;
    },
    async listarRecebidos() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonRecebidos = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listarReferentesRecebidos",
        jsonRecebidos
      );
      this.referentesRecebidos = todas;
    },
  },
  async mounted() {
    this.listarRecebidos();
  },
};
</script>
<style scoped>
.modal-content {
  width: 65vw !important;
}

.modal-dialog {
  max-width: 65vw !important;
}
</style>
