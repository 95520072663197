<template><div></div></template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      user: [],
    };
  },
  methods: {
    background(imagem) {
      return (
        "background-image:  url('" +
        imagem +
        "');background-size: cover; background-repeat: no-repeat; background-position: center;"
      );
    },
    sair() {
      localStorage.removeItem("idYourCodeCalendar");
      this.$router.push("/login");
    },
    async getUser() {
      var entryUUID = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", entryUUID);

      this.user = Object.values(user1)[0];
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.avatarCan {
  width: 100%;
}
</style>
