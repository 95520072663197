<template>
  <base-header class="pb-6">
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>

        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          <route-bread-crumb> </route-bread-crumb>
        </nav>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--6">
    <div class="card">
      <div class="border-0 card-header">
        <h3 class="mb-0">PID's</h3>
      </div>
      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="pids"
        :items-per-page="5"
      >
        <el-table-column label="PID" min-width="310px" prop="titulo" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.titulo
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="funcoes"
          prop="funcoes"
          min-width="140px"
          sortable
        >
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.funcoes
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Data" min-width="170px" prop="status" sortable>
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <span class="status">{{ row.data }}</span>
            </badge>
          </template>
        </el-table-column>

        <el-table-column v-slot="{ row }" min-width="180px">
          <el-dropdown trigger="click" class="dropdown">
            <span class="btn btn-sm btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                label="Estado"
              >
                <a class="dropdown-item" @click="verPerguntas(row.id)">Ver</a>
                <a class="dropdown-item" @click="apadar(row.id)">Apagar</a>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-table-column>
      </el-table>

      <!-- MODAL PID + PERGUNTAS  -->
      <modal class="modalPerfil" v-model:show="modal.pid">
        <div class="card">
          <div class="row justify-content-center">
            <div class="col-lg-10 order-lg-10">
              <div class="card-profile-image"></div>
              <div
                class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
              ></div>
              <div class="card-body pt-0">
                <div class="text-center">
                  <div v-if="perguntasPidSelecionado.length > 0">
                    <h1>{{ tituloPid }}</h1>
                    <base-input name="select" label="Etapas">
                      <el-select
                        v-model="selects.simple"
                        filterable
                        placeholder="Escolha uma etapa"
                        @change="escolherEtapa"
                      >
                        <el-option
                          v-for="option in selectOptionsPercentagem"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <div v-for="(item, i) in perguntasPidSelecionado" :key="i">
                      <div class="h5 font-weight-300">
                        Pergunta: {{ item.nome_pergunta }}
                      </div>
                      <div class="h5 font-weight-300">
                        Etapa: {{ item.etapa }}
                      </div>
                      <div class="h5 font-weight-300">
                        Bloco: {{ item.bloco }}
                      </div>
                      <div class="h5 font-weight-300">
                        ---------------------------
                      </div>
                    </div>
                  </div>
                  <div v-else>Este PID não tem perguntas associadas</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <div
            type="link"
            class="text-black ml-auto text-right"
            @click="this.modal.pid = false"
          >
            Fechar
          </div>
        </template>
      </modal>
      <!-- FIM MODAL PID + PERGUNTAS  -->
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import Modal from "@/components/Modal";
import { ElSelect, ElOption } from "element-plus";

export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Modal,
  },
  data() {
    return {
      modal: {
        pid: false,
      },
      pidID: "",
      tituloPid: "",
      pids: [],
      perguntas: [],
      perguntasPidSelecionado: [],
      selectOptionsPercentagem: [
        {
          label: "Todas as Etapas",
          value: "todas",
        },
        {
          label: "Etapa 0",
          value: "0",
        },
        {
          label: "Etapa 1",
          value: "1",
        },
        {
          label: "Etapa 2",
          value: "2",
        },
        {
          label: "Etapa 3",
          value: "3",
        },
        {
          label: "Etapa 4",
          value: "4",
        },
        {
          label: "Etapa 5",
          value: "5",
        },
        {
          label: "Etapa 6",
          value: "6",
        },
        {
          label: "Etapa 7",
          value: "7",
        },
        {
          label: "Etapa 8",
          value: "8",
        },
        {
          label: "Etapa 9",
          value: "9",
        },
        {
          label: "Etapa 10",
          value: "10",
        },
        {
          label: "Etapa 11",
          value: "11",
        },
        {
          label: "Etapa 12",
          value: "12",
        },
      ],
      selects: {
        simple: "",
      },
    };
  },
  methods: {
    async apadar(id) {
      var that = this;
      swal
        .fire({
          title: "Atenção",
          text: "Deseja apagar este PID? Ele não poderá ser restaurado!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
        })
        .then(async function (result) {
          if (result.isConfirmed) {
            let json = {
              id: id,
            };
            let res = await that.$store.dispatch("deletePID", json);
            console.log(res);
            if (res) {
              if (res.resultado == "OK") {
                that.successNotification(
                  "top-right",
                  "success",
                  "PID apagado com sucesso!"
                );
                let resPids = await that.$store.dispatch("pidsListarTodos");
                that.pids = Object.values(resPids);
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            } else {
              that.errorNotification(
                "top-right",
                "danger",
                "Ocurreu um erro. Por favor, tente mais tarde!"
              );
            }
          }
        });
    },
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    verPerguntas(id) {
      this.selects.simple = "";
      this.pidID = id;
      this.perguntasPidSelecionado = this.perguntas.filter(
        (x) => x.pid_id == id
      );
      this.perguntasPidSelecionado = this.perguntasPidSelecionado.sort(
        (x, y) => x.etapa > y.etapa
      );
      if (this.perguntasPidSelecionado[0]) {
        this.tituloPid = this.perguntasPidSelecionado[0].nome_pid;
      }

      this.modal.pid = true;
    },

    escolherEtapa() {
      if (this.selects.simple == "todas") {
        this.perguntasPidSelecionado = this.perguntas.filter(
          (x) => x.pid_id == this.pidID
        );
        this.perguntasPidSelecionado = this.perguntasPidSelecionado.sort(
          (x, y) => x.etapa > y.etapa
        );
        this.tituloPid = this.perguntasPidSelecionado[0].nome_pid;
      } else {
        this.perguntasPidSelecionado = this.perguntas.filter(
          (x) => x.pid_id == this.pidID
        );
        this.perguntasPidSelecionado = this.perguntasPidSelecionado.filter(
          (x) => x.etapa == this.selects.simple
        );
      }
    },
  },
  async mounted() {
    // IR BUSCAR OS PIDS
    let res = await this.$store.dispatch("pidsListarTodos");
    this.pids = Object.values(res);

    // IR BUSCAR AS PERGUNTAS
    let resPerguntas = await this.$store.dispatch("pidsListarPerguntasTodas");
    this.perguntas = Object.values(resPerguntas);
    console.log(this.perguntas);
  },
};
</script>
