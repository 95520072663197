<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0 text-left">
            {{ $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb> </route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <Ajuda />
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="border-0 card-header">
          <div class="row">
            <div class="col"><h3 class="mb-0">Ligações</h3></div>
            <div class="col text-right">
              <input
                type="search"
                class="form-control"
                clearable
                prefix-icon="el-icon-search"
                style="width: 200px; float: right"
                placeholder="Pesquisar"
                v-model="searchQuery"
                aria-controls="datatables"
              />
              <v-button
                class="mr-5 btn base-button btn-success"
                type="success"
                @click="mostrarLigacoes()"
                >Adicionar Ligação
              </v-button>
            </div>
          </div>
        </div>

        <modal class="modalPerfil" v-model:show="modals.perfil">
          <div class="card card-profile">
            <div class="border-0 card-header">
              <h3 class="mb-0">Utilizadores</h3>
            </div>
            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="Object.values(utilizadores)"
            >
              <el-table-column label="Nome" prop="name" sortable>
                <template v-slot="{ row }">
                  <div class="media align-items-center">
                    <a class="avatar rounded-circle mr-3">
                      <img alt="" :src="row.avatar" />
                    </a>
                    <div class="media-body">
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.nome + " " + row.apelido
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Responsabilidades" prop="name">
                <template v-slot="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="font-weight-600 name mb-0 text-sm">
                        {{ row.responsabilidades_individuais }}</span
                      >
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="name">
                <template v-slot="{ row }">
                  <div class="media align-items-right text-center">
                    <div class="media-body">
                      <v-button
                        class="btn base-button btn-primary btn-sm"
                        :class="
                          filtrar(row.entryUUID) == 'Adicionado'
                            ? 'disabled'
                            : ''
                        "
                        :disabled="filtrar(row.entryUUID) == 'Adicionado'"
                        @click="showSwal('adicionar', row.entryUUID)"
                        >{{ filtrar(row.entryUUID) }}
                      </v-button>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <template v-slot:footer>
            <div type="link" class="ml-auto text-right" @click="fecharModal()">
              Fechar
            </div>
          </template>
        </modal>

        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(referenteLigacoes)"
        >
          <el-table-column label="Decathloniano" prop="decathloniano" sortable>
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <a class="avatar rounded-circle mr-3">
                  <img alt="" :src="row[0].avatar" />
                </a>
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row[0].nome + " " + row[0].apelido
                  }}</span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Responsabilidades" prop="name">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row[0].responsabilidades_individuais }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Função" prop="name">
            <template v-slot="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row[0].funcao }}</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column v-slot="{ row }">
            <el-dropdown trigger="click" class="dropdown">
              <span class="btn btn-sm btn-icon-only text-light">
                <i class="fas fa-ellipsis-v mt-2"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu
                  class="dropdown-menu dropdown-menu-arrow show"
                  label="Estado"
                >
                  <a class="dropdown-item" @click="showSwal('pedir', row)"
                    >Pedir feedback</a
                  >
                  <a class="dropdown-item" @click="showSwal('remover', row)"
                    >Remover</a
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import Ajuda from "../../../Components/Ajuda";
import Modal from "../../../../components/Modal";
export default {
  name: "ListarLigacoes",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    Ajuda,
    Modal,
  },
  data() {
    return {
      currentPage: 1,
      referenteLigacoes: [],
      utilizadores: [],
      utilizadorSelecionado: [],
      modals: {
        perfil: false,
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async listarReferentesLigacoes() {
      var id = localStorage.getItem("idYourCodeCalendar");
      var jsonLigacoes = {
        entryUUID: id,
      };

      var todas = await this.$store.dispatch(
        "listarReferentesLigacoes",
        jsonLigacoes
      );

      this.referenteLigacoes = Object.values(todas);
    },
    async showSwal(type, id) {
      var that = this;
      if (type === "remover") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja apagar esta ligação?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                entryUUID: localStorage.getItem("idYourCodeCalendar"),
                entryUUID_referente: id[0].entryUUID,
              };
              console.log(json);
              let res = await that.$store.dispatch(
                "apagarReferentesLigacoes",
                json
              );

              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Ligação removida com sucesso!"
                  );

                  that.listarReferentesLigacoes();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "pedir") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja pedir um Feedback Referente a este Decathloniano?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                entryUUID: localStorage.getItem("idYourCodeCalendar"),
                entryUUID_referente: id[0].entryUUID,
              };
              let res = await that.$store.dispatch("pedirReferente", json);

              if (res) {
                if (res.data.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Pedido enviado com sucesso!"
                  );

                  that.listarReferentesLigacoes();
                } else if (res.data.resultado == "DUPLICADO") {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Já existe um pedido de feedback para este Decathloniano pendente!"
                  );
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "adicionar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja adicionar este Decathloniano, como referente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                entryUUID: localStorage.getItem("idYourCodeCalendar"),
                entryUUID_referente: id,
              };

              let res = await that.$store.dispatch(
                "criarReferentesLigacoes",
                json
              );

              if (res) {
                if (res.data.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Adicionado com sucesso!"
                  );

                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      }
    },
    mostrarLigacoes() {
      this.modals.perfil = true;
    },
    fecharModal() {
      this.modals.perfil = false;
    },
    async inicio() {
      this.listarReferentesLigacoes();

      var utilizadores = [];
      if (this.$store.state.todosUtilizadores.length == undefined) {
        utilizadores = this.$store.state.todosUtilizadores;
        this.utilizadores = utilizadores;

        this.rendered = true;
      } else {
        utilizadores = await this.$store.dispatch("todosUtilizadores");
        this.utilizadores = utilizadores;
        this.utilizadores = this.utilizadores.filter(
          (x) => x.entryUUID != localStorage.getItem("idYourCodeCalendar")
        );
        this.rendered = true;
      }
    },
    filtrar(entryUUID) {
      var users = [];
      var existe = [];
      for (let i = 0; i < this.referenteLigacoes.length; i++) {
        users.push(this.referenteLigacoes[i][0]);
      }
      existe = users.filter((x) => x.entryUUID == entryUUID);
      if (existe) {
        if (existe.length > 0) {
          return "Adicionado";
        } else {
          return "Adicionar";
        }
      } else {
        return "Adicionar";
      }
    },
  },
  async mounted() {
    this.inicio();
  },
};
</script>
<style scoped>
.modal-content {
  width: 65vw !important;
}

.modal-dialog {
  max-width: 65vw !important;
}
</style>
