<template>
  <div v-if="rendered">
    <div>
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <Ajuda />
          </div>
        </div>
      </base-header>
    </div>
    <div class="container-fluid mt--6">
      <div class="card">
        <!-- Card header -->
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 mt-0">
              <h1 class="display-3 ml-1 text-capitalize">
                {{ noticiaIndividual[current].titulo }}
              </h1>
            </div>
            <div class="col-lg-6 text-right">
              <p class="ml-2 infoNoticia">
                <i class="ni ni-watch-time"></i>
                {{ noticiaIndividual[current].data }}
                <i class="ni ni-single-02 ml-2"></i>
                {{ noticiaIndividual[current].autor }}
              </p>
              <div class="col-lg-12">
                <p
                  :class="
                    listarInteracoes(noticiaIndividual[current].id) +
                    ' infoNoticia'
                  "
                >
                  <span
                    class="gostoCor"
                    @click="
                      gostar(
                        listarInteracoes(noticiaIndividual[current].id),
                        noticiaIndividual[current].id
                      )
                    "
                  >
                    <i class="ml-3 ni ni-like-2"></i
                    ><span>
                      {{ contadorGostos(noticiaIndividual[current].id) }}</span
                    >
                    Gosto(s)
                  </span>

                  <base-dropdown
                    menu-on-right
                    tag="li"
                    title-tag="a"
                    title-classes="nav-link pr-0"
                  >
                    <template v-slot:title-container>
                      <i class="ml-3 ni ni-send" @click.prevent></i> Opções de
                      partilha
                    </template>

                    <ul
                      class="dropdown-toggle"
                      v-if="noticiaIndividual[current].url"
                    >
                      <div
                        class="nav-link"
                        @click="
                          partilhar(
                            'whatsapp',
                            noticiaIndividual[current].id,
                            noticiaIndividual[current].url,
                            noticiaIndividual[current].titulo
                          )
                        "
                      >
                        <span class="whatsapp"
                          ><i class="fab fa-whatsapp"></i
                        ></span>

                        <span style="color: #000"> WhatsApp </span>
                      </div>
                      <div
                        class="nav-link"
                        @click="
                          partilhar(
                            'twitter',
                            noticiaIndividual[current].id,
                            noticiaIndividual[current].url,
                            noticiaIndividual[current].titulo
                          )
                        "
                      >
                        <span class="twitter"
                          ><i class="fab fa-twitter"></i
                        ></span>
                        <span style="color: #000"> Twitter</span>
                      </div>
                      <div
                        class="nav-link"
                        @click="
                          partilhar(
                            'facebook',
                            noticiaIndividual[current].id,
                            noticiaIndividual[current].url,
                            noticiaIndividual[current].titulo
                          )
                        "
                      >
                        <span class="facebook"
                          ><i class="fab fa-facebook"></i
                        ></span>
                        <span style="color: #000"> Facebook</span>
                      </div>
                    </ul>
                    <ul
                      class="dropdown-toggle"
                      v-if="!noticiaIndividual[current].url"
                    >
                      <div
                        class="nav-link"
                        @click="
                          copyPaste(
                            'https://calendario.yourcode.pt/noticia?id=' +
                              noticiaIndividual[current].id
                          )
                        "
                      >
                        <span><i class="far fa-clone"></i></span>

                        <span style="color: #000"> Copiar Link Interno </span>
                      </div>
                    </ul>
                  </base-dropdown>
                </p>
              </div>
            </div>
          </div>
          <div id="slider">
            <transition-group
              tag="div"
              :name="transitionName"
              class="slides-group"
            >
              <div :key="current" class="slide">
                <p>
                  <a :href="noticiaIndividual[current].url" target="_blank">
                    <img
                      class="height"
                      :src="noticiaIndividual[current].imagem"
                      alt=""
                    />
                  </a>
                </p>

                <!-- <p>I'm {{ slides[current].className }}!</p> -->
              </div>
            </transition-group>
            <div
              class="btn btn-prev"
              aria-label="Previous slide"
              @click="slide(-1)"
            >
              &#10094;
            </div>
            <div class="btn btn-next" aria-label="Next slide" @click="slide(1)">
              &#10095;
            </div>
          </div>
          <div class="container-fluid mt-5">
            <div
              id="descricao"
              v-html="noticiaIndividual[current].descricao"
            ></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 mt-0">
              <h1 class="display-3 ml-1 text-capitalize">Comentários:</h1>
            </div>
          </div>
          <div v-if="comentarios.length > 0">
            <div v-for="(item, i) in comentarios" :key="i">
              <p>
                Utilizador: <span> {{ item.nome + " " + item.apelido }}</span>
              </p>
              <p>
                Data: <span>{{ item.data }}</span>
              </p>
              <p>
                Comentário: <span>{{ item.comentario }}</span>
              </p>
              <hr />
            </div>
          </div>
          <div v-else>
            <p>Ainda não existem comentários...</p>
          </div>
          <div>
            <div class="card-header">
              <h3 class="mb-0">Comentar</h3>
            </div>
            <!-- Card body -->
            <div class="card-body pt-0 pb-0">
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea
                    rows="4"
                    class="form-control"
                    placeholder="Novo Comentário"
                    v-model="novoComentario"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-left pr-0 pl-0">
        <v-button
          class="btn base-button btn-success"
          type="success"
          @click="enviarComentario()"
          >Enviar Comentário</v-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Ajuda from "../Components/Ajuda";
import Notification from "@/components/Notification";
import { useToast } from "vue-toastification";

export default {
  components: {
    Ajuda,
  },
  data() {
    return {
      todasInteracoes: [],
      interacoesGostos: [],
      gostou: "gosto",
      comentarios: [],
      id1: "",
      noticiaIndividual: [],
      current: 0,
      rendered: false,
      direction: 1,
      transitionName: "fade",
      novoComentario: "",
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "Link da notícia copiado!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: text,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    copyPaste(url) {
      navigator.clipboard.writeText(url);
      this.successNotification("top-right", "success");
    },
    partilhar(onde, id, link, titulo) {
      if (onde == "whatsapp") {
        window.open("whatsapp://send?text=" + link);
      } else if (onde == "twitter") {
        window.open("http://twitter.com/share?text=" + titulo + "&url=" + link);
      } else if (onde == "facebook") {
        window.open(
          "https://www.facebook.com/share.php?u=" + link + "&quote=" + titulo
        );
      }
    },

    contadorGostos(idNoticia) {
      var contador = 0;
      var quantidade = this.todasInteracoes.filter(
        (x) => x.id_noticia == idNoticia
      );
      for (let i = 0; i < quantidade.length; i++) {
        if (quantidade[i].gosto == 1) {
          contador++;
        }
      }
      return " " + contador;
    },
    listarInteracoes(id) {
      var interacoesNoticia = this.interacoesGostos;
      var converterInteracoesNoticia = Object.values(interacoesNoticia);

      var procuraInteracao = converterInteracoesNoticia.find(
        (x) => x.id_noticia == id
      );
      if (procuraInteracao) {
        if (procuraInteracao.gosto == 1) {
          return this.gostou;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async gostar(data, idNoticia) {
      var todas = [];
      todas.push(
        this.todasInteracoes.find(
          (x) =>
            x.id_noticia == idNoticia &&
            x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
        )
      );

      var indexInteracoesGostos = this.interacoesGostos
        .map((x) => x.id_noticia)
        .indexOf(idNoticia);
      var entryUUid = localStorage.getItem("idYourCodeCalendar");
      if (data == false) {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos[indexInteracoesGostos].gosto = 1;
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 1;
            }
          });
        } else {
          this.interacoesGostos.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
          this.todasInteracoes.push({
            id_noticia: idNoticia,
            id_utilizador: entryUUid,
            gosto: 1,
            comentario: "",
            data: "",
          });
        }

        // this.listarInteracoes();
        var json = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 1,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json);
      } else {
        if (indexInteracoesGostos > -1) {
          this.interacoesGostos.splice(indexInteracoesGostos, 1);
          this.todasInteracoes.find(function (x) {
            if (
              x.id_noticia == idNoticia &&
              x.id_utilizador == localStorage.getItem("idYourCodeCalendar")
            ) {
              x.gosto = 0;
            }
          });
        }
        var json1 = {
          entryUUid: entryUUid,
          noticia: idNoticia,
          gosto: 0,
        };
        await this.$store.dispatch("atualizarEstadoNoticia", json1);
        this.listarInteracoes();
      }
    },
    async enviarComentario() {
      var comentario = this.novoComentario;
      var id = this.noticiaIndividual[0].id;
      if (comentario != "") {
        let jsonComentario = {
          id_noticia: id,
          id_utilizador: localStorage.getItem("idYourCodeCalendar"),
          gosto: "",
          comentario: comentario,
        };
        let res = await this.$store.dispatch("novoComentario", jsonComentario);
        console.log(res);
        if (res) {
          if (res.data.resultado == "OK") {
            // alerta sucesso
            let json = {
              id: id,
            };
            let comentariosAtualizados = await this.$store.dispatch(
              "noticiaComentarios",
              json
            );
            this.comentarios = Object.values(comentariosAtualizados);
          }
        }
      }
    },
    async procurarId() {
      var id = window.location.href.split("=")[1];

      if (id != undefined) {
        let json = {
          id: id,
        };
        let noticia = await this.$store.dispatch("noticiaIndividual", json);
        let comentarios = await this.$store.dispatch(
          "noticiaComentarios",
          json
        );
        this.comentarios = Object.values(comentarios);
        this.noticiaIndividual = noticia;
        await this.iframe();
        this.rendered = true;
      } else {
        this.rendered = true;
      }
      console.log(this.comentarios);
    },
    iframe() {
      var alterarDescricao = this.noticiaIndividual;
      for (let i = 0; i < alterarDescricao.length; i++) {
        alterarDescricao[i].descricao = alterarDescricao[i].descricao
          .replaceAll(/&lt;/g, "<")
          .replace(/&gt;/g, ">");
      }
      this.noticiaIndividual = alterarDescricao;
    },

    slide(dir) {
      // console.log(this.noticiaIndividual);
      this.direction = dir;
      dir === 1
        ? (this.transitionName = "slide-next")
        : (this.transitionName = "slide-prev");
      var len = this.noticiaIndividual.length;
      this.current = (this.current + (dir % len) + len) % len;
      this.iframe();
    },
  },
  async mounted() {
    this.procurarId();
    this.iframe();
    var id = {
      entryUUID: localStorage.getItem("idYourCodeCalendar"),
    };
    let interacoes = await this.$store.dispatch("listarInteracoes", id);
    let todasInteracoes = await this.$store.dispatch("listarTodasInteracoes");
    this.todasInteracoes = Object.values(todasInteracoes);
    this.interacoesGostos = Object.values(interacoes);
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Crimson+Text");
.instagram {
  color: #d6249f;
}
.facebook {
  color: #4267b2;
}
.twitter {
  color: #1da1f2;
}
.whatsapp {
  /* color: #075e54; */
  color: #7fff00;
}
.gostoCor {
  cursor: pointer;
}

.gosto .gostoCor {
  color: red;
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
/* .slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
/* .slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 1s ease-in-out;
} */
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

/* SLIDES CLASSES */
.height {
  max-height: 450px;
  max-width: 800px;
}

body {
  overflow: hidden;
  margin: 0;
  font-size: 50px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
}

#slider {
  width: 100%;
  height: 45vh;
  position: relative;
}

.slide {
  width: 100%;
  height: 45vh;
  position: absolute;
  top: 5;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-next {
  left: auto;
  right: 1%;
}

.btn:hover {
  transform: scale(1.1);
}

.infoNoticia {
  font-size: 12px;
}
.btn.btn-prev,
.btn.btn-next {
  display: none;
}
.dropdown-toggle::after {
  display: none;
}
</style>
