<template>
  <div class="quill">
    <div id="idQuill" :name="name" class="" ref="editor"></div>
  </div>
</template>
<script>
export default {
  name: "html-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    name: String,
  },
  data() {
    return {
      editor: null,
      content: null,
      lastHtmlValue: "",
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize(Quill) {
      var toolbarOptions = [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }], // toggled buttons
        [{ align: [] }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // Como o TAB
        // [{ font: [] }],

        ["clean"], // remove formatting button
      ];
      this.editor = new Quill("#idQuill", {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
      });

      if (this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }

      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on("text-change", () => {
        let html = node.innerHTML;
        if (html === "<p><br></p>") {
          html = "";
        }
        if (html.includes("iframe")) {
          html = "<iframe>" + html + "</iframe>";
        }
        node.innerHTML.replaceAll(/&lt;/g, "<").replace(/&gt;/g, ">");

        this.content = html;
        this.$emit("input", this.content);
      });
    },

    pasteHTML() {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },

    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  async mounted() {
    let Quill = await import("quill");
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
<style>
#editor-container {
  height: 375px;
}
#idQuill {
  height: 400px;
}
</style>
