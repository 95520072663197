<template>
  <loader
    v-show="carregar"
    object="rgb(20, 130, 194)"
    color1="#ffffff"
    color2="rgb(20, 130, 194)"
    size="5"
    speed="10"
    bg="#343a40"
    objectbg="#999793"
    opacity="80"
    name="circular"
  ></loader>

  <card>
    <template v-slot:header>
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Editar Perfil</h3>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-8 text-right">
              <h5 class="h3 mb-0 pt-2">Como os outros me vêem</h5>
            </div>
            <div class="col-4 text-right">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="mostrarModal()"
                >Ver Perfil</v-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- BARRA DE PROGRESSO -->
    <div class="card mb-6">
      <!-- MODALS  -->
      <modal class="modalPerfil" v-if="rendered" v-model:show="modals.classic">
        <div class="card card-profile">
          <div
            class="card-img-top"
            style="background-image: url(/img/theme/img-1-1000x600.jpg)"
          />
          <div class="row justify-content-center">
            <div class="col-lg-3 order-lg-2">
              <div class="card-profile-image">
                <span> </span>
                <img :src="user.avatar" class="rounded-circle" />
              </div>
            </div>
          </div>
          <div class="card-header text-center border-0 pt-6 pb-0 pb-md-4"></div>
          <div class="card-body pt-0">
            <div class="text-center">
              <h5 class="h3">
                {{ user.nome + " " + user.apelido }}
              </h5>
              <div class="h5 font-weight-300">
                <i class="ni ni-email-83"></i> {{ user.email }}
              </div>
              <div class="h5 font-weight-300">
                <i class="ni ni-mobile-button"></i> {{ user.telemovel }}
              </div>
              <div class="h5 font-weight-300">
                <i class="ni ni-briefcase-24"></i> {{ user.funcao }}
              </div>
              <div class="h5 font-weight-300">
                <i class="fas fa-running"></i>
                {{ user.desporto.replaceAll(",", ", ") }}
              </div>
              <div class="h5 font-weight-300">
                <i class="fas fa-globe-europe"></i>
                {{ user.territorio_responsabilidade }}
              </div>
              <div class="h5 font-weight-300">
                <i class="fas fa-check-square"></i>
                {{ user.responsabilidades_individuais }}
              </div>
              <div class="h5 font-weight-300">
                <i class="fas fa-address-card"></i> Sobre mim:<br />
                {{ user.descricao }}
              </div>
              <div class="h5 font-weight-300">
                <i class="fas fa-bullseye"></i> <br />
                <a @click="modalMissao()" href="#"> A minha Missão </a>
                <span style="font-size: 10px" v-if="user.missao_preenchida == 1"
                  >(Visivel a todos os decathlonianos)</span
                ><span
                  style="font-size: 10px"
                  v-if="user.missao_preenchida == 0"
                  >(Não Visivel a nenhum decathlonianos)</span
                >
              </div>
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <div
            type="link"
            class="text-white ml-auto text-right"
            @click="fecharModal()"
          >
            Fechar
          </div>
        </template>
      </modal>
      <modal
        class="modalPerfil"
        v-if="user.length != 0"
        v-model:show="modals.missao"
      >
        <div class="card">
          <!-- <img src="/img/theme/img-1-1000x600.jpg" class="card-img-top" /> -->
          <div class="row justify-content-center">
            <div class="col-lg-10 order-lg-10">
              <div class="card-profile-image"></div>
              <div
                class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
              ></div>
              <div class="card-body pt-0">
                <div class="text-center">
                  <h5 class="h3">
                    <img
                      style="max-width: 30px"
                      :src="user.avatar"
                      class="rounded-circle"
                    />{{ " " + user.nome + " " + user.apelido }}
                  </h5>
                  <div class="h5 font-weight-300">
                    Título da Missão: {{ missao.titulo }}
                  </div>
                  <div class="h5 font-weight-300">
                    Resumo da missão:
                    <span v-html="missao.descricao"></span>
                  </div>
                  <div class="h5 font-weight-300">
                    {{ missao.data }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <div
            type="link"
            class="text-black ml-auto text-right"
            @click="fecharModalMissao()"
          >
            Fechar
          </div>
        </template>
      </modal>
      <!-- FIM MODALS -->
      <div class="card-header">
        <!-- Title -->
        <h5 class="h3 mb-0">Progresso de Preenchimento</h5>
      </div>

      <!-- Card body -->
      <div class="card-body">
        <!-- List group -->
        <ul class="list-group list-group-flush list my--3">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="list-group-item px-0"
          >
            <div class="row align-items-center">
              <div class="col-auto">
                <!-- Avatar -->
                <a href="#" class="">
                  <span
                    class="avatar rounded-circle"
                    :style="background(item.imagem)"
                  ></span>
                  <!-- <img alt="" :src="item.imagem" /> -->
                </a>
              </div>
              <div class="col">
                <h5>{{ item.name }} {{ item.progress }}</h5>
                <base-progress
                  class="progress-xs mb-0"
                  :type="item.color"
                  :value="item.progress"
                >
                </base-progress>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <h6 class="heading-small text-muted mb-2">Informação do Utilizador</h6>

      <div class="pl-lg-0 mb-6">
        <div class="row mb-4">
          <div class="col-lg-4">
            <label class="form-control-label"> Nome: </label>
            <input
              type="text"
              @input="progressBarUpdate"
              disabled="disabled"
              :class="user.nome ? 'form-control' : 'form-control'"
              v-model="user.nome"
            />
          </div>

          <div class="col-lg-4">
            <label class="form-control-label">Apelido:</label>
            <input
              type="text"
              @input="progressBarUpdate"
              disabled="disabled"
              :class="user.apelido ? 'form-control' : 'form-control'"
              v-model="user.apelido"
            />
          </div>

          <div class="col-lg-4">
            <label class="form-control-label">Coach:</label>
            <input
              type="text"
              @input="progressBarUpdate"
              disabled="disabled"
              :class="user.coach ? 'form-control' : 'form-control'"
              v-model="user.coach"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-lg-4">
            <label class="form-control-label">E-mail:</label>
            <input
              type="text"
              @input="progressBarUpdate"
              disabled="disabled"
              :class="user.email ? 'form-control' : 'form-control'"
              v-model="user.email"
            />
          </div>

          <div class="col-lg-4">
            <label class="form-control-label">Telemóvel:</label>
            <input
              type="text"
              @input="progressBarUpdate"
              :class="
                user.telemovel
                  ? 'form-control complete'
                  : 'form-control incomplete'
              "
              v-model="user.telemovel"
            />
          </div>

          <div class="col-lg-4">
            <label class="form-control-label">Função:</label>
            <input
              type="text"
              @input="progressBarUpdate"
              disabled="disabled"
              :class="user.funcao ? 'form-control' : 'form-control'"
              v-model="user.funcao"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-lg-4">
            <label class="form-control-label">Data de início de Função:</label>
            <base-input addon-left-icon="ni ni-calendar-grid-58">
              <flat-picker
                @on-open="focus"
                @on-close="blur"
                @change="progressBarUpdate"
                :config="{ allowInput: true }"
                class="form-control"
                v-model="user.data_inicio_funcao"
              >
              </flat-picker>
            </base-input>
          </div>

          <div class="col-lg-4">
            <base-input name="selectmultiple" label="Desporto Paixão:">
              <el-select
                v-model="desportoPaixao"
                multiple
                filterable
                placeholder="Adicionar"
                @change="valorDropDown()"
              >
                <el-option
                  v-for="option in OpcoesDesporto"
                  :key="option.label"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div v-if="user.outro_desporto || mostrarOutro" class="col-lg-4">
            <label class="form-control-label">Outro Desporto:</label>
            <input
              type="text"
              class="form-control"
              v-model="user.outro_desporto"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-lg-12">
            <label class="form-control-label">Fotografia:</label>
            <dropzone-file-upload
              style="border: 1px dashed #000; border-radius: 0.375rem"
              @change="imgF"
            ></dropzone-file-upload>
          </div>
        </div>
        <h6 class="heading-small text-muted mt-5">Responsabilidades</h6>
        <div class="row">
          <div class="col-lg-6">
            <label class="form-control-label"
              >Territórios de Responsabilidade:</label
            >
            <input
              type="text"
              @input="progressBarUpdate"
              :class="
                user.territorio_responsabilidade
                  ? 'form-control complete'
                  : 'form-control incomplete'
              "
              v-model="user.territorio_responsabilidade"
            />
          </div>

          <div class="col-lg-6">
            <base-input
              name="selectmultiple"
              label="Responsabilidades Individuais"
            >
              <el-select
                v-model="responsabilidadesIdividuais"
                multiple
                filterable
                placeholder="Adicionar"
                @change="progressBarUpdate()"
              >
                <el-option
                  v-for="option in OpcoesResponsabilidade"
                  :key="option.label"
                  :label="option.label"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="card mt-5">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Sobre mim</h3>
        </div>
        <!-- Card body -->
        <div class="card-body pb-0">
          <div class="col-lg-12">
            <div class="form-group">
              <textarea
                rows="4"
                class="form-control"
                placeholder="Umas palavras sobre mim ..."
                v-model="user.descricao"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-left pr-0 pl-0">
        <v-button
          class="btn base-button btn-success"
          type="success"
          @click="updateProfile"
          >Guardar</v-button
        >
      </div>
    </div>
  </card>
</template>
<script>
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import { ElTag } from "element-plus";
import Modal from "@/components/Modal";
import { ElSelect, ElOption } from "element-plus";

export default {
  components: {
    flatPicker,
    DropzoneFileUpload,
    [ElTag.name]: ElTag,
    Modal,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      description: "List of tags",
    },
    tagType: {
      type: String,
      default: "primary",
      description: "Tag type (primary|danger etc)",
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      desportoPaixao: [],
      responsabilidadesIdividuais: [],
      outroDesporto: "",
      mostrarOutro: false,
      carregar: true,
      modals: {
        classic: false,
        notice: false,
        form: false,
        missao: false,
      },
      missao: {
        titulo: "",
        descricao: "",
        data: "",
      },
      OpcoesDesporto: [],
      OpcoesResponsabilidade: [],
      inputVisible: false,
      inputValue: "",
      user: {},
      fileSingle: "",
      descricao: "",
      showDismissibleAlert: true,
      classInputComplete: "form-control complete",
      classInputInomplete: "form-control incomplete",
      list: [
        {
          name: "Preenchimento Perfil",
          image: "",
          color: "",
          progress: 0,
        },
      ],
      rendered: false,
    };
  },
  methods: {
    background(imagem) {
      return (
        "background-image:  url('" +
        imagem +
        "');background-size: cover; background-repeat: no-repeat; background-position: center;"
      );
    },
    modalMissao() {
      this.modals.classic = false;
      this.modals.missao = true;
    },
    fecharModalMissao() {
      this.modals.missao = false;
    },
    valorDropDown() {
      this.progressBarUpdate();
      if (this.desportoPaixao.toString().includes("Outro")) {
        this.mostrarOutro = true;
      } else {
        this.mostrarOutro = false;
      }
    },

    mostrarModal() {
      this.rendered = true;
      this.modals.classic = true;
    },

    fecharModal() {
      this.rendered = false;
      this.modals.classic = false;
    },

    showInput() {
      this.inputVisible = true;
      this.progressBarUpdate();
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    onInput(evt) {
      this.$emit("input", evt.target.value);
      this.progressBarUpdate();
    },

    editDescricao(data) {
      if (data == 1) {
        this.descricao = "";
      } else {
        this.descricao = this.user.descricao;
      }
    },

    imgF() {
      setTimeout(() => {
        var img = document.getElementsByClassName("dz-preview-img");
        for (let i = 0; i < img.length; i++) {
          if (i === img.length - 1) {
            this.user.avatar = img[i].src;
          }
        }
      }, 200);
    },

    progressBarUpdate() {
      var percentagem = 0;
      let json = [
        { nome: this.user.apelido, pontuacao: 5 },
        { nome: this.user.avatar, pontuacao: 10 },
        { nome: this.user.coach, pontuacao: 5 },
        { nome: this.user.data_inicio_funcao, pontuacao: 5 },
        { nome: this.desportoPaixao, pontuacao: 10 },
        { nome: this.user.email, pontuacao: 5 },
        { nome: this.user.funcao, pontuacao: 5 },
        { nome: this.user.missao_preenchida, pontuacao: 15 },
        { nome: this.user.nome, pontuacao: 5 },
        {
          nome: this.user.responsabilidades_individuais,
          pontuacao: 15,
        },
        { nome: this.user.telemovel, pontuacao: 5 },
        {
          nome: this.user.territorio_responsabilidade,
          pontuacao: 15,
        },
      ];
      for (let i = 0; i < json.length; i++) {
        if (
          json[i].nome != "NA" &&
          json[i].nome != "N" &&
          json[i].nome != "null" &&
          json[i].nome != "Null" &&
          json[i].nome != "NULL" &&
          json[i].nome != ""
        ) {
          percentagem += json[i].pontuacao;
        }
      }
      this.list[0].progress = percentagem;
      if (percentagem > 0 && percentagem <= 40) {
        this.list[0].color = "red";
      } else if (percentagem > 40 && percentagem < 99) {
        this.list[0].color = "yellow";
      } else {
        this.list[0].color = "green";
      }
    },

    async getUserProfile() {
      var id = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", id);

      this.user = Object.values(user1)[0];
      if (this.user.desporto != "") {
        this.desportoPaixao = this.user.desporto.split(",");
      } else {
        this.desportoPaixao = "";
      }
      if (this.user.responsabilidades_individuais != "") {
        this.responsabilidadesIdividuais = this.user.responsabilidades_individuais.split(
          ","
        );
      } else {
        this.responsabilidadesIdividuais = "";
      }

      this.carregar = false;
      this.list[0].imagem = this.user.avatar;
      this.missao.titulo = this.user.titulo;
      this.missao.descricao = this.user.descricao_missao;
      this.missao.data = this.user.data_criacao;
      this.progressBarUpdate();
    },

    async updateProfile() {
      this.list.imagem = this.user.avatar;

      if (this.responsabilidadesIdividuais) {
        this.user.responsabilidades_individuais = this.responsabilidadesIdividuais.toString();
      }
      if (this.desportoPaixao) {
        this.user.desporto = this.desportoPaixao.toString();
      }
      let res = await this.$store.dispatch("updateProfile", this.user);
      this.progressBarUpdate();
      if (res) {
        if (res.includes("OK")) {
          this.successNotification("top-right", "success");
        } else {
          this.errorNotification("top-right", "danger");
        }
      } else {
        this.errorNotification("top-right", "danger");
      }

      this.editDescricao(0);
      // this.$router.go(this.$router.currentRoute);
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao atualizar o seu perfil! Tente novamente mais tarde!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    successNotification(pos, type, ownText, ownIcon) {
      const text = "O seu perfil foi atualizado com sucesso!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    async ajudas() {
      let res_Desportos = await this.$store.dispatch("desportos");
      var desportos = Object.values(res_Desportos.data);
      let res_Responsabilidades_individuais = await this.$store.dispatch(
        "responsabilidades_individuais"
      );
      var responsabilidades = Object.values(
        res_Responsabilidades_individuais.data
      );
      //Desportos//
      for (let i = 0; i < desportos.length; i++) {
        this.OpcoesDesporto.push({
          label: desportos[i].desporto,
          value: desportos[i].desporto,
        });
      }
      this.OpcoesDesporto.push({
        label: "Outro",
        value: "Outro",
      });
      // Responsabilidades Individuais //

      for (let k = 0; k < responsabilidades.length; k++) {
        this.OpcoesResponsabilidade.push({
          label: responsabilidades[k].responsabilidades,
          value: responsabilidades[k].responsabilidades,
        });
      }
    },
  },
  async mounted() {
    this.getUserProfile();
    this.ajudas();
  },
};
</script>
<style>
.descricaoArea {
  max-width: 80%;
}

.incomplete {
  background-color: rgba(255, 0, 0, 0.2) !important;
  border: 1px solid !important;
}

.complete {
  background-color: #fff !important;
  border: 1px solid #dee2e6;
}

.edit {
  background-color: #999;
  border: none;
  color: #fff;
}

.edit:hover {
  background-color: #999;
  border: none;
  color: #fff;
}

.modalPerfil .modal-body {
  padding: 0 !important;
}

.modalPerfil .modal-content {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
