<template>
  <div class="container mt-5 pt-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="card bg-secondary border-0 mb-0">
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center text-muted mb-4">
              <img
                src="https://calendario.yourcode.pt/img/theme/yourcode_logo.png"
                alt=""
              />
              <br />
            </div>
            <div class="row text-center">
              <div class="col-lg-6" style="margin: auto">
                <input
                  alternative
                  class="mb-3 form-control"
                  name="email"
                  placeholder="Email/Username"
                  v-model="username"
                />

                <input
                  alternative
                  class="mb-3 form-control"
                  name="password"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                />

                <div class="text-center">
                  <button
                    type="primary"
                    class="my-4 btn base-button bg-orange text-white"
                    @click="login()"
                  >
                    Entrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col text-center">
            <router-link to="/registar" class="text-light"
              ><small>Registar</small></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Form } from "vee-validate";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  // components: {
  //   Form,
  // },
  data() {
    return {
      username: "",
      password: "",
      carregar: false,
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      btnShow: true,
      btnLoginShow: false,
      settings: {
        client_id: "C76bc6b676813c7707a98e28b6f9d43f4e5c5c06b",
        callback_url: "https://calendario.yourcode.pt/login",
      },
    };
  },
  methods: {
    async login() {
      var username = this.username;
      var password = this.password;
      var data = {
        username: username,
        password: password,
      };
      let res = await this.$store.dispatch("login", data);
      console.log(res);
      if (res.resultado == "KO") {
        this.errorNotification("top-right", "danger");
        this.username = "";
        this.password = "";
      } else {
        localStorage.setItem(
          "idYourCodeCalendar",
          res.resultado[0].id_plataforma
        );
        this.$router.push("/calendario");
      }
    },
    errorNotification(pos, type, ownText, ownIcon) {
      const text = "Utilizador ou Email e password não correspondem";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },

  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const schema = Yup.object().shape({
      fullName: Yup.string().required().label("The Full Name"),
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(5).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
  mounted() {},
};
</script>

<style scoped>
.card-header.bg-transparent.pb-5 {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.3);
}
.btn-wrapper.text-center {
  padding-top: 1.7vh;
}
.logoLogin {
  border-radius: 20px;
}
.title {
  padding-top: 50px !important;
}
.mt--8,
.my--8 {
  margin: 0 !important;
}
</style>
