<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav
            aria-label="breadcrumb"
            class="d-none d-md-inline-block ml-md-4"
          ></nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <a
            href="#"
            @click.prevent="prev"
            class="fullcalendar-btn-prev btn btn-sm btn-default"
          >
            <i class="fas fa-angle-left"></i>
          </a>
          <a
            href="#"
            @click.prevent="next"
            class="fullcalendar-btn-next btn btn-sm btn-default"
          >
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridMonth')"
          >
            Mês
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridWeek')"
          >
            Semana
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('timeGridDay')"
          >
            Dia
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <div id="fullCalendar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model:show="showAddModal" modal-classes="modal-secondary">
      <label class="form-control-label"> Título: </label>
      <input
        class="form-control"
        name="grid1"
        id="grid1"
        type="text"
        placeholder="Titulo do Evento"
        v-model="model.title"
      />
      <label class="form-control-label"> URL ( Meeting ): </label>
      <input
        class="form-control"
        name="grid1"
        id="grid1"
        type="text"
        placeholder="URL do Evento"
        v-model="model.url"
      />

      <label class="form-control-label"> Descrição: </label>
      <textarea
        v-model="model.description"
        class="form-control form-control-alternative edit-event--description textarea-autosize"
        placeholder="Descrição do Evento"
      >
      </textarea>
      <i class="form-group--bar"></i>

      <!-- HORA DE COMECO -->

      <label class="form-control-label">Hora de Começo: </label>
      <input
        class="form-control"
        name="time"
        type="time"
        v-model="horaComeco"
        :disabled="diaTodo"
      />

      <!-- HORA DE FIM -->

      <label class="form-control-label">Hora de Fim: </label>
      <input
        class="form-control"
        name="time"
        type="time"
        v-model="horaFim"
        :disabled="diaTodo"
      />

      <base-checkbox v-model="diaTodo" class="mb-3"> O dia todo </base-checkbox>

      <div class="form-group">
        <label class="form-control-label d-block mb-3"> Cor: </label>
        <div class="btn-group btn-group-toggle btn-group-colors event-tag">
          <label
            v-for="color in eventColors"
            :key="color"
            class="btn"
            :class="[color, { 'active focused': model.className === color }]"
          >
            <input
              v-model="model.className"
              type="radio"
              name="event-tag"
              :value="color"
              autocomplete="off"
            />
          </label>
        </div>
      </div>
      <input type="hidden" class="new-event--start" />
      <input type="hidden" class="new-event--end" />
      <button
        type="submit"
        class="btn new-event--add btn-orange mt-5"
        @click="saveEvent"
      >
        Adicionar Evento
      </button>
      <button
        type="button"
        class="btn ml-auto mt-5"
        @click="showAddModal = false"
      >
        Cancelar
      </button>
    </modal>

    <modal v-model:show="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form" @submit.prevent="editEvent">
        <label class="form-control-label"> Titulo: </label>
        <input
          class="form-control"
          name="grid1"
          id="grid1"
          type="text"
          placeholder="Titulo do Evento"
          v-model="modelEditado.title"
        />
        <label class="form-control-label"> URL ( Meeting ): </label>
        <input
          class="form-control"
          name="grid1"
          id="grid1"
          type="text"
          placeholder="URL do Evento"
          v-model="modelEditado.url"
        />

        <label class="form-control-label"> Descrição: </label>
        <textarea
          v-model="modelEditado.description"
          class="form-control form-control-alternative edit-event--description textarea-autosize"
          placeholder="Descrição do Evento"
        >
        </textarea>
        <i class="form-group--bar"></i>

        <!-- HORA DE COMECO -->

        <label class="form-control-label">Hora de Começo: </label>
        <input
          class="form-control"
          name="time"
          type="time"
          v-model="horaComecoEditado"
          :disabled="diaTodoEditado"
        />

        <!-- HORA DE FIM -->

        <label class="form-control-label">Hora de Fim: </label>
        <input
          class="form-control"
          name="time"
          type="time"
          v-model="horaFimEditado"
          :disabled="diaTodoEditado"
        />

        <base-checkbox v-model="diaTodoEditado" class="mb-3">
          O dia todo
        </base-checkbox>
        <div class="form-group">
          <label class="form-control-label d-block mb-3"> Cor: </label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[
                color,
                { 'active focused': modelEditado.className === color },
              ]"
            >
              <input
                v-model="modelEditado.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>
        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <button
        type="submit"
        class="btn new-event--add btn-success mt-5"
        @click="editEvent"
      >
        Editar Evento
      </button>
      <button
        type="submit"
        class="btn new-event--add btn-danger mt-5"
        @click="deleteEvent"
      >
        Apagar
      </button>
      <button
        type="button"
        class="btn ml-auto mt-5"
        @click="showEditModal = false"
      >
        Cancelar
      </button>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import swal from "sweetalert2";

// const today = new Date();
// const y = today.getFullYear();
// const m = today.getMonth();
// const d = today.getDate();
var calendar;
export default {
  name: "calendar",
  components: {
    Modal,
  },
  data() {
    return {
      diaTodo: false,
      horaComeco: "",
      horaFim: "",
      diaTodoEditado: false,
      horaComecoEditado: "",
      horaFimEditado: "",
      events: [],
      modelEditado: {
        id: "",
        title: "",
        className: "",
        description: "",
        start: "",
        end: "",
        url: "",
      },
      model: {
        title: "",
        className: "bg-default",
        description: "",
        start: "",
        end: "",
        url: "",
      },
      showAddModal: false,
      showEditModal: false,
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-red",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
      ],
    };
  },
  watch: {
    diaTodo: function () {
      if (this.diaTodo) {
        this.horaComeco = "";
        this.horaFim = "";
      }
    },
    diaTodoEditado: function () {
      if (this.diaTodoEditado) {
        this.horaComecoEditado = "";
        this.horaFimEditado = "";
      }
    },
  },
  methods: {
    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Ocurreu um erro ao criar o Evento! Verifique se tem os campos devidamente preenchidos";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    async calendarioDatasMarcadas() {
      var entryUUID = localStorage.getItem("idYourCodeCalendar");
      let json = {
        entryUUID: entryUUID,
      };
      let res = await this.$store.dispatch("calendario", json);
      var resultado = Object.values(res);
      for (let i = 0; i < resultado.length; i++) {
        this.events.push({
          id: resultado[i].id,
          title: resultado[i].title,
          start: resultado[i].start,
          end: resultado[i].end,
          description: resultado[i].description,
          allDay: resultado[i].allday,
          className: resultado[i].className,
          url: resultado[i].url,
        });
      }
      console.log(this.events);
      this.initCalendar();
    },

    initCalendar() {
      var _this = this;
      var calendarEl = document.getElementById("fullCalendar");

      calendar = new Calendar(calendarEl, {
        timeZone: "Europe/London",
        locale: "pt",
        plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
        selectable: true,
        headerToolbar: {
          left: "title",
        },
        select: (e) => {
          this.showAddModal = true;
          this.model.start = e.start;
          this.model.end = e.end;
        },

        eventClick: (e) => {
          // console.log(e.event.extendedProps.description);
          if (e.event.url) {
            e.jsEvent.preventDefault();

            var url = e.event.url;
            var id = e.event.id;
            var title = e.event.title;
            var description = e.event.extendedProps.description;
            var classNames = e.event.classNames;
            var end = e.event.end;
            var start = e.event.start;

            swal
              .fire({
                title: "",
                text: "Será redireccionado para um link exterior: " + url,
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#c9c9c9",
                cancelButtonColor: "#c9c9c9",
                confirmButtonText: "Ir para Link",
                cancelButtonText: "Editar evento",
              })
              .then(async function (result) {
                if (result.isConfirmed) {
                  window.open(url, "_blank");
                } else {
                  _this.modelEditado = _this.events.find((x) => x.id == id);
                  if (
                    _this.modelEditado.start.split("T")[1] == "00:00:00.000Z"
                  ) {
                    _this.diaTodoEditado = true;
                    _this.horaComecoEditado = "";
                    _this.horaFimEditado = "";
                  } else {
                    _this.diaTodoEditado = false;
                    _this.horaComecoEditado = _this.modelEditado.start.split(
                      "T"
                    )[1];
                    _this.horaFimEditado = _this.modelEditado.end.split("T")[1];
                  }
                  _this.model = {
                    title: title,
                    className: classNames ? classNames.join(" ") : "",
                    start: start,
                    end: end,
                    description: description,
                  };
                  _this.showEditModal = true;
                }
              });
          } else {
            this.modelEditado = this.events.find((x) => x.id == e.event.id);
            if (this.modelEditado.start.split("T")[1] == "00:00:00.000Z") {
              this.diaTodoEditado = true;
            } else {
              this.diaTodoEditado = false;
            }
            this.model = {
              title: e.event.title,
              className: e.event.classNames ? e.event.classNames.join(" ") : "",
              start: e.event.start,
              end: e.event.end,
              description: e.event.extendedProps.description,
            };
            this.showEditModal = true;
          }
        },

        events: this.events,
      });
      calendar.render();
    },

    changeView(newView) {
      calendar.changeView(newView);
      calendar.view.title;
    },

    next() {
      calendar.next();
    },

    prev() {
      calendar.prev();
    },

    async saveEvent() {
      if (this.diaTodo == true) {
        let event1 = {
          ...this.model,
          allDay: true,
        };
        this.horaComeco = "";
        this.horaFim = "";
        let todos = await this.$store.dispatch("todosEventosCalendario");
        if (this.model.title) {
          if (Object.values(todos)) {
            var id1 = Number(Object.values(todos).at(-1).id) + 1;
          }

          let json = {
            id: id1,
            title: this.model.title,
            description: this.model.description,
            entryUUID: localStorage.getItem("idYourCodeCalendar"),
            start: JSON.parse(JSON.stringify(event1)).start,
            end: JSON.parse(JSON.stringify(event1)).end,
            allday: false,
            className: this.model.className,
            url: this.model.url,
          };
          this.events.push(JSON.parse(JSON.stringify(json)));
          let res = await this.$store.dispatch("criarEventoCalendario", json);
          console.log(res);
          if (res.resultado == "OK") {
            this.successNotification(
              "top-right",
              "success",
              "Evento criado com sucesso!"
            );
            this.initCalendar();
            this.model = {
              title: "",
              eventColor: "bg-danger",
              start: "",
              end: "",
            };
            this.events.push;
          } else {
            this.errorNotification("top-right", "danger");
          }
        }
        this.showAddModal = false;
      } else {
        if (this.horaComeco == "" || this.horaFim == "") {
          this.errorNotification("top-right", "danger");
        } else {
          let event = {
            ...this.model,
            allDay: true,
          };
          var start = JSON.parse(JSON.stringify(event)).start.split("T")[0];

          start = start + "T" + this.horaComeco;

          var end = JSON.parse(JSON.stringify(event)).end.split("T")[0];
          end =
            JSON.parse(JSON.stringify(event)).start.split("T")[0] +
            "T" +
            this.horaFim;
          let todos = await this.$store.dispatch("todosEventosCalendario");
          if (this.model.title) {
            if (Object.values(todos)) {
              var id = Number(Object.values(todos).at(-1).id) + 1;
            }

            let json = {
              id: id,
              title: this.model.title,
              description: this.model.description,
              entryUUID: localStorage.getItem("idYourCodeCalendar"),
              start: start,
              end: end,
              allday: false,
              className: this.model.className,
              url: this.model.url,
            };
            this.events.push(JSON.parse(JSON.stringify(json)));
            let res = await this.$store.dispatch("criarEventoCalendario", json);
            if (res.resultado == "OK") {
              this.successNotification(
                "top-right",
                "success",
                "Evento criado com sucesso!"
              );
              this.initCalendar();
              this.model = {
                title: "",
                eventColor: "bg-danger",
                start: "",
                end: "",
              };
              this.events.push;
            } else {
              this.errorNotification("top-right", "danger");
            }
          } else {
            this.errorNotification("top-right", "danger");
          }
        }
        this.showAddModal = false;
      }
    },

    async editEvent() {
      if (this.diaTodoEditado == true) {
        if (this.modelEditado.title != "") {
          this.modelEditado.start =
            this.modelEditado.start.split("T")[0] + "T00:00:00.000Z";
          this.modelEditado.end =
            this.modelEditado.start.split("T")[0] + "T23:59";
          console.log(this.modelEditado);
          let res = await this.$store.dispatch(
            "editarEventoCalendario",
            this.modelEditado
          );
          if (res.resultado == "OK") {
            let index = this.events.findIndex(
              (e) => e.id == this.modelEditado.id
            );
            if (index != -1) {
              this.events[index] = this.modelEditado;
            }
            this.successNotification(
              "top-right",
              "success",
              "Evento editado com sucesso!"
            );
            this.initCalendar();
          } else {
            this.errorNotification("top-right", "danger");
          }
        } else {
          this.errorNotification("top-right", "danger");
        }
      } else {
        if (this.modelEditado.title != "") {
          //     this.horaComecoEditado = this.modelEditado.start.split("T")[1];
          // this.horaFimEditado = this.modelEditado.end.split("T")[1];
          this.modelEditado.start =
            this.modelEditado.start.split("T")[0] +
            "T" +
            this.horaComecoEditado;
          this.modelEditado.end =
            this.modelEditado.end.split("T")[0] + "T" + this.horaFimEditado;
          console.log(this.modelEditado);
          let res = await this.$store.dispatch(
            "editarEventoCalendario",
            this.modelEditado
          );
          if (res.resultado == "OK") {
            let index = this.events.findIndex(
              (e) => e.id == this.modelEditado.id
            );
            if (index != -1) {
              this.events[index] = this.modelEditado;
            }
            this.successNotification(
              "top-right",
              "success",
              "Evento editado com sucesso!"
            );
            this.initCalendar();
          } else {
            this.errorNotification("top-right", "danger");
          }
        }
      }

      this.showEditModal = false;
    },

    async deleteEvent() {
      var _this = this;
      swal
        .fire({
          title: "Atenção",
          text: "Deseja apagar este evento?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
        })
        .then(async function (result) {
          if (result.isConfirmed) {
            let res = await _this.$store.dispatch(
              "apagarEventoCalendario",
              _this.modelEditado
            );
            if (res.resultado == "OK") {
              let index = _this.events.findIndex(
                (e) => e.id == _this.modelEditado.id
              );
              if (index !== -1) {
                _this.events.splice(index, 1);
              }
              _this.showEditModal = false;
              _this.successNotification(
                "top-right",
                "success",
                "Evento apagado com sucesso!"
              );
              _this.initCalendar();
            } else {
              _this.errorNotification("top-right", "danger");
            }
          }
        });
    },
  },
  mounted() {
    this.calendarioDatasMarcadas();
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/core/vendors/fullcalendar";

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  height: 50px;
  padding: 0 25px;
  text-transform: uppercase;
}

.fc .fc-toolbar-title {
  font-size: 1.2em;
  margin: 0;
}

.fc-today-button.fc-button.fc-button-primary {
  display: none;
}

.fc-button-group {
  display: none !important;
}
</style>
