<template>
  <loader
    v-show="carregar"
    object="rgb(20, 130, 194)"
    color1="#ffffff"
    color2="rgb(20, 130, 194)"
    size="5"
    speed="10"
    bg="#343a40"
    objectbg="#999793"
    opacity="80"
    name="circular"
  ></loader>
  <div>
    <div v-if="rendered">
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-4"
            >
              <route-bread-crumb> </route-bread-crumb>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right">
            <Ajuda />
          </div>
        </div>
      </base-header>

      <!-- MODAL VER RESPOSTAS  -->
      <modal class="modalPerfil" v-model:show="modals.verRespostas">
        <div class="card">
          <div class="row justify-content-center">
            <div class="col-lg-10 order-lg-10">
              <div class="card-profile-image"></div>
              <div
                class="card-header text-center border-0 pt-6 pb-0 pb-md-4"
              ></div>
              <div class="card-body pt-0">
                <div class="text-center">
                  <base-input name="select" label="Etapas">
                    <el-select
                      v-model="selects.percentagem"
                      filterable
                      placeholder="Escolha uma etapa"
                      @change="verRespostas(selects.percentagem)"
                    >
                      <el-option
                        v-for="option in selectOptionsPercentagem"
                        :key="option.label"
                        :label="option.label"
                        :value="option.value"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <div v-if="selects.percentagem != ''">
                    Progresso nesta Etapa: {{ percentagemEtapa }}
                  </div>
                  <div v-if="respostasDadas.length > 0">
                    <div v-for="(item, i) in respostasDadas" :key="i">
                      <div class="h5 font-weight-300">
                        Bloco: {{ item.bloco }}
                      </div>
                      <div class="h5 font-weight-300">
                        Etapa: {{ item.etapa }}
                      </div>
                      <div class="h5 font-weight-300">
                        Pergunta: {{ item.nome_pergunta }}
                      </div>
                      <div class="h5 font-weight-300">
                        A minha resposta:
                        <span v-html="item.resposta"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      selects.percentagem == '' && respostasDadas.length == 0
                    "
                  >
                    Seleccione uma Etapa
                  </div>
                  <div
                    v-else-if="
                      selects.percentagem != '' && respostasDadas.length == 0
                    "
                  >
                    Ainda não tem respostas nesta etapa.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <div
            type="link"
            class="text-black ml-auto text-right"
            @click="this.modals.verRespostas = false"
          >
            Fechar
          </div>
        </template>
      </modal>

      <!-- FIM MODAL VER RESPOSTAS  -->
      <div v-if="naoExistePid == ''">
        <div class="col-md-6">
          <base-input name="select" label="Etapas">
            <el-select
              v-model="selects.simple"
              filterable
              placeholder="Escolha uma etapa"
              @change="escolherEtapa"
            >
              <el-option
                v-for="option in selectOptions"
                :key="option.label"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
          <button class="base-button btn" @click="verRespostas()">
            Ver Respostas / Progresso
          </button>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-5">
            <ul class="nav nav-pills nav-pills-primary" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#Etapa0"
                  role="tablist"
                  aria-expanded="true"
                  @click="selecionarPerguntas('Saber Estar')"
                >
                  Saber Estar
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#Etapa1"
                  role="tablist"
                  aria-expanded="false"
                  @click="selecionarPerguntas('Saber Fazer')"
                >
                  Saber Fazer
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#Etapa2"
                  role="tablist"
                  aria-expanded="false"
                  @click="selecionarPerguntas('Academy')"
                >
                  Academy
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#Etapa3"
                  role="tablist"
                  aria-expanded="false"
                  @click="selecionarPerguntas('Sugestões Coach')"
                >
                  Sugestões Coach
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-toggle="tab"
                  href="#Etapa4"
                  role="tablist"
                  aria-expanded="false"
                  @click="selecionarPerguntas('Plano de Acção')"
                >
                  Plano de Acção
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="pidPorEtapa.length > 0 && bloco != 'Sugestões Coach'">
          <div v-for="(item, i) in pidPorEtapa" :key="i">
            {{ item.nome_pergunta }} - {{ item.pergunta_id }}-
            {{ item.percentagem }}
            <input
              disabled
              type="radio"
              v-model="item.resposta"
              class="mb-3"
              value="ok"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            OK

            <!-- @click="guardarResposta(item.pergunta_id, item)" -->
            <input
              disabled
              type="radio"
              v-model="item.resposta"
              class="mb-3"
              value="nok"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            NOK

            <input
              disabled
              type="radio"
              v-model="item.resposta"
              class="mb-3"
              value="NA"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            NA
          </div>
        </div>
        <div
          v-else-if="bloco == 'Sugestões Coach' && pidCoachPorEtapa.length > 0"
        >
          <div v-for="(item, i) in pidCoachPorEtapa" :key="i">
            {{ item.nome_pergunta }}
            <input
              type="radio"
              disabled
              v-model="item.resposta"
              class="mb-3"
              value="ok"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            OK

            <!-- @click="guardarResposta(item.pergunta_id, item)" -->
            <input
              type="radio"
              disabled
              v-model="item.resposta"
              class="mb-3"
              value="nok"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            NOK

            <input
              type="radio"
              disabled
              v-model="item.resposta"
              class="mb-3"
              value="NA"
              @click="guardarResposta(item.pergunta_id, item)"
            />
            NA
          </div>
        </div>
        <div v-else>Selecione uma etapa</div>
        <div class="tab-pane" id="planoAccao">
          <div
            class="form-group"
            v-for="(input, k) in perguntasSugestoesCoach"
            :key="k"
          >
            <!-- <div v-if="input.pergunta == ''"> -->
            <div>
              <base-input name="select" label="Etapas">
                <el-select
                  v-model="input.etapa"
                  filterable
                  placeholder="Escolha uma etapa"
                >
                  <el-option
                    v-for="option in selectOptionsPercentagem"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <label class="form-control-label">Pergunta </label>
              <input
                type="text"
                class="form-control"
                v-model="input.pergunta"
              />
              <label class="form-control-label">Percentagem </label>
              <input
                type="text"
                class="form-control"
                v-model="input.percentagem"
              />
              <button
                @click="remove(k, 'SugestoesCoach')"
                icon
                type="primary"
                class="btn base-button btn-danger"
              >
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-delete"></i
                ></span>
              </button>
            </div>
          </div>
          <button
            v-if="perguntasSugestoesCoach.length == 0"
            @click="add('novo', 'SugestoesCoach')"
            icon
            type="primary"
            class="btn base-button btn-success mt-5 text-right"
          >
            <span class="btn-inner--icon"
              ><i class="ni ni-fat-add" style="font-size: 18px !important"></i>
              Adicionar Sugestão</span
            >
          </button>
          <button
            v-else
            @click="add('novo', 'SugestoesCoach')"
            icon
            type="primary"
            class="btn base-button btn-success mt-5 text-right"
          >
            <i class="ni ni-fat-add" style="font-size: 18px !important"></i>
          </button>
          <div class="row mt-5" v-if="perguntasSugestoesCoach.length > 0">
            <div class="col-lg-12 text-left">
              <v-button
                class="btn base-button btn-success"
                type="success"
                @click="criarPID"
                >Guardar</v-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else>{{ naoExistePid }}</div>
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from "element-plus";
import Modal from "@/components/Modal";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Modal,
  },
  data() {
    return {
      carregar: false,
      referente: "",
      entryUUIDUtilizador: "",
      percentagemEtapa: "",
      naoExistePid: "",
      interagiu: 0,
      modals: {
        verRespostas: false,
      },
      bloco: "Saber Estar",
      user: [],
      respostas: [],
      respostasDadas: [],
      selectOptionsPercentagem: [
        {
          label: "Todas as Etapas",
          value: "todas",
        },
        {
          label: "Etapa 0",
          value: "0",
        },
        {
          label: "Etapa 1",
          value: "1",
        },
        {
          label: "Etapa 2",
          value: "2",
        },
        {
          label: "Etapa 3",
          value: "3",
        },
        {
          label: "Etapa 4",
          value: "4",
        },
        {
          label: "Etapa 5",
          value: "5",
        },
        {
          label: "Etapa 6",
          value: "6",
        },
        {
          label: "Etapa 7",
          value: "7",
        },
        {
          label: "Etapa 8",
          value: "8",
        },
        {
          label: "Etapa 9",
          value: "9",
        },
        {
          label: "Etapa 10",
          value: "10",
        },
        {
          label: "Etapa 11",
          value: "11",
        },
        {
          label: "Etapa 12",
          value: "12",
        },
      ],

      selectOptions: [
        {
          label: "Etapa 0",
          value: "0",
        },
        {
          label: "Etapa 1",
          value: "1",
        },
        {
          label: "Etapa 2",
          value: "2",
        },
        {
          label: "Etapa 3",
          value: "3",
        },
        {
          label: "Etapa 4",
          value: "4",
        },
        {
          label: "Etapa 5",
          value: "5",
        },
        {
          label: "Etapa 6",
          value: "6",
        },
        {
          label: "Etapa 7",
          value: "7",
        },
        {
          label: "Etapa 8",
          value: "8",
        },
        {
          label: "Etapa 9",
          value: "9",
        },
        {
          label: "Etapa 10",
          value: "10",
        },
        {
          label: "Etapa 11",
          value: "11",
        },
        {
          label: "Etapa 12",
          value: "12",
        },
      ],
      pid: [],
      pidCoach: [],
      pidPorEtapa: [],
      perguntasSugestoesCoach: [],
      pidCoachPorEtapa: [],
      tituloPid: "",
      rendered: false,
      selects: {
        simple: "",
        percentagem: "",
      },
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = "As suas respostas foram registadas com sucesso!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text =
        "Por favor, responda a uma ou mais perguntas antes de guardar!";
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    async criarPID() {
      this.carregar = true;
      var $ = this.$store;
      var funcao = "";
      var pidID = "";
      let resultadoTotal = [];
      for (let k = 0; k < this.perguntasSugestoesCoach.length; k++) {
        this.perguntasSugestoesCoach[k].referente = this.referente;
        this.perguntasSugestoesCoach[k].bloco = "Sugestões Coach";
      }
      for (let i = 0; i < this.perguntasSugestoesCoach.length; i++) {
        var jsonCriarPergunta = {
          pergunta: this.perguntasSugestoesCoach[i].pergunta,
          referente: this.perguntasSugestoesCoach[i].referente,
        };
        let idPerguntasCriadas = await $.dispatch(
          "PidcriarPerguntasSugestaoCoach",
          jsonCriarPergunta
        );
        this.perguntasSugestoesCoach[i].id = idPerguntasCriadas.ID[0].id;
      }
      let data = {
        entryUUID: this.referente,
      };
      let res = await $.dispatch("pidFuncaoDeclathoriano", data);
      if (res) {
        funcao = res[0].funcao;
        let jsonFuncao = {
          funcao: funcao,
        };
        let resultadoPid = await $.dispatch("pidDoUtilizador", jsonFuncao);
        // console.log(resultadoPid);
        if (resultadoPid) {
          pidID = resultadoPid[0].id;
          console.log(this.perguntasSugestoesCoach);
          for (let k = 0; k < this.perguntasSugestoesCoach.length; k++) {
            this.perguntasSugestoesCoach[k].pid_id = pidID;
            let resFinal = await $.dispatch(
              "pidCriarLigacaoesCoach",
              this.perguntasSugestoesCoach[k]
            );
            resultadoTotal.push(resFinal.resultado);
          }
          var uniq = [...new Set(resultadoTotal)];
          if (uniq.length == 1) {
            // MENSAGEM DE SUCESSO
            this.perguntasSugestoesCoach = [];
            this.inicio();
          } else {
            // MENSAGEM DE ERRO
            this.carregar = false;
          }
        } else {
          // MENSAGEM DE ERRO
          this.carregar = false;
        }
      } else {
        // MENSAGEM DE ERRO
        this.carregar = false;
      }
    },

    verRespostas(etapa) {
      if (etapa != "todas") {
        this.respostasDadas = [];
        var etapaLength = [];
        var respostasEtapa = [];
        console.log(this.pid);
        for (let i = 0; i < this.pid.length; i++) {
          if (this.pid[i].etapa == etapa) {
            etapaLength.push(this.pid[i]);
          }
          if (this.pid[i].resposta && this.pid[i].etapa == etapa) {
            this.respostasDadas.push(this.pid[i]);
          }
        }
        this.respostasDadas = this.respostasDadas.sort(
          (a, b) => a.etapa > b.etapa && 1
        );
        for (let k = 0; k < this.respostasDadas.length; k++) {
          if (this.respostasDadas[k].etapa == etapa) {
            respostasEtapa.push(this.respostasDadas[k]);
          }
        }
        var percentagemEtapa = (
          (Number(respostasEtapa.length) * 100) /
          Number(etapaLength.length)
        ).toFixed(2);
        this.percentagemEtapa = percentagemEtapa + "%";
        this.modals.verRespostas = true;
      }
      if (etapa == "todas") {
        this.selects.percentagem = "";
        this.respostasDadas = [];
        var etapaLength2 = [];
        var respostasEtapa2 = [];
        console.log(this.pid);
        for (let i = 0; i < this.pid.length; i++) {
          if (this.pid[i].etapa == etapa) {
            etapaLength2.push(this.pid[i]);
          }
          if (this.pid[i].resposta) {
            this.respostasDadas.push(this.pid[i]);
          }
          this.respostasDadas = this.respostasDadas.sort(
            (a, b) => a.etapa > b.etapa && 1
          );
        }
        for (let k = 0; k < this.respostasDadas.length; k++) {
          if (this.respostasDadas[k].etapa == etapa) {
            respostasEtapa2.push(this.respostasDadas[k]);
          }
        }
        var percentagemEtapa2 = (
          (Number(respostasEtapa2.length) * 100) /
          Number(etapaLength2.length)
        ).toFixed(2);
        this.percentagemEtapa = percentagemEtapa2 + "%";
        this.modals.verRespostas = true;
      }
      if (!etapa) {
        this.selects.percentagem = "";
        this.respostasDadas = [];
        var etapaLength1 = [];
        var respostasEtapa1 = [];
        console.log(this.pid);
        for (let i = 0; i < this.pid.length; i++) {
          if (this.pid[i].etapa == etapa) {
            etapaLength1.push(this.pid[i]);
          }
          if (this.pid[i].resposta && this.pid[i].etapa == etapa) {
            this.respostasDadas.push(this.pid[i]);
          }
          this.respostasDadas = this.respostasDadas.sort(
            (a, b) => a.etapa > b.etapa && 1
          );
        }
        for (let k = 0; k < this.respostasDadas.length; k++) {
          if (this.respostasDadas[k].etapa == etapa) {
            respostasEtapa1.push(this.respostasDadas[k]);
          }
        }
        var percentagemEtapa1 = (
          (Number(respostasEtapa1.length) * 100) /
          Number(etapaLength1.length)
        ).toFixed(2);
        this.percentagemEtapa = percentagemEtapa1 + "%";
        this.modals.verRespostas = true;
      }

      console.log(etapa);
    },

    async guardarResposta(id, item) {
      this.interagiu = 1;
      var that = this;
      var $ = this.$store;
      var index;
      if (item) {
        index = this.pid.findIndex((x) => x.pergunta_id == id);
      }
      this.pid[index] = item;
      this.pid[index].entryUUID = localStorage.getItem("idYourCodeCalendar");
      if (this.respostas) {
        if (this.respostas.length > 0) {
          var i = this.respostas.findIndex((x) => x.pergunta_id == id);
          if (i != -1) {
            this.respostas[i] = this.pid[index];
          } else {
            this.respostas.push(this.pid[index]);
          }
        } else {
          this.respostas.push(this.pid[index]);
        }
      } else {
        this.respostas.push(this.pid[index]);
      }

      setTimeout(async function () {
        let res = "";
        for (let i = 0; i < that.respostas.length; i++) {
          console.log(that.respostas[i].resposta);
          res = await $.dispatch("GuardarResposta", that.respostas[i]);
          res = res.resultado;
        }
        console.log(res);
        if (
          res == "OK - INTERAÇÃO ATUALIZADA" ||
          res == "OK - INTERAÇÃO INSERIDA"
        ) {
          that.respostas = [];
          that.inicio();
        }
      }, 500);
    },

    escolherEtapa() {
      this.etapa = this.selects.simple;
      //   this.pidPorEtapa = this.pid.filter((x) => x.etapa == this.etapa);
      if (this.bloco != "") {
        this.selecionarPerguntas(this.bloco);
      }
    },

    selecionarPerguntas(bloco) {
      this.bloco = bloco;
      if (this.bloco != "Sugestões Coach") {
        this.pidPorEtapa = this.pid;
        this.pidPorEtapa = this.pidPorEtapa.filter(
          (x) => x.bloco == this.bloco && x.etapa == this.etapa
        );
      } else {
        this.pidCoachPorEtapa = this.pidCoach;
        this.pidCoachPorEtapa = this.pidCoachPorEtapa.filter(
          (x) => x.bloco == this.bloco && x.etapa == this.etapa
        );
      }
    },

    async inicio() {
      var $ = this.$store;
      var funcao = this.user.funcao;
      var jsonPid = {
        funcao: funcao,
      };

      let res = await $.dispatch("PIDUtilizador", jsonPid);
      console.log(Object.values(res).length, "resssss");
      if (res) {
        if (Object.values(res).length > 0) {
          var pid_id = res[0].id;
          var JsonPidId = {
            pid_id: pid_id,
          };
          var entryUUID = this.referente;
          var jsonInteracoes = {
            entryUUID: entryUUID,
          };
          let perguntas = await $.dispatch("pidPerguntas", JsonPidId);
          let perguntasCoach = await $.dispatch(
            "pidPerguntasCoach",
            jsonInteracoes
          );
          this.pidCoach = Object.values(perguntasCoach);
          console.log(this.pidCoach);
          this.pid = Object.values(perguntas);

          let interacoes = await $.dispatch(
            "listarInteracoesUser",
            jsonInteracoes
          );
          interacoes = Object.values(interacoes);

          for (let i = 0; i < interacoes.length; i++) {
            if (interacoes[i].estado == "1") {
              var index = this.pid.findIndex(
                (x) => x.pergunta_id == interacoes[i].pergunta_id
              );

              this.pid[index].resposta = interacoes[i].resposta;
              this.pid[index].estado = interacoes[i].estado;
            }
          }
          for (let i = 0; i < interacoes.length; i++) {
            if (interacoes[i].estado == "1") {
              var indexCoach = "";
              indexCoach = this.pidCoach.findIndex(
                (x) => x.pergunta_id == interacoes[i].pergunta_id
              );
              if (indexCoach != -1) {
                this.pidCoach[indexCoach].resposta = interacoes[i].resposta;
                this.pidCoach[indexCoach].estado = interacoes[i].estado;
              }
            }
          }
          this.rendered = true;
        } else {
          this.naoExistePid =
            "A função deste decathloriano não tem um PID atribuido!";
          this.rendered = true;
        }
      }
      this.carregar = false;
    },

    add(k, bloco) {
      if (bloco == "SugestoesCoach") {
        this.perguntasSugestoesCoach.push({
          etapa: this.etapa,
          bloco: this.bloco,
          pergunta: "",
          percentagem: "",
        });
      }
    },

    remove(k, onde) {
      if (onde == "SugestoesCoach") {
        this.perguntasSugestoesCoach.splice(k, 1);
      }
    },
  },
  async mounted() {
    this.carregar = true;
    var id = window.location.href.split("=")[1];
    this.referente = id;
    let user = await this.$store.dispatch("profileDetails", id);
    this.user = Object.values(user)[0];
    this.inicio();
  },
};
</script>
