<template>
  <base-header class="pb-6">
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <nav
          aria-label="breadcrumb"
          class="d-none d-md-inline-block ml-md-4"
        ></nav>
      </div>
    </div>
  </base-header>
  <div class="container-fluid mt--6">
    <div class="card">
      <div class="border-0 card-header">
        <h3 class="mb-0">Utilizadores</h3>
      </div>

      <el-table
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="utilizadores"
        :items-per-page="5"
      >
        <el-table-column label="nome" min-width="310px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.nome
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Apelido" min-width="310px" prop="name" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.apelido
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Username"
          min-width="310px"
          prop="name"
          sortable
        >
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.username
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Email" prop="email" min-width="140px" sortable>
          <template v-slot="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-600 name mb-0 text-sm">{{
                  row.email
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Estado"
          min-width="170px"
          prop="status"
          sortable
        >
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <span v-if="row.status == 1" class="status text-green"
                >Aprovado</span
              >
              <span v-if="row.status == 0" class="status text-red"
                >Não Aprovado</span
              >
            </badge>
          </template>
        </el-table-column>
        <el-table-column
          label="Administrador"
          min-width="170px"
          prop="admin"
          sortable
        >
          <template v-slot="{ row }">
            <badge class="badge-dot mr-4" type="">
              <span v-if="row.admin == 1" class="status text-green">Sim</span>
              <span v-if="row.admin == 0" class="status text-red">Não</span>
            </badge>
          </template>
        </el-table-column>

        <el-table-column v-slot="{ row }" min-width="180px">
          <el-dropdown label="Ações" trigger="click" class="dropdown">
            <span class="btn btn-sm btn-icon-only text-light">
              <i class="fas fa-ellipsis-v mt-2"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu
                class="dropdown-menu dropdown-menu-arrow show"
                label="Estado"
              >
                <a
                  v-if="row.status == '0'"
                  class="dropdown-item"
                  @click="showSwal('aprovar', row.id_plataforma)"
                  >Aprovar</a
                >
                <a
                  v-if="row.status == '1'"
                  class="dropdown-item"
                  @click="showSwal('desaprovar', row.id_plataforma)"
                  >Desactivar</a
                >
                <a
                  v-if="row.admin == '0'"
                  class="dropdown-item"
                  @click="showSwal('promover', row.id_plataforma)"
                  >Promover a Admin</a
                >
                <a
                  v-if="row.admin == '1'"
                  class="dropdown-item"
                  @click="showSwal('despromover', row.id_plataforma)"
                  >Remover Admin</a
                >
                <a
                  class="dropdown-item"
                  @click="showSwal('apagar', row.id_plataforma)"
                  >Apagar</a
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
} from "element-plus";
export default {
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  data() {
    return {
      utilizadores: [],
      noticiaEditada: [],
      currentPage: 1,
      rendered: false,
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
    async showSwal(type, id) {
      var that = this;
      if (type === "apagar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja apagar este utilizador?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id_plataforma: id,
              };
              let res = await that.$store.dispatch("apagarUtilizador", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Utilizador apagado com sucesso!"
                  );
                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "aprovar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja aprovar este utilizador?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id_plataforma: id,
              };
              let res = await that.$store.dispatch("aprovarUtilizadores", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Utilizador aprovado com sucesso!"
                  );
                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "desaprovar") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja desaprovar este utilizador?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id_plataforma: id,
              };
              let res = await that.$store.dispatch(
                "desaprovarUtilizadores",
                json
              );
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Utilizador desativado com sucesso!"
                  );
                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "promover") {
        swal
          .fire({
            title: "Atenção",
            text: "Deseja desaprovar este utilizador?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id_plataforma: id,
              };
              let res = await that.$store.dispatch("promoverAdmin", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Utilizador promovido a Administrador com sucesso!"
                  );
                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      } else if (type === "despromover") {
        swal
          .fire({
            title: "Atenção",
            text:
              "Deseja retirar os privilégios de administrador a este utilizador?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim",
          })
          .then(async function (result) {
            if (result.isConfirmed) {
              let json = {
                id_plataforma: id,
              };
              let res = await that.$store.dispatch("despromoverAdmin", json);
              if (res) {
                if (res.resultado == "OK") {
                  that.successNotification(
                    "top-right",
                    "success",
                    "Utilizador promovido a Administrador com sucesso!"
                  );
                  that.inicio();
                } else {
                  that.errorNotification(
                    "top-right",
                    "danger",
                    "Ocurreu um erro. Por favor, tente mais tarde!"
                  );
                }
              } else {
                that.errorNotification(
                  "top-right",
                  "danger",
                  "Ocurreu um erro. Por favor, tente mais tarde!"
                );
              }
            }
          });
      }
    },
    async inicio() {
      var utilizadores = await this.$store.dispatch("listarUtilizadores");
      this.utilizadores = Object.values(utilizadores);
      this.rendered = true;
    },
  },
  async mounted() {
    this.inicio();
  },
};
</script>
