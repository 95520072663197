<template>
  <div class="col-lg-12 text-center pr-0 noShadow">
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-lg-6 text-center">
          <h3>Hoje Estou:</h3>
          <div class="icon">
            <svg
              id="icon-bad"
              version="1.1"
              class="redIcon"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 103.696 103.695"
            >
              <path
                d="M75.835,72.818c0.656,1.521-0.043,3.287-1.563,3.945s-3.286-0.043-3.944-1.563c-2.894-6.688-9.729-11.013-17.42-11.013
                    c-7.869,0-14.748,4.32-17.523,11.006c-0.48,1.152-1.596,1.85-2.771,1.852c-0.385,0-0.773-0.074-1.15-0.23
                    c-1.531-0.637-2.256-2.393-1.619-3.922c3.709-8.933,12.764-14.703,23.064-14.703C62.993,58.189,71.993,63.932,75.835,72.818z
                    M28.452,36.484c-0.676-1.176-0.27-2.676,0.906-3.351l9.045-5.196c1.176-0.674,2.676-0.268,3.352,0.907
                    c0.676,1.176,0.27,2.676-0.906,3.351l-9.045,5.194C30.626,38.065,29.126,37.66,28.452,36.484z M42.487,36.59
                    c1.688,1.689,1.688,4.429,0,6.115c-1.688,1.688-4.426,1.688-6.117-0.002c-1.688-1.688-1.688-4.426,0-6.113
                    C38.059,34.901,40.797,34.901,42.487,36.59z M57.188,21.907c0.121-1.35,1.312-2.347,2.662-2.226l10.391,0.934
                    c1.35,0.121,2.348,1.313,2.225,2.664c-0.121,1.351-1.312,2.347-2.664,2.225l-10.389-0.933
                    C58.063,24.45,57.065,23.256,57.188,21.907z M68.28,36.519c1.688,1.688,1.688,4.426,0,6.113c-1.691,1.69-4.43,1.69-6.117,0.002
                    c-1.688-1.687-1.688-4.426,0-6.115C63.852,34.829,66.59,34.829,68.28,36.519z M85.465,103.695H18.23
                    C8.178,103.695,0,95.518,0,85.465V18.23C0,8.177,8.179,0,18.23,0h67.235c10.053,0,18.229,8.178,18.229,18.23v67.235
                    C103.696,95.518,95.518,103.695,85.465,103.695z M18.23,8.577c-5.322,0-9.652,4.33-9.652,9.652v67.234
                    c0,5.322,4.33,9.652,9.652,9.652h67.235c5.321,0,9.651-4.33,9.651-9.652V18.23c0-5.322-4.33-9.652-9.651-9.652L18.23,8.577
                    L18.23,8.577z"
              />
            </svg>

            <svg
              id="icon-ok"
              class="orangeIcon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 103.696 103.695"
            >
              <path
                d="M50.803,71.848c0-2.482,1.305-4.496,2.913-4.496h24.27c1.607,0,2.91,2.014,2.91,4.496c0,2.481-1.303,4.496-2.91,4.496
                    h-24.27C52.108,76.344,50.803,74.329,50.803,71.848z M30.559,29.488c0-4.113,3.12-7.451,6.965-7.451
                    c3.846,0,6.966,3.338,6.966,7.451c0,4.117-3.12,7.453-6.966,7.453C33.679,36.941,30.559,33.605,30.559,29.488z M60.145,29.488
                    c0-4.113,3.123-7.451,6.969-7.451c3.845,0,6.965,3.338,6.965,7.451c0,4.117-3.12,7.453-6.965,7.453
                    C63.268,36.941,60.145,33.605,60.145,29.488z M85.465,103.695H18.23C8.178,103.695,0,95.518,0,85.465V18.23
                    C0,8.177,8.179,0,18.23,0h67.235c10.053,0,18.229,8.178,18.229,18.23v67.235C103.696,95.518,95.518,103.695,85.465,103.695z
                    M18.23,8.577c-5.322,0-9.652,4.33-9.652,9.652v67.234c0,5.322,4.33,9.652,9.652,9.652h67.235c5.321,0,9.651-4.33,9.651-9.652
                    V18.23c0-5.322-4.33-9.652-9.651-9.652L18.23,8.577L18.23,8.577z"
              />
            </svg>

            <svg
              version="1.1"
              id="icon-good"
              class="greenIcon"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 103.696 103.696"
            >
              <path
                d="M32.06,37.489c0-3.423,2.777-6.201,6.201-6.201c3.423,0,6.2,2.777,6.2,6.201c0,3.426-2.777,6.203-6.2,6.203
            C34.836,43.692,32.06,40.915,32.06,37.489z M60.176,37.489c0-3.423,2.78-6.201,6.203-6.201c3.424,0,6.201,2.777,6.201,6.201
            c0,3.426-2.777,6.203-6.201,6.203C62.957,43.692,60.176,40.915,60.176,37.489z M74.836,62.887
            c-3.843,8.887-12.843,14.629-22.928,14.629c-10.301,0-19.354-5.771-23.064-14.703c-0.636-1.529,0.089-3.285,1.62-3.92
            c0.376-0.156,0.766-0.23,1.15-0.23c1.176,0,2.292,0.695,2.771,1.85c2.777,6.686,9.655,11.004,17.523,11.004
            c7.689,0,14.527-4.321,17.421-11.01c0.658-1.521,2.424-2.223,3.944-1.564S75.495,61.366,74.836,62.887z M85.467,103.696H18.23
            C8.179,103.696,0,95.518,0,85.467V18.23C0,8.178,8.179,0,18.23,0h67.235c10.053,0,18.23,8.178,18.23,18.23v67.235
            C103.697,95.518,95.518,103.696,85.467,103.696z M18.23,8.579c-5.321,0-9.651,4.33-9.651,9.651v67.235
            c0,5.321,4.33,9.651,9.651,9.651h67.235c5.321,0,9.651-4.33,9.651-9.651V18.23c0-5.321-4.33-9.651-9.651-9.651H18.23z"
              />
            </svg>
          </div>
          <div class="range">
            <svg
              width="360"
              height="15"
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="0">
                  <stop id="gradient-grey" offset="0%" stop-color="#EAEEF4" />
                  <stop id="gradient-stop" offset="0%" stop-color="#ff5757" />
                </linearGradient>
              </defs>
              <path
                d="M0 7.65377C0 6.22069 1.1207 5.01982 2.5 5L177.5 2.5L352.776 0.000723075C356.75 -0.0563631 360 3.27402 360 7.40212C360 11.5262 356.756 14.855 352.786 14.8037L177.5 13L2.5 10.5C1.11931 10.4821 0 9.08826 0 7.65377Z"
                fill="url(#gradient)"
              />
            </svg>
            <input
              @input="checkSliderValue"
              type="range"
              min="1"
              max="3"
              step="0.1"
              value="2"
              class="slider"
            />
          </div>
        </div>
        <div class="col-lg-6 text-left">
          <label class="form-control-label mb-1">Descrição do meu dia: </label>
          <textarea
            rows="5"
            class="form-control"
            placeholder="O meu dia hoje foi épico porque..."
            v-model="feedbackComentario"
          ></textarea>
        </div>
      </div>
      <div class="col-lg-12 text-right pr-0">
        <button
          id="teste"
          class="btn base-button btn-success"
          type="success"
          @click="feedback()"
        >
          Enviar Feedback
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import Notification from "@/components/Notification";
export default {
  data() {
    return {
      range: document.querySelector(".range"),
      slider: document.querySelector(".slider"),
      buttonClass: "",
      feedbackvalue: "",
      feedbackComentario: "",
      classInputComplete: "form-control complete",
      classInputInomplete: "form-control incomplete",
      user: [],
    };
  },
  methods: {
    successNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    errorNotification(pos, type, ownText, ownIcon) {
      const text = ownText;
      const icon = "ni ni-bell-55";
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          icon: icon,
          text: text,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },

    dataAtual(num) {
      var data = new Date();
      if (num == 1) {
        return (
          data.getFullYear() +
          "/" +
          (data.getMonth() + 1) +
          "/" +
          data.getDate() +
          " " +
          data.getHours() +
          ":" +
          data.getMinutes() +
          ":" +
          data.getSeconds()
        );
      } else if (num == 2) {
        return (
          data.getFullYear() + "/" + data.getMonth() + "/" + data.getDate()
        );
      } else {
        return (
          data.getHours() + ":" + data.getMinutes() + ":" + data.getSeconds()
        );
      }
    },

    async feedback() {
      var feedbackValue = Math.floor(document.querySelector(".slider").value);
      let json = {
        id: this.user.entryUUID,
        feedback: feedbackValue,
        comentario: this.feedbackComentario,
      };
      var that = this;
      let res = await this.$store.dispatch("verificarFeedback", json);
      var dataUltimoFeedback = res[0].data;
      var dataAtual = new Date(this.dataAtual(1));
      var dataUltimoFeedbackCalculo = new Date(dataUltimoFeedback);
      var diferencaTempo =
        dataAtual.getTime() - dataUltimoFeedbackCalculo.getTime();
      var diferencaDias = diferencaTempo / (1000 * 3600);
      if (diferencaDias >= 1) {
        if (this.feedbackComentario != "") {
          let json = {
            id: this.user.entryUUID,
            feedback: feedbackValue,
            comentario: this.feedbackComentario,
          };
          let res = await this.$store.dispatch("feedback", json);
          if (res) {
            if (res.data.resultado == "OK") {
              that.successNotification(
                "top-right",
                "success",
                "O seu feedback foi enviado com sucesso. Obrigado!"
              );
              if (window.location.href.includes("/feliz")) {
                setTimeout(() => {
                  location.reload();
                }, 1000);
              }
            } else {
              that.errorNotification(
                "top-right",
                "danger",
                "Ocurreu um erro. Por favor, tente mais tarde!"
              );
            }
          } else {
            that.errorNotification(
              "top-right",
              "danger",
              "Ocurreu um erro. Por favor, tente mais tarde!"
            );
          }
        } else {
          that.errorNotification(
            "top-right",
            "danger",
            "Por favor, verifique se os campos estão devidamente preenchidos!"
          );
        }
      } else {
        that.errorNotification(
          "top-right",
          "danger",
          "Só podemos receber o teu feedback uma vez por hora!"
        );
      }
    },

    checkSliderValue(slider) {
      let that = this;
      let range = document.querySelector(".range");
      let slider1 = document.querySelector(".slider");
      let gradientStop = document.querySelector("#gradient-stop");
      let gradientGrey = document.querySelector("#gradient-grey");

      let colorBad = "#e31414";
      let colorOk = "#ff9800";
      let colorGood = "#36d896";
      slider = slider1;
      gradientGrey.setAttribute("offset", 150 - slider.value * 50 + "%");
      //   console.log(150 - slider.value * 50 + "%");
      if (slider.value > 0 && slider.value < 2) {
        range.closest(".row").classList.add("bad");
        range.closest(".row").classList.remove("ok", "great", "good");
        gradientStop.setAttribute("stop-color", colorBad);
        that.buttonClass = "red";
      }
      if (slider.value >= 2 && slider.value < 3) {
        range.closest(".row").classList.add("ok");
        range.closest(".row").classList.remove("bad", "great", "good");
        gradientStop.setAttribute("stop-color", colorOk);
        that.buttonClass = "orange";
      }
      if (slider.value >= 3 && slider.value <= 3) {
        range.closest(".row").classList.add("good");
        range.closest(".row").classList.remove("ok", "great", "bad");
        gradientStop.setAttribute("stop-color", colorGood);
        that.buttonClass = "green";
        console.log(that.buttonStyle);
        console.log(colorGood);
      }
    },

    async getUser() {
      var entryUUID = localStorage.getItem("idYourCodeCalendar");
      let user1 = await this.$store.dispatch("profileDetails", entryUUID);

      this.user = Object.values(user1)[0];
    },
  },
  mounted() {
    this.getUser();
    setTimeout(() => {
      this.checkSliderValue(document.querySelector(".slider"));
    }, 500);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500&display=swap");

:root {
  --body-color: #ebf4ff;
  --bad-color: #e31414;
  --ok-color: #ff9800;
  --good-color: #36d896;
  --great-color: #3f51b5;
  --track-color: #eaeef4;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background: var(--body-color);
  height: 100vh;
  align-items: center;
  display: flex;
  font-family: "Raleway", sans-serif;
}

.modal-dialog {
  max-width: 70vw !important;
}

.red {
  background-color: #e31414 !important;
  border: transparent;
}
.orange {
  background-color: #ff9800 !important;
  border: transparent;
}
.green {
  background-color: #36d896 !important;
  border: transparent;
}
.redIcon {
  fill: #e31414 !important;
}
.orangeIcon {
  fill: #ff9800 !important;
}
.greenIcon {
  fill: #36d896 !important;
}
.card {
  height: 20vh;
  width: 25vw;
  background: #fff;
  display: flex;
  margin: auto;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 2px 10px -10px #2f2f2f;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
}

.card .row {
  margin-top: 30px;
  display: flex;
}

.card p {
  font-size: 18px;
  font-weight: 700;
  color: var(--great-color);
}

.icon {
  height: 40px;
  width: 40px;
  position: relative;
  margin: 40px auto;
}

.icon svg {
  height: 100%;
  width: 100%;
  position: absolute;
}

.range {
  position: relative;
  width: 360px;
  height: 17px;
  margin: auto;
}

.range svg {
  width: 100%;
  height: 100%;
}

.range svg path {
  transition: linear 0.5s;
}

input[type="range"] {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 80%;
  margin: 0;
  padding: 0;
  transform: translateY(-50%);
  background: none;
  transition: linear 0.5s;
}

input[type="range"]:focus {
  outline: 0;
}

input[type="range"]::-webkit-slider-thumb {
  width: 26px;
  -webkit-appearance: none;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  border: 6px solid #fff;
  box-shadow: 0px 0px 6px 0px #ccc;
  z-index: 2;
  position: relative;
  margin-left: 3px;
  margin-top: -3px;
}

.range.bad svg path#path-ok,
.range.bad svg path#path-good,
.range.bad svg path#path-great {
  fill: var(--track-color);
}

.icon svg#icon-bad path {
  fill: var(--bad-color);
}

.icon svg#icon-ok path {
  fill: var(--ok-color);
}

.icon svg#icon-good path {
  fill: var(--good-color);
}

.icon svg#icon-great path {
  fill: var(--great-color);
}

.row.bad input[type="range"]::-webkit-slider-thumb {
  background: var(--bad-color);
}

.row.ok input[type="range"]::-webkit-slider-thumb {
  background: var(--ok-color);
}

.row.good input[type="range"]::-webkit-slider-thumb {
  background: var(--good-color);
}

.row.great input[type="range"]::-webkit-slider-thumb {
  background: var(--great-color);
}

.row .icon svg {
  display: none;
}

.row.bad .icon svg#icon-bad,
.row.ok .icon svg#icon-ok,
.row.good .icon svg#icon-good,
.row.great .icon svg#icon-great {
  display: block;
  animation: heartBeat 1s;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__heartBeat {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.range:after {
  content: "";
  position: absolute;
  box-shadow: 99px 31px 0px 1px var(--track-color),
    183px 31px 0px 1px var(--track-color), 267px 31px 0px 1px var(--track-color),
    351px 31px 0px 1px var(--track-color);
  left: 0;
  height: 6px;
}

.row.bad .range:after {
  box-shadow: 99px 31px 0px 1px var(--bad-color),
    183px 31px 0px 1px var(--track-color), 267px 31px 0px 1px var(--track-color),
    351px 31px 0px 1px var(--track-color);
}

.row.ok .range:after {
  box-shadow: 99px 31px 0px 1px var(--track-color),
    183px 31px 0px 1px var(--ok-color), 267px 31px 0px 1px var(--track-color),
    351px 31px 0px 1px var(--track-color);
}

.row.good .range:after {
  box-shadow: 99px 31px 0px 1px var(--track-color),
    183px 31px 0px 1px var(--track-color), 267px 31px 0px 1px var(--good-color),
    351px 31px 0px 1px var(--track-color);
}

.row.great .range:after {
  box-shadow: 99px 31px 0px 1px var(--track-color),
    183px 31px 0px 1px var(--track-color), 267px 31px 0px 1px var(--track-color),
    351px 31px 0px 1px var(--great-color);
}

.noShadow {
  box-shadow: none !important;
}

#feedbackContainer {
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>
