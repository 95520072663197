<template>
  <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
  <router-view></router-view>

  <modal
    v-model:show="modals.notice"
    modal-classes="modal-danger"
    modal-content-classes="bg-gradient-danger"
  >
    <div class="py-3 text-center">
      <UserHapiness />
    </div>

    <template v-slot:footer>
      <div
        type="link"
        class="text-white ml-auto text-right"
        @click="modals.notice = false"
      >
        Fechar
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "../src/components/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modals: {
        notice: false,
      },
    };
  },
  methods: {},
};
</script>

<style>
#feedbackWidget {
  position: fixed;
  bottom: 40px;
  right: 47px;
  width: 125px;
  z-index: 999999;
  font-size: 12px;
  background: #fff;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 3px 3px 7px #cecece;
  cursor: pointer;
}

#feedbackWidget span {
  position: relative;
  top: 5px;
}

#iconFeedback {
  max-width: 30px;
  float: left;
}

.modal-content {
  width: 50vw !important;
}

.modal-dialog {
  max-width: 50% !important;
}

.modal-footer {
  position: absolute;
  right: 0;
  font-weight: bold;
  cursor: pointer;
}

.modal-header {
  padding-bottom: 0 !important;
}

.el-dropdown {
  width: 100% !important;
  text-align: center !important;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.ql-header.ql-picker {
  border: 1px solid #999 !important;
}
.dropdown-item {
  cursor: pointer;
}
strong {
  font-weight: bold;
}

#overlay-circular {
  z-index: 999999999999999999 !important;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  background: #fff !important;
  opacity: 1 !important;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  padding-left: 5px;
}

.card-img-top {
  height: 250px;
}
</style>
